import * as React from "react";
import { useState, useEffect } from "react";
import { resetPw } from "../services/utility/callChangePassword";
import ChangePassword from "./ChangePassword";
import { Grid,Card, CardContent, Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";

interface Props {
  importedClasses?: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
  
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '300px',
      },
      '& .MuiButtonBase-root': {
        margin: theme.spacing(2),
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        margin: theme.spacing(1),
        minWidth: 300,
      },
      dense: {
        marginTop: theme.spacing(2),
      },
      menu: {
        width: 200,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      cardStyle: {
        border: 'gray solid thin',
        marginTop: theme.spacing(10),
        width: 374
      },
      clockTrackButton: {
          margin: theme.spacing(1),
          color: "#333333",
          '&:hover': {
              textDecoration: "none",
              color: "#25ca94"
          },
          fontWeight: "bold"
      }
  }),
);

/**
 * Login component.
 */
const ChangePasswordIndex: React.FC<Props> = (props) => {
  const { importedClasses } = props;
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const [found, setFound] = useState(false);
  const [userInfo, setUserInfo] = useState(undefined);
  const [msg, setMsg] = useState('');
  const classes = useStyles();

  const findReset = async () => {
    if (token) {
      const response = await resetPw(token);
      if (response && response.success) {
        setFound(true);
        setUserInfo(response.result);
      } else {
        setFound(false);
        setMsg(response.result);
      }
    }
  }
  
  useEffect(() => {
    findReset();
  },[token]);
  
  return <>
    
      {found && 
      <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={10}
      >
        <Card className={classes.cardStyle}>
            <CardContent>
              <img src="/images/png/fhg-logo-90.png" alt="FHG logo"/>
            <ChangePassword importedClasses={importedClasses} userInfo={userInfo} navToHome={true}/>
            </CardContent>
          </Card>
        </Grid>
      } 
      {!found &&
        <span>{msg}</span>
      }
          
    
  </>;
};

export default ChangePasswordIndex;

