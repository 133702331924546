import { Theme, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import * as React from "react";

/**
 * component props.
 * @param data - the array you want to show in the drop down
 * @param label - the label of the drop down
 * @param name - the name of the field
 */
 interface Props {
  data: any[];
  label: string;
  name: string;
  classes: any;
  userInfo: any;
  required?: boolean;
}

/**
 * The period toggle buttons. selection between month, quarter, year.
 * @param props required props for the periodic toggle to function.
 */
const DropDown: React.FC<Props> = props => {
  const { data, label, name, classes , userInfo, required  } = props;

  return (
    <Field
      disabled={userInfo.userType === '4'}
      type="text"
      name={name}
      select
      className={classes.autocomplete}
      component={TextField}
      helperText=""
      margin="normal"
      variant="outlined"
      label={label}
      InputLabelProps={{
          shrink: true,
        }}
      required={required}
      >
        <MenuItem key={`0`} value={`0`}>
         Unassigned
        </MenuItem>
      {data.map(option => (
          <MenuItem key={`${option.lastName}, ${option.firstName}`} value={option.id}>
          {`${option.lastName}, ${option.firstName}`}
          </MenuItem>
      ))}
    </Field>
  );
};

export default DropDown;
