import React from 'react';
import logo from './logo.svg';
import { Router, globalHistory } from "@reach/router";
import './App.css';
import { PrivateRoute } from './auth/PrivateRoute';
import Dashboard from './auth/Dashboard';
import axios from 'axios';
import { AppStateProvider } from "./AppStateContext";
import { TokenContextProvider } from './TokenContext';
import { ResetRoute } from './auth/ResetRoute';
import ForgotPassword from './auth/ForgotPassword';
import ChangePasswordIndex from './auth/ChangePasswordIndex';
import Spinner from './dashboard/utility/Spinner';

(function() {
  const token = localStorage.getItem("token");
  if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
      axios.defaults.headers.common['Authorization'] = null;
      /*if setting null does not remove `Authorization` header then try     
        delete axios.defaults.headers.common['Authorization'];
      */
  }
})();

const App: React.FC = () => {

  return (
    <TokenContextProvider>
      <AppStateProvider>
      <Spinner />
        <Router>
          <PrivateRoute component={Dashboard} path="/*" />
          <ResetRoute component={ChangePasswordIndex} path="/reset/*"/>
          <ResetRoute component={ForgotPassword} path="/forgot/*"/>
        </Router>
      </AppStateProvider>
    </TokenContextProvider>
  );
}

export default App;
