import Axios from "axios";

export const callCompanies = async () => {
  try {
    
    
    const { data }: { data: any } = await Axios.post(
      `/api/utility/companies`,
      {

      }, 
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        id: response.id,
        shortName: response.shortName,
        name: response.name
      };
    } else {
      return {
        success: false,
        id: null,
        shortName: "",
        name: ""
      };
    }
  }
};