import * as React from "react";
import { Field, Form, Formik } from "formik";
import { Grid, makeStyles, Theme, Button, Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { UserFullModelType } from "../../model/user/UserFullModelType";
import { useState } from "react";
import { updateOrCreateUser } from "../../services/users/callUsers";
import { TextField} from 'formik-material-ui';
import { keyUpPhone, keyDownPhone, phoneRegex } from '../utility/Utils';
import ChangePassword from "../../auth/ChangePassword";

interface Props {
    user?: any;
    isUpdate: boolean;
    handleClose: any;
    updateUser: any;
    refresh: any;
    importedClasses: any;
    userInfo: any;
    setUserAfterUpdate: any;
    selfService?: boolean;
}

const useStyle = makeStyles((theme: Theme) => {
    return {
      formControl: {
        minWidth: 120,
        display: "flex",
        flexDirection: "column",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        width: "85%"
      },
      autocomplete: {
        // marginBottom: 25
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 300
      },
      checkbox: {
        margin: theme.spacing(2)
      }
    };
  });

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const UserForm: React.FC<Props> = props => {
    const { user, isUpdate, handleClose, updateUser, refresh, importedClasses, userInfo
      , setUserAfterUpdate, selfService} = props;
    const [error, setError] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);
    const classes = useStyle();
    const [sbOpen, setSBOpen] = useState(false);
    const [sbMessage, setSBMessage] = useState<string>("");
    const [sbSeverity, setSBSeverity] = useState<string>("info");
    const [sbTimeout, setSBTimeout] = useState<number>(6000);
    const showSnackbar = (message: string, severity: string = "info", timeout: number = 6000)  => {
      setSBOpen(true);
      setSBMessage(message);
      setSBSeverity(severity);
      setSBTimeout(timeout);
    }
    const handleSBClose = () => {
      setSBOpen(false);
    }

    const initialValues = isUpdate ? user : {
        name: '',
        id: null,
        companyId: null,
        contact: '',
        phone: '',
        email: '',
        active: true,
        salesPerson: null,
        projMgr: null,
        startDate: null,
        endDate: null,
        success: false,
        owner: userInfo.ownerId,
        createdBy: userInfo.fullName
      };

    const updateUserResult = async (updatedUser : UserFullModelType) => {
        updateUser(updatedUser.id);
    }

    // const keyDownPhone = (el: any) => {
    //   console.log('value = ' + el.key);
    //   if ((el.key !== 'Delete' && el.key !== 'Backspace' && el.key !== 'Tab' && !el.key.match(/^\d+$/)) ) {
    //     el.preventDefault();
    //   } else if (el.key !== 'Delete' && el.key !== 'Backspace' 
    //     && el.key !== 'Tab'&& el.target.value.replace(/[^0-9]/g, '').length > 9) {
    //     el.preventDefault();
    //   }
    // }

    // const keyUpPhone = (el: any) => {
    //   if (el.key.match(/^\d+$/)) {
    //     el.target.value = el.target.value.replace(phoneRegex, "($1) $2-$3");
    //   }
    //   console.log('value = ' + el.target.value);
    // }
    

    return <>
    
    <Formik
        isInitialValid={isUpdate ? true : false}
        initialValues={initialValues}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            showSnackbar("Saving...", "info", undefined);
            values.updatedBy = userInfo.fullName;
            const result: any = await updateOrCreateUser(values, isUpdate);
            if (result.success) {
              showSnackbar("Saved", "success", 6000);
              setError(result.error);
              setSubmitting(false);
              if (!isUpdate) {
                handleClose();
                updateUserResult(result.result);
                refresh();
              }
              setUserAfterUpdate(result.result);
            } else {
              showSnackbar("Error Saving", "error", undefined);
              setSubmitting(false);
              setError("");
              const fileInput: any = document.getElementById(
                "admin-add-customer-logo"
              );
              resetForm(initialValues);
            //   handleClose();
            }
          }}
    >
        <Form className={classes.formControl}>
        <Grid container spacing={3}
            direction="row"
            justify="space-evenly"
            alignItems="baseline">
            <Grid container spacing={1}>
                <Grid item>  
                    <Field
                    name="firstName"
                    placeholder={"First Name"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="First Name"
                    disabled={userInfo.userType !== '1' && userInfo.userType !== '2'}
                    required
                    />
                </Grid>
                <Grid item>  
                    <Field
                    name="middle"
                    placeholder={"Middle Name"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Middle Name"
                    disabled={userInfo.userType !== '1' && userInfo.userType !== '2'}
                    />
                </Grid>
                <Grid item>  
                    <Field
                    name="lastName"
                    placeholder={"Last Name"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Last Name"
                    disabled={userInfo.userType !== '1' && userInfo.userType !== '2'}
                    required
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item> 
                    <Field
                    name="cellphone"
                    placeholder={"Cell Phone"}
                    isParent={true}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Cell Phone"
                    render={({ field }: any) => (
                      <Field
                        className={classes.autocomplete}
                        component={TextField}
                        name="cellphone"
                        placeholder={"Cell Phone"}
                        value={field.value ? field.value.replace(phoneRegex, "($1) $2-$3") : ''}
                        margin="normal"
                        variant="outlined"
                        label="Cell Phone"
                        onKeyDown={keyDownPhone}
                        onKeyUp={keyUpPhone}
                      />
                    )}
                  />
                    
                </Grid>
                <Grid item> 
                <Field
                    name="phone"
                    placeholder={"Phone"}
                    isParent={true}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Phone"
                    onKeyDown={keyDownPhone}
                    onKeyUp={keyUpPhone}
                    render={({ field }: any) => (
                      <Field
                        className={classes.autocomplete}
                        component={TextField}
                        name="phone"
                        placeholder={"Phone"}
                        value={field.value ? field.value.replace(phoneRegex, "($1) $2-$3") : ''}
                        margin="normal"
                        variant="outlined"
                        label="Phone"
                        onKeyDown={keyDownPhone}
                        onKeyUp={keyUpPhone}
                      />
                    )}
                  />
                </Grid>
                <Grid item> 
                    <Field
                    name="email"
                    placeholder={"Email"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Email"
                    type="email"
                    required
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item>  
                    <Field
                    name="username"
                    placeholder={"UserName"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="UserName"
                    required
                    />
                </Grid>
                <Grid item>  
                    <Field
                    name="title"
                    placeholder={"Company"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Company"
                    disabled={userInfo.userType !== '1' && userInfo.userType !== '2'}
                    />
                </Grid>
            </Grid>
            
            <Grid container spacing={1}>
              {(parseInt(userInfo.userType) === 1 || parseInt(userInfo.userType) === 2)&&
              <Grid item>  
                  <Field
                  name="rate"
                  placeholder={"Billable Rate"}
                  isParent={false}
                  className={classes.autocomplete}
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                  label="Billable Rate"
                  disabled={parseInt(userInfo.userType) !== 1}
                  required
                  />
              </Grid>
              }
              <Grid item>  
                  <Field
                  name="payableRate"
                  placeholder={"Payable Rate"}
                  isParent={false}
                  className={classes.autocomplete}
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                  label="Payable Rate"
                  disabled={userInfo.userType !== '1'}
                  required
                  />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item>  
                  <Field
                  name="admin"
                  margin="normal"
                  variant="outlined"
                  type="checkbox"
                  className={classes.checkbox}
                  disabled={parseInt(userInfo.userType) > 1}
                  />
                  <label htmlFor='managed'>Admin</label>
              </Grid>
              <Grid item>  
                  <Field
                  name="projectManager"
                  margin="normal"
                  variant="outlined"
                  type="checkbox"
                  className={classes.checkbox}
                  disabled={parseInt(userInfo.userType) > 1}
                  />
                  <label htmlFor='projectManager'>Project Manager</label>
              </Grid>
              <Grid item>  
                  <Field
                  name="salesPerson"
                  margin="normal"
                  variant="outlined"
                  type="checkbox"
                  className={classes.checkbox}
                  disabled={parseInt(userInfo.userType) > 1}
                  />
                  <label htmlFor='salesPerson'>Sales Person</label>
              </Grid>
              {parseInt(userInfo.userType) > 3 && 
                <Grid item>  
                    <Field
                    name="developer"
                    margin="normal"
                    variant="outlined"
                    type="checkbox"
                    className={classes.checkbox}
                    disabled={true}
                    checked
                    />
                    <label htmlFor='developer'>Developer</label>
                </Grid>
              }
              <Grid item>  
                  <Field
                  name="active"
                  margin="normal"
                  variant="outlined"
                  type="checkbox"
                  className={classes.checkbox}
                  disabled={parseInt(userInfo.userType) > 1}
                  />
                  <label htmlFor='active'>Active</label>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item sm={6} lg={6}>  
                      <Field
                      name="notes"
                      placeholder={"Notes"}
                      isParent={false}
                      className={classes.autocomplete}
                      component={TextField}
                      margin="normal"
                      variant="outlined"
                      label="Notes"
                      multiline
                      rowsMax="4"
                      rows={4}
                      style={{width: "91%"}}
                      />
              </Grid>
            </Grid>
        </Grid>
        <Grid container sm>
            <Grid item style={{marginTop: "20px"}}>
                <Button type="submit" variant="contained" className={importedClasses.clockTrackButton} style={{float: "left", width: "92px"}}>Save</Button>
                {/* <Button onClick={handleClose} variant="contained" color="secondary" style={{float: "left", marginLeft: "10px", width: "92px"}}>Cancel</Button> */}
            </Grid>
        </Grid>
        </Form>
    </Formik>
    <Snackbar open={sbOpen} autoHideDuration={sbTimeout} message={sbMessage} onClose={handleSBClose}>
      <Alert onClose={handleSBClose} severity={sbSeverity}>{sbMessage}</Alert>
    </Snackbar>

    <div style={{float: 'left',marginLeft: "8px"}}>
        <ChangePassword userInfo={selfService ? userInfo : user} importedClasses={importedClasses}/>
    </div>
    </>
};
export default UserForm;
