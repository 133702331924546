import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Grid, Paper, Button} from "@material-ui/core";
import { useState, useEffect } from "react";
import { getAllTimeReports, callTimeReportBySprint  } from "../../services/report/callTimeReport";
import { displayLoader, hideLoader } from '../../services/utility/loader';
import MaterialTable from "material-table";
import dayjs from "dayjs";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { callSprints } from "../../services/utility/callSprints";

interface Props {
    setShowEnc?: any;
    showEnc?: boolean;
    userInfo: any;
    classesImported: any;
}

export const TimeReportByDeveloper: React.FC<Props & RouteComponentProps> = (props) => {

    const {userInfo , classesImported} = props;
    const [showTable, setShowTable] =  useState(false);
    const [timeReports, setTimeReports] = useState<any[]>([]);
    const [sprint, setSprint] = useState<any>(undefined);
    const [selectedDate, setSelectedDate] = useState(new Date());

    // const userSearch = () => {
    //     findTimeReports();
    // }

    const findTimeReports  = async () => {
        displayLoader();
        const response: any = await getAllTimeReports();
        if (response && response.success) {
            setTimeReports(response.results);
            hideLoader();
        }        
    };

    useEffect(() => {
        displayLoader();
        // userSearch();
        setShowTable(true);
        hideLoader();
    }, [userInfo]);

    const handleDateChange = (date: Date | null) => {
        if (date) {
          setSelectedDate(date);
        }
      };
  
      useEffect(() => {
          getSprints();
          
      }, [selectedDate]);

    const getSprints = async () => {
        const response: any = await callSprints(dayjs(selectedDate).format("YYYYMMDD"));
        if (response.success) {
          let list = response.result;
          if (list) {
            setSprint(list);
            hideLoader();
          }
        }  
      }

    useEffect(() => {
        if (sprint && sprint.startDateString && sprint.endDateString) {
            displayLoader();
            getReportTimesBySprint(dayjs(sprint.startDateString).format("YYYY-MM-DD"), dayjs(sprint.endDateString).format("YYYY-MM-DD"))
        }
    }, [sprint]);

    const getReportTimesBySprint = async(startDate: string, endDate: string) => {
        const response: any = await callTimeReportBySprint(startDate, endDate);
        if (response && response.success) {
            setTimeReports(response.results);
            hideLoader();
        } else {
            setTimeReports([]);
        }   
    }

  return (
      <>
            <Paper style={{margin: "3%"}}>
                <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}>
                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            autoOk={true}
                            margin="normal"
                            id="date-picker-dialog"
                            label="Sprint"
                            format="MM/dd/yyyy"
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                    <Button style={{marginTop: "20px"}} onClick={findTimeReports} variant="contained" className={classesImported.clockTrackButton} >Show All</Button>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                    {/* <Paper className={classes.paper}> */}
                    {showTable && 
                    <>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        >
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} alignItems="stretch">
                            <MaterialTable
                                components={{}}
                                columns={[  
                                    {
                                    title: 'Developer',
                                    field: 'username'
                                    },
                                    {
                                    title: '',
                                    field: 'title'
                                    },
                                    {
                                    title: 'Project Manager',
                                    field: 'pmUsername'
                                    },
                                    {
                                    title: 'Customer',
                                    field: 'company'
                                    },
                                    {
                                    title: 'Contact',
                                    field: 'contact'
                                    },
                                    {
                                    title: 'Dev Hours',
                                    field: 'devHours',
                                    align: 'right'
                                    },
                                    {
                                    title: 'PM Hours',
                                    field: 'pmHours',
                                    align: 'right'
                                    },
                                    {
                                    title: 'Total',
                                    field: 'total',
                                    align: 'right'
                                    },
                                    {
                                    title: 'Billable Rate',
                                    field: 'devRate',
                                    align: 'right'
                                    },
                                    {
                                    title: 'Payable Rate',
                                    field: 'dmPayableRate',
                                    align: 'right'
                                    },
                                    {
                                    title: 'Dev Payable Total',
                                    field: 'devPayable',
                                    align: 'right'
                                    },
                                    {
                                    title: 'PM Billable Rate',
                                    field: 'pmRate',
                                    align: 'right'
                                    },
                                    {
                                    title: 'PM Payable Rate',
                                    field: 'pmPayableRate',
                                    align: 'right'
                                    },
                                    {
                                    title: 'PM Payable',
                                    field: 'pmPayable'
                                    },
                                    {
                                    title: 'Project Name',
                                    field: 'projectName'
                                    },
                                    {
                                    title: 'Billable Total',
                                    field: 'totalAmount',
                                    align: 'right'
                                    },
                                    {
                                    title: 'Payable Total',
                                    field: 'totalPayableAmount',
                                    align: 'right'
                                    },
                                    {
                                    title: 'Start Date',
                                    field: 'startDate',
                                        render: (rowData: any) => {
                                            return (
                                            <>{dayjs(rowData.startDate).format("MM/DD/YYYY")}</>
                                        );
                                        }
                                    },
                                    {
                                    title: 'End Date',
                                    field: 'endDate',
                                    render: (rowData: any) => {
                                        return (
                                        <>{dayjs(rowData.endDate).format("MM/DD/YYYY")}</>
                                    );
                                    }
                                    },
                                    {
                                    title: 'Comments',
                                    field: 'comments'
                                    }
                                ]}
                                    options={{
                                    exportButton: true,
                                    search: true,
                                    showTitle: false,
                                    exportFileName: "time_entries",
                                    rowStyle: {
                                        verticalAlign: "baseline"
                                    },
                                    columnsButton: true,
                                    exportAllData: true,
                                    pageSize: 20,
                                }}
                                data={timeReports}
                            />
                        </Grid>
                    </Grid>
                    </>
                    }

                </Grid>
                </Paper>
            </>
  );
};

export default TimeReportByDeveloper;