import * as React from "react";
import { callLoginApi } from "./authenticate";
import {
  Button,
  Theme,
  Grid,
  OutlinedInput,
  Card, CardContent, Link,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";
import Axios from "axios";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useState } from "react";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ForgotPassword from "./ForgotPassword";
import ChangePasswordIndex from "./ChangePasswordIndex";


interface Props {
  authenticated: boolean;
  handleLogin: any
  importedClasses?: any;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
  
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '300px',
      },
      '& .MuiButtonBase-root': {
        margin: theme.spacing(2),
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        margin: theme.spacing(1),
        minWidth: 300,
      },
      dense: {
        marginTop: theme.spacing(2),
      },
      menu: {
        width: 200,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      cardStyle: {
        border: 'gray solid thin',
        marginTop: theme.spacing(10),
        width: 374
      },
      clockTrackButton: {
          margin: theme.spacing(1),
          color: "#333333",
          '&:hover': {
              textDecoration: "none",
              color: "#25ca94"
          },
          fontWeight: "bold"
      },
      linkStatic: {
          margin: theme.spacing(1),
          color: "#333333",
          '&:hover': {
              textDecoration: "none",
              color: "#25ca94"
          },
          fontWeight: "bold"
      }
  }),
);

/**
 * Login component.
 */
const Login: React.FC<Props> = (props) => {
  const { authenticated, handleLogin } = props;
  // const [error, setError] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const forgot = params.get('help');
  const token = params.get('token');

  const authenticateUser = async () => {
    const response = await
    console.log('auth clicked');

    // const loginResponse = await callLoginApi({
    //   username: username,
    //   password: password
    // });

    const {
      firstName,
      lastName,
      success,
      token,
      sessionTimeout,
      expires,
      ownerId,
      error,
      userType,
      userId,
      admin,
      pm,
      sp,
      id,
    } = await callLoginApi({
      username: username,
      password: password
    });
  
    if (success && token) {
      Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      handleLogin({firstName,
        lastName,
        token,
        sessionTimeout,
        expires,
        ownerId,
        userType,
        userId,
        admin,
        pm,
        sp,
        id});
      return {
        firstName,
        lastName,
        token,
        sessionTimeout,
        expires,
        ownerId,
        userId,
        admin,
        pm,
        sp,
        id
      };
    } else {
      setMessage(error as string);
      return ;
    }
  };

  const handleChangePw = (event: any) => {
    setPassword(event.target.value);
  } 

  const handleChangeUser = (event: any) => {
    setUserName(event.target.value);
  }

  const keyUpEnter = (event: any) => {
    if (event.keyCode === 13) {
        authenticateUser();
    }
  }

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  
  return <>

    {forgot && !token &&
      <ForgotPassword />
    }

    {token && !forgot && 
      <ChangePasswordIndex />
    }
    
    {!forgot && !token && 
    <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={10}
        >
      <Card className={classes.cardStyle}>
        <CardContent>
          <img src="/images/png/fhg-logo-90.png" alt="FHG logo"/>
          <Grid item>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
            <OutlinedInput
              id="outlined-adornment-username"
              value={username}
              onChange={handleChangeUser}
              label="Username"
              className={classes.textField}
            />
          </FormControl>
          </Grid>
          <Grid item>
          <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handleChangePw}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPasswordClick}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                className={classes.textField}
                onKeyUp={keyUpEnter}
              />
            </FormControl>
            </Grid>
            <Grid item>
          <Button onClick={authenticateUser} variant="contained" className={classes.clockTrackButton} >Login</Button>
          <Link href={"/?help=forgot"} className={classes.linkStatic}>Forgot Password?</Link>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">
            <div style={{color: 'red'}}>{message}</div>
           </Grid>
          </CardContent>
          </Card>
        </Grid>
      }
    
  </>;
};

export default Login;
