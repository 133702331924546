export const keyDownPhone = (el: any) => {
    // console.log('value = ' + el.key);
    if ((el.key !== 'Delete' && el.key !== 'Backspace' && el.key !== 'Tab' && !el.key.match(/^\d+$/)) ) {
        el.preventDefault();
    } else if (el.key !== 'Delete' && el.key !== 'Backspace' 
        && el.key !== 'Tab'&& el.target.value.replace(/[^0-9]/g, '').length > 9) {
        el.preventDefault();
    }
};

export const keyUpPhone = (el: any) => {
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (el.key.match(/^\d+$/)) {
        el.target.value = el.target.value.replace(phoneRegex, "($1) $2-$3");
    }
    // console.log('value = ' + el.target.value);
}

export const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const validJwt = (error: any) => {
    let windowG : any = window;
    if (error.response.status === 401 || error.response.status === '401') {
        windowG.location = "/" as unknown as string;
    }
}