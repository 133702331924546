import * as React from "react";
import MaterialTable, { MTableBody } from "material-table";
import { Grid, makeStyles, Theme, Paper, Link, Button, Typography, TableFooter, TableRow, TableCell, TableHead} from "@material-ui/core";
import { withStyles, createStyles, WithStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { callTimeEntryByPatientIdLastFive, deleteTimeEntry, callTimeEntryByUserIdAndSprint, 
  callTimeEntryByProjectIdAndSprint } from "../../services/entries/callTimeEntry";
import { callSprints } from "../../services/utility/callSprints";
import { displayLoader, hideLoader } from "../../services/utility/loader";
import TimeEntryForm from "./TimeEntryForm";
import dayjs from "dayjs";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { approveTimeSheet, callProjectTimeApprovalByUserIdAndProjectAndSprint, 
  recallTimeSheet, updateOrCreateProjectTimeApproval, recallApprovedTimeSheet } from "../../services/entries/callApprovalEntry";
import { callApprovedTimeByProjectIdAndSprintAndYear } from "../../services/entries/callApprovedTime";

interface Props extends WithStyles<typeof styles> {
    // handleClick: any;
    lastFive?: boolean;
    // setShowEnc?: any;
    setTimeEntryState?: any;
    setTimeEntryStateUpdate? : any;
    projectId?: number;
    companyId?: number;
    showEdit: boolean;
    timeEntry?: any;
    isUpdate: boolean;
    setIsUpdate: any;
    userInfo: any;
    importedClasses: any;
}

const useStyle = makeStyles((theme: Theme) => {
    return {
      formControl: {
        minWidth: 120,
        display: "flex",
        flexDirection: "column",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        width: "100%"
      },
      autocomplete: {
        // marginBottom: 25
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      approved: {
        color: 'green'
      },
      errorMsg: {
        color: 'red'
      } 
    };
  });

  const styles = createStyles({

    }
  );

  const TimeEntryTable: React.FC<Props> = props => {
    const { lastFive, setTimeEntryState, setTimeEntryStateUpdate,
      projectId, companyId, showEdit, timeEntry, isUpdate, setIsUpdate, userInfo,importedClasses } = props;
    const classNames = useStyle();
    const [timeEntries, setTimeEntrys] = useState<any[]>([]);
    // const token = localStorage.getItem("token");
    const [selectedDate, setSelectedDate] = useState(timeEntry ? timeEntry.workDate : new Date());
    const [sprint, setSprint] = useState<any>(undefined);
    const [currentSprint, setCurrentSprint] = useState<any>(undefined);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [showSubmit, setShowSubmit] = useState(true);
    const [showRecall, setShowRecall] = useState(false);
    const [showApprove, setShowApprove] = useState(false);
    const [showApproveRecall, setShowApproveRecall] = useState(false);
    const [approvalId, setApprovalId] = useState(0);
    const [approvalMessage, setApprovalMessage] = useState('');
    const [showSave, setShowSave] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const handleDateChange = (date: Date | null) => {
      if (date) {
        setSelectedDate(date);
        setTimeEntryState({workDate: date});
      }
    };

    const handleSetTimeEntry = (rowData : any) => {
      setTimeEntryStateUpdate(rowData);
    }

    useEffect(() => {
      if (userInfo.userId && !isNaN(userInfo.userId)) {
        getSprints();
        hideLoader();
      }
    }, [selectedDate]);

    const getSprints = async () => {
      const selected = dayjs(selectedDate).set('hour', 12).set('minute', 0).set('second', 0).set('millisecond', 0);
      const response: any = await callSprints(dayjs(selected).format("YYYYMMDD"));
      if (response.success) {
        let sprint = response.result;
        if (sprint && projectId) {
          setSprint(sprint);
          if (!currentSprint) {
            const curRes: any = await callSprints(dayjs(new Date()).format("YYYYMMDD"));
            if (curRes.success) {
              setCurrentSprint(curRes.result.sprintNumber);
            }
          }
          const today = dayjs();
          const sprintEnd =dayjs(sprint.endDateString);
          const pastSprint = today.isAfter(sprintEnd);

          setStartDate(dayjs(sprint.startDateString).format("MM/DD/YYYY"));
          setEndDate(dayjs(sprint.endDateString).format("MM/DD/YYYY"));
          setMaxDate(pastSprint ? dayjs(sprintEnd).format("MM/DD/YYYY") : dayjs().format("MM/DD/YYYY"));
          checkIfAllClosed(projectId, sprint.sprintNumber, sprint.year);
        }
      }  
    }

    const checkIfAllClosed = async(projectId: number, sprintNumber: number, year: number) => {
      if (projectId && sprint && year) {
        const response: any = await callApprovedTimeByProjectIdAndSprintAndYear(projectId, sprintNumber, year)
        if (response && response.success && response.results.length > 0) {
          let submitted = response.results[0].submitted;
          let approved = response.results[0].approved;
          if (submitted) {
            if (approved) {
              setShowSave(false);
              setShowApprove(false);
              setShowSubmit(false);
              setApprovalMessage("Timesheet Approved");
              if (userInfo.isPm === true || userInfo.isAdmin === true) {
                setShowApproveRecall(true);
              }
            } else {
              setShowApprove(true);
              setShowSubmit(false);            }
          }

          if (response.results[0].submitted === false) {
            setShowSubmit(true);
          }
        } else {
          setShowApprove(true);
          // setShowSubmit(true);
          // setShowRecall(false);
          setApprovalMessage('');
          if (sprint.sprintNumber === currentSprint) {
            setShowSubmit(true);
          }
        }
      }
    }

    useEffect(()=> {
      if (projectId && sprint) {
        getTimesheetStatus();
      }
    }, [projectId, sprint]);

    useEffect(() => {
      const entries = timeEntries.filter((te) => {
        return te.user === parseInt(userInfo.userId); 
      })

      if (timeEntries.length === 0 || entries.length === 0) {
        setDisableSubmit(true);
      } else {
        setDisableSubmit(false);
      }

    }, [timeEntries])

  
    const getTimeEntrys = async () => {
      if (lastFive) {
        const response: any = await callTimeEntryByPatientIdLastFive(userInfo.userId);
        if (response.success) {
          let list = response.results;
          setTimeEntrys(list);
        }  
      } else {
        if (sprint && (userInfo.isPm === true || userInfo.isAdmin === true ) && projectId) {
          const response: any = await callTimeEntryByProjectIdAndSprint(projectId, sprint.sprintNumber, sprint.year);
            if (response.success) {
                let list = response.results;
                setTimeEntrys(list);
            }  
          } else if (sprint && projectId) {
            const response: any = await callTimeEntryByUserIdAndSprint(userInfo.userId, sprint.sprintNumber, projectId, sprint.year);
              if (response.success) {
                  let list = response.results;
                  setTimeEntrys(list);
              }  
            }
          }
      } 

    const getTimesheetStatus = async() => {
      if (projectId) {
        getTimeEntrys();
        const response: any = await callProjectTimeApprovalByUserIdAndProjectAndSprint(userInfo.userId, projectId, sprint.sprintNumber, sprint.year);
        
        if (response && response.success) {
          const projApp = response.results[0];

          if (projApp) {
            setApprovalId(projApp.id);
            if (projApp.submitted && !projApp.approved) {
              // checkIfAllClosed(projectId, sprint.sprintNumber, sprint.year);
              setShowSubmit(false);
              setShowRecall(true);
              setShowApprove(true);
            } else {
              // setShowRecall(true);
              // setShowSubmit(false);
              // setShowApprove(false);
              checkIfAllClosed(projectId, sprint.sprintNumber, sprint.year);
            }
          } else {
            // setShowSubmit(true);
            // setShowApprove(false);
            // setShowRecall(false);
            checkIfAllClosed(projectId, sprint.sprintNumber, sprint.year);
          }
        }
      }
    }

    const recallTimeSheetSubmit = async() => {
      displayLoader();
      if (approvalId && approvalId !== 0) {
        const response: any = await recallTimeSheet(approvalId);

        if (response && response.success) {
          setApprovalId(0);
          setShowSubmit(true);
          setShowRecall(false);
          setShowApprove(false);
          hideLoader();
        }
      }
    }

    const approveTimeSheetSubmit = async() => {
      displayLoader();
      if (projectId && projectId !== 0) {
        const response: any = await approveTimeSheet(projectId, sprint.sprintNumber, sprint.year);

        if (response && response.success) {
          setApprovalId(response.id);
          setShowSubmit(false);
          setShowRecall(false);
          setShowApprove(false);
          setApprovalMessage("Timesheet Approved");
          setShowApproveRecall(true);
          console.log('Hide Approve 4');
          hideLoader();
        }
      }
    }

    const submitTimeSheet = async() => {
      displayLoader();
      if (projectId) {
        const approval: any = {
          companyId: companyId,
          projectId: projectId,
          userId: userInfo.userId,
          submitted: true,
          approved: false,
          sprint: sprint.sprintNumber,
          updated: new Date(),
          created: new Date(),
          year: sprint.year
        }

        const response: any = await updateOrCreateProjectTimeApproval(approval, false);

        if (response && response.success) {
          setApprovalId(response.result.id);
          setShowSubmit(false);
          setShowRecall(true);

          if (userInfo.isPm === true) {
            // setShowApprove(true);
            checkIfAllClosed(projectId, sprint.sprintNumber, sprint.year);
          }
          
          hideLoader();
        }
      }
    }

    const deleteTime = async (id: number) => {
      displayLoader();
      const response = await deleteTimeEntry(id);
      if (response && response.success) {
        getTimeEntrys();
        if (projectId) {
          getTimesheetStatus();
        }
        hideLoader();
      }      
    }

    const recallApprovedTime = async () => {
      displayLoader();
      if (projectId && projectId !== 0) {
        const response: any = await recallApprovedTimeSheet(projectId, sprint.sprintNumber, sprint.year);

        if (response && response.success) {
          setApprovalId(0);
          // setShowSubmit(true);
          // setShowRecall(false);
          // setShowApprove(true);
          getTimesheetStatus();
          setShowApproveRecall(false);
          setApprovalMessage('');
          hideLoader();
        }
      }
    }

    return <div className={classNames.formControl}>
      <Grid container md style={{marginBottom: "20px"}}>
        <Grid item style={{marginTop: "20px", marginRight: "10px"}}>
          <strong>Start Date:</strong> {startDate}&nbsp;
          <br/>
          <strong>End Date:</strong> {endDate}
        </Grid>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                variant="inline"
                autoOk={true}
                margin="normal"
                id="date-picker-dialog"
                label="Work Week"
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
          </MuiPickersUtilsProvider>
        </Grid>
        {showSubmit && 
          <Grid item><Button type="submit" variant="contained" className={importedClasses.clockTrackButton} style={{margin: "20px"}} onClick={submitTimeSheet} disabled={disableSubmit}>Submit Timesheet</Button></Grid>
        }
        {showRecall &&
          <Grid item><Button type="submit" variant="contained" className={importedClasses.clockTrackButton} style={{margin: "20px"}} onClick={recallTimeSheetSubmit}>Recall Timesheet</Button></Grid>
        }
        {showApproveRecall &&
          <Grid item><Button type="submit" variant="contained" className={importedClasses.clockTrackButton} style={{margin: "20px"}} onClick={recallApprovedTime}>Recall Approved Timesheet</Button></Grid>
        }
        {(userInfo.isPm === true || userInfo.isAdmin === true) && showApprove &&
          <Grid item><Button type="submit" variant="contained" className={importedClasses.clockTrackButton} style={{margin: "20px"}} onClick={approveTimeSheetSubmit}>Approve All Times</Button></Grid>
        }
          <Typography className={classNames.approved}>
            {approvalMessage}
          </Typography>
    </Grid>
    {!showRecall &&
    <Grid container md style={{paddingBottom: "10px"}}>
      <Grid item>
        <Paper>
        {/* (currentSprint === sprint.sprintNumber) && */}
          {sprint !== undefined && projectId && showSave &&  
            <Grid item>
              <TimeEntryForm isUpdate={isUpdate} timeEntry={timeEntry} sprint={sprint} projectId={projectId} user={userInfo.userId} refresh={getTimesheetStatus}
              setIsUpdate={setIsUpdate} importedClasses={importedClasses} showSave={showSave} startDate={startDate} endDate={endDate} maxDate={maxDate}/>
            </Grid>
          }
        </Paper>
      </Grid>
    </Grid>
    }
    <MaterialTable
        components={{
          Body: (props) => {
              let totalObj = {
                actualSum: 0
              }
              props.renderData.forEach((rowData: any) => {
                totalObj.actualSum += rowData.hours;
              });
              return (
              <>
                <TableHead>
                  <TableRow style={{margin: "0 auto"}}>
                    <TableCell colSpan={2} />
                    <TableCell colSpan={1} style={{fontWeight: "bold", fontSize: "1em"}} align="right">Total: {totalObj.actualSum}</TableCell>
                    <TableCell colSpan={4} />
                  </TableRow>
                </TableHead>
                <TableFooter>
                  <TableRow style={{margin: "0 auto"}}>
                    <TableCell colSpan={2} />
                    <TableCell colSpan={1} style={{fontWeight: "bold", fontSize: "1em"}} align="right">Total: {totalObj.actualSum}</TableCell>
                    <TableCell colSpan={4} />
                  </TableRow>
                </TableFooter>
                <MTableBody {...props}/>
                
              </>
            )
          }
      }}
        columns={[
            {
                title: '',
                field: '',
                render: (rowData: any) => {
                    return (
                      <>
                      {/* (currentSprint === sprint.sprintNumber) && */}
                      {showSubmit && (userInfo ? parseInt(userInfo.userId) : undefined)  === rowData.user &&  
                      <>
                        <Link onClick={() => {handleSetTimeEntry(rowData)}} className={(!showEdit ? `${importedClasses.hidden} ${importedClasses.link}`: importedClasses.link)} >
                          Edit
                        </Link>
                        &nbsp;&nbsp;
                        <Link onClick={() => {deleteTime(rowData.id)}} className={(!showEdit ? `${importedClasses.hidden} ${importedClasses.link}`: importedClasses.link)} >
                          Delete
                        </Link>
                        </>
                      }
                    </>
                );
                }
            },
            {
              title: 'User Name',
              field: 'userName',
              render: (rowData: any) => {
                return (
                  <>
                  {(userInfo ? parseInt(userInfo.userId) : undefined)  === rowData.user &&
                    `You`
                  }
                  {(userInfo ? parseInt(userInfo.userId) : undefined)  !== rowData.user &&
                    rowData.userName
                  }
                </>
            );
            }
            },
            {
              title: 'Hours',
              field: 'hours',
              align: "right",
            },
            {
              title: 'Work Date',
              field: 'workDateDisplay',
              render: (rowData: any) => {
                  return (
                    <span>
                    {/* {dayjs(rowData.workDate).format("MM/DD/YYYY")} */}
                    {rowData.workDateDisplay}
                    </span>
              );
              }
            },
            {
              title: 'Year',
              field: 'year'
            },
            {
              title: 'Comments',
              field: 'comments'
            },
            {
              title: 'PM Hours',
              field: 'pmHours'
            }
        ]}
        options={{
          exportButton: true,
          search: true,
          sorting: lastFive ? false : true,
          showTitle: false,
          exportFileName: "time_entries",
          paging: lastFive ? false : true,
          rowStyle: {
            verticalAlign: "baseline"
          },
          pageSize: 20,
          exportAllData: true,     
        }}
        data={timeEntries}
      />
    </div>
  };

  export default withStyles(styles)(TimeEntryTable);
