import Axios from "axios";
import { UserTimeEntryModelType } from "../../model/entries/UserTimeEntryModelType";


export const callUserTimeReportBySprint = async (
  userId : number,
  sprintNumber: number,
  year: number,
  isPm?: boolean
) => {
  try {
    
    const url = isPm ? `/api/userTimeReport/pmTime/${userId}/${sprintNumber}/${year}` : `/api/userTimeReport/${userId}/${sprintNumber}/${year}`;
    
    const { data }: { data: UserTimeEntryModelType[] } = await Axios.post(url, {},);

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response
      };
    } else {
      return {
        success: false,
        error: response
      };
    }
  }
};