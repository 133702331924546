import Axios from "axios";
import { ApprovedTimeModel } from "../../model/entries/ApprovedTimeModel";

export const callAllSubmittedTime = async () => {
  try {
    
    
    const { data }: { data: ApprovedTimeModel[] } = await Axios.post(
      `/api/submittedTime/all`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response
      };
    } else {
      return {
        success: false,
        error: response
      };
    }
  }
};
