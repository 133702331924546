import Axios from "axios";
import { UserFullModelType } from "../../model/user/UserFullModelType";

export const changePassword = async (user : UserFullModelType) => {
  try {
    
    const { data }: { data: any } = await Axios.post(
      `/reset/changePassword`,
      {
        user: user
      }, 
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        id: response.id
      };
    } else {
      return {
        success: false,
        id: null
      };
    }
  }
};

export const resetPw = async (token: String) => {
  try {
    
    const { data }: { data: any } = await Axios.post(
      `/reset/${token}`,
      {
      }, 
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        id: response.id
      };
    } else {
      return {
        success: false,
        id: null
      };
    }
  }
};

export const forgot = async (email : String) => {
  try {
    
    const { data }: { data: any } = await Axios.post(
      `/forgot/${email}`,
      {
      }, 
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        id: response.id
      };
    } else {
      return {
        success: false,
        id: null
      };
    }
  }
};
