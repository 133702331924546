import Axios from "axios";
import { ProjectTimeApprovalModelType } from "../../model/entries/ProjectTimeApprovalModelType";


export const callProjectTimeApprovalById = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: ProjectTimeApprovalModelType[] } = await Axios.post(
      `/api/projectTimeApproval/${id}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        updated: response.updated,
        created: response.created,
        updatedBy: response.updatedBy,
        createdBy: response.createdBy,
        version: response.version,
        id: response.id,
        companyId: response.companyId,
        userId: response.userId,
        sprint: response.sprint,
        submitted: response.submitted,
        approved: response.approved,
        projectId: response.projectId
      };
    } else {
      return {
        success: false,
        error: response,
        updated: null,
        created: null,
        updatedBy: null,
        createdBy: null,
        version: null,
        id: null,
        companyId: null,
        userId: null,
        sprint: null,
        submitted: false,
        approved: false,
        projectId: null
      };
    }
  }
};

export const updateOrCreateProjectTimeApproval = async (
  projectTimeApproval : ProjectTimeApprovalModelType,
  isUpdate: boolean
) => {
  try {
    
    const url = isUpdate ? `/api/projectTimeApproval/${projectTimeApproval.id}/full/update` : `/api/projectTimeApproval/new`;
    
    const { data }: { data: ProjectTimeApprovalModelType[] } = await Axios.post(
      url,
      {
        projectTimeApproval: projectTimeApproval
      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: true,
        error: response,
        updated: response.updated,
        created: response.created,
        updatedBy: response.updatedBy,
        createdBy: response.createdBy,
        version: response.version,
        id: response.id,
        companyId: response.companyId,
        userId: response.userId,
        sprint: response.sprint,
        submitted: response.submitted,
        approved: response.approved,
        projectId: response.projectId
      };
    } else {
      return {
        success: false,
        error: response,
        updated: null,
        created: null,
        updatedBy: null,
        createdBy: null,
        version: null,
        id: null,
        companyId: null,
        userId: null,
        sprint: null,
        submitted: false,
        approved: false,
        projectId: null
      };
    }
  }
};

export const approveTimeSheet = async (
  projectId: number,
  sprint: number, 
  year: number
) => {
  try {
    
    const url = `/api/projectTimeApproval/approveTime/${projectId}/${sprint}/${year}/full/approve`;
    
    const { data }: { data: ProjectTimeApprovalModelType[] } = await Axios.post(
      url,
      {
      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: true,
        error: response,
        updated: response.updated,
        created: response.created,
        updatedBy: response.updatedBy,
        createdBy: response.createdBy,
        version: response.version,
        id: response.id,
        companyId: response.companyId,
        userId: response.userId,
        sprint: response.sprint,
        submitted: response.submitted,
        approved: response.approved,
        projectId: response.projectId
      };
    } else {
      return {
        success: false,
        error: response,
        updated: null,
        created: null,
        updatedBy: null,
        createdBy: null,
        version: null,
        id: null,
        companyId: null,
        userId: null,
        sprint: null,
        submitted: false,
        approved: false,
        projectId: null
      };
    }
  }
};

export const recallApprovedTimeSheet = async (
  projectId: number,
  sprint: number, 
  year: number
) => {
  try {
    
    const url = `/api/projectTimeApproval/recallApprovedTime/${projectId}/${sprint}/${year}/full/approve`;
    
    const { data }: { data: ProjectTimeApprovalModelType[] } = await Axios.post(
      url,
      {
      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: true,
        error: response,
        updated: response.updated,
        created: response.created,
        updatedBy: response.updatedBy,
        createdBy: response.createdBy,
        version: response.version,
        id: response.id,
        companyId: response.companyId,
        userId: response.userId,
        sprint: response.sprint,
        submitted: response.submitted,
        approved: response.approved,
        projectId: response.projectId
      };
    } else {
      return {
        success: false,
        error: response,
        updated: null,
        created: null,
        updatedBy: null,
        createdBy: null,
        version: null,
        id: null,
        companyId: null,
        userId: null,
        sprint: null,
        submitted: false,
        approved: false,
        projectId: null
      };
    }
  }
};

export const callProjectTimeApprovalByUserId = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: ProjectTimeApprovalModelType[] } = await Axios.post(
      `/api/projectTimeApproval/callProjectTimeApprovalByUserId/${id}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        id: response.id,
        companyId: response.companyId,
        userId: response.userId,
        sprint: response.sprint,
        submitted: response.submitted,
        approved: response.approved,
        projectId: response.projectId
      };
    } else {
      return {
        success: false,
        error: response,
        id: null,
        companyId: null,
        userId: null,
        sprint: null,
        submitted: false,
        approved: false,
        projectId: null
      };
    }
  }
};

export const callProjectTimeApprovalByUserIdAndSprint = async (
  id : number,
  sprintId: number,
  year: number
) => {
  try {
    
    
    const { data }: { data: ProjectTimeApprovalModelType[] } = await Axios.post(
      `/api/projectTimeApproval/callProjectTimeApprovalBySprint/${id}/${sprintId}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        id: response.id,
        companyId: response.companyId,
        userId: response.userId,
        sprint: response.sprint,
        submitted: response.submitted,
        approved: response.approved,
        projectId: response.projectId
      };
    } else {
      return {
        success: false,
        error: response,
        id: null,
        companyId: null,
        userId: null,
        sprint: null,
        submitted: false,
        approved: false,
        projectId: null
      };
    }
  }
};

export const callProjectTimeApprovalByUserIdAndProjectAndSprint = async (
  id : number,
  projectId: number,
  sprintNumber: number,
  year: number
) => {
  try {
    
    
    const { data }: { data: ProjectTimeApprovalModelType[] } = await Axios.post(
      `/api/projectTimeApproval/callProjectTimeApprovalByUserIdAndProjectAndSprint/${id}/${projectId}/${sprintNumber}/${year}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        id: response.id,
        companyId: response.companyId,
        userId: response.userId,
        sprint: response.sprint,
        submitted: response.submitted,
        approved: response.approved,
        projectId: response.projectId
      };
    } else {
      return {
        success: false,
        error: response,
        id: null,
        companyId: null,
        userId: null,
        sprint: null,
        submitted: false,
        approved: false,
        projectId: null
      };
    }
  }
};

export const newProjectTimeApproval = async (
  userId : number,
  projectId: number,
  sprintNumber: number
) => {
  try {
    
    let url = `/api/projectTimeApproval/${userId}/${projectId}/${sprintNumber}/newProjectTimeApproval`;

    const { data }: { data: ProjectTimeApprovalModelType[] } = await Axios.post(
      url,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: true,
        error: response,
        updated: response.updated,
        created: response.created,
        updatedBy: response.updatedBy,
        createdBy: response.createdBy,
        version: response.version,
        id: response.id,
        companyId: response.companyId,
        userId: response.userId,
        sprint: response.sprint,
        submitted: response.submitted,
        approved: response.approved,
        projectId: response.projectId
      };
    } else {
      return {
        success: false,
        error: response,
        updated: null,
        created: null,
        updatedBy: null,
        createdBy: null,
        version: null,
        id: null,
        companyId: null,
        userId: null,
        sprint: null,
        submitted: false,
        approved: false,
        projectId: null
      };
    }
  }
};

export const recallTimeSheet = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: ProjectTimeApprovalModelType[] } = await Axios.post(
      `/api/projectTimeApproval/${id}/delete`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response
      };
    } else {
      return {
        success: false,
        error: response
      };
    }
  }
};