import * as React from "react";
import { Field, Form, Formik } from "formik";
import { Grid, makeStyles, Theme, MenuItem, FormControlLabel, Radio, Button} from "@material-ui/core";
import { TimeEntryModelType } from "../../model/entries/TimeEntryModelType";
import { useState, useEffect } from "react";
import { getAvailableUsers } from "../../services/users/callUsers";
// import moment from 'moment';
import { displayLoader, hideLoader } from '../../services/utility/loader';
import { TextField } from 'formik-material-ui';
import DropDown from "../common/DropDown";
import { allProjects, getAllProjects } from "../../services/entries/callProjectApi";
import { updateOrCreateUserProject } from "../../services/project/callUserProject";

interface Props {
    userProject?: any;
    isUpdate: boolean;
    projectId: number;
    user: number;
    refresh: any;
    setIsUpdate: any;
    userInfo: any; 
    importedClasses: any;
}

const useStyle = makeStyles((theme: Theme) => {
    return {
      formControl: {
        minWidth: 120,
        display: "flex",
        flexDirection: "column",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        // width: "85%"
      },
      autocomplete: {
        // marginBottom: 25
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 300
      },
      checkbox: {
        margin: theme.spacing(2)
      }
    };
  });



const ProjectUserForm: React.FC<Props> = props => {
    const { userProject, isUpdate, projectId, user, 
      refresh, setIsUpdate, userInfo, importedClasses} = props;
    const [error, setError] = useState("");
    const [users, setUsers] = useState<any[]>([]);
    const [projects, setProjects] = useState<any[]>([]);
    const classes = useStyle();

    const initialValues = isUpdate ? userProject : {
        id: '',
        projectId: projectId,
        user: undefined,
        active: true
      };

    const [initVals, setInitVals] = useState(initialValues);
      
    const userSearch = () => {
        findUsers();
    }

    const findUsers  = async () => {
        const response: any = await getAvailableUsers(projectId);
        if (response && response.success) {
            setUsers(response.results);
            hideLoader();
        }        
    };

    const findProjects  = async () => {
      const response: any = await allProjects();
      if (response && response.success) {
          setProjects(response.results);
          hideLoader();
      }        
    };

    useEffect(() => {
      findUsers();
      findProjects();
    }, []);

  return <>
    
    <Formik
        isInitialValid={isUpdate ? true : false}
        enableReinitialize
        initialValues={initVals}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            const result: any = await updateOrCreateUserProject(values, isUpdate);
            if (result.success) {
                // updateTimeEntry(result.result);
                if (!isUpdate) {
                    // updateTimeEntryResult(result.result);
                }
              setError(result.error);
              setSubmitting(false);
              resetForm(initVals);
              refresh();
              setIsUpdate(false);
              userSearch();
            } else {
              setSubmitting(false);
              setError("");
              const fileInput: any = document.getElementById(
                "admin-add-customer-logo"
              );
              resetForm(initVals);
            //   handleClose();
            }
          }}
    >
        <Form className={classes.formControl}>
        <Grid container spacing={1}
            direction="row"
            justify="space-evenly"
            alignItems="baseline">
            <Grid container spacing={1}>
              <Grid item> 
                  <Field
                  disabled={true}
                  type="text"
                  name="projectId"
                  select
                  className={classes.autocomplete}
                  component={TextField}
                  helperText=""
                  margin="normal"
                  variant="outlined"
                  label="Project"
                  InputLabelProps={{
                      shrink: true,
                    }}
                  >
                  {projects.map(option => (
                      <MenuItem key={option.name} value={option.id}>
                      {option.name}
                      </MenuItem>
                  ))}
                  </Field>
              </Grid>
              <Grid item> 
                <DropDown data={users} label={"Developer"} name={"user"} classes={classes} userInfo={userInfo}/>
              </Grid>
            </Grid>
        </Grid>
        <Button type="submit" variant="contained" className={importedClasses.clockTrackButton} style={{float: "left", width: "92px"}}>Save</Button>
      </Form>

    </Formik>
  
    </>
};

export default ProjectUserForm;