import Axios from "axios";
import { TimeEntryModelType } from "../../model/entries/TimeEntryModelType";
import { TimeEntryReportModelType } from "../../model/entries/TimeEntryReportModelType";

export const findAllGroupByYear = async (
) => {
  try {
    
    
    const { data }: { data: TimeEntryReportModelType[] } = await Axios.post(
      `/api/timeReport/findAllGroupByYear`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        id: response.id,
        companyId: response.companyId ,
        company: response.company ,
        projectId: response.projectId ,
        devHours: response.devHours ,
        devAmount: response.devAmount,
        devRate: response.devRate,
        pmHours: response.pmHours ,
        pmAmount: response.pmAmount,
        pmRate: response.pmRate,
        total: response.total ,
        totalAmount: response.totalAmount,
        username: response.username ,
        pmUsername: response.pmUsername ,
        projectName: response.projectName ,
        startDate: response.startDate ,
        endDate: response.endDate ,
        comments: response.comments
      };
    } else {
      return {
        success: false,
        error: response,
        id: null,
        companyId: null ,
        company: '',
        projectId: null ,
        devHours: null,
        devAmount: null,
        devRate: null,
        pmHours: null,
        pmAmount: null,
        pmRate: null,
        total: null,
        totalAmount: null,
        username: '',
        pmUsername: '',
        projectName: '',
        startDate: null,
        endDate: null,
        comments: ''
      };
    }
  }
};

export const findAllGroupByYearByProjectId = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      `/api/timeReport/findAllGroupByYearByProjectId/${id}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        id: response.id,
        companyId: response.companyId ,
        company: response.company ,
        projectId: response.projectId ,
        devHours: response.devHours ,
        pmHours: response.pmHours ,
        total: response.total ,
        username: response.username ,
        pmUsername: response.pmUsername ,
        projectName: response.projectName ,
        startDate: response.startDate ,
        endDate: response.endDate ,
        comments: response.comments
      };
    } else {
      return {
        success: false,
        error: response,
        id: null,
        companyId: null ,
        company: '',
        projectId: null ,
        devHours: null,
        pmHours: null,
        total: null,
        username: '',
        pmUsername: '',
        projectName: '',
        startDate: null,
        endDate: null,
        comments: ''
      };
    }
  }
};

export const findAllGroupByYearByCompanyId = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      `/api/timeReport/findAllGroupByYearByCompanyId/${id}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        id: response.id,
        companyId: response.companyId ,
        company: response.company ,
        projectId: response.projectId ,
        devHours: response.devHours ,
        pmHours: response.pmHours ,
        total: response.total ,
        username: response.username ,
        pmUsername: response.pmUsername ,
        projectName: response.projectName ,
        startDate: response.startDate ,
        endDate: response.endDate ,
        comments: response.comments
      };
    } else {
      return {
        success: false,
        error: response,
        id: null,
        companyId: null ,
        company: '',
        projectId: null ,
        devHours: null,
        pmHours: null,
        total: null,
        username: '',
        pmUsername: '',
        projectName: '',
        startDate: null,
        endDate: null,
        comments: ''
      };
    }
  }
};
