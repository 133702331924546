import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Table, TableRow, Grid, makeStyles, Theme, createStyles, Paper, Button, TableHead, 
    TableCell, TableBody, Link, Tabs, Tab, Typography, Box } from "@material-ui/core";
import { useState } from "react";
import { callProjectById, deactivateProject, getAllProjects } from "../../services/entries/callProjectApi";
import ProjectForm from "./ProjectForm";
import { displayLoader, hideLoader } from '../../services/utility/loader';
import TimeEntryTable from "../entries/TimeEntryTable";
import ProjectUserTable from "./ProjectUserTable";
import { phoneRegex } from "../utility/Utils";
import MaterialTable from "material-table";
import { callSprints } from "../../services/utility/callSprints";
import dayjs from "dayjs";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

interface Props {
    setShowEnc?: any;
    showEnc?: boolean;
    userInfo: any;
    importedClasses: any;
    timerId: number;
    setTimerProjectId: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      dense: {
        marginTop: theme.spacing(2),
      },
      menu: {
        width: 200,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      inactive: {
        color: 'red',
        '&:hover': {
            textDecoration: "none",
            color: "#25ca94",
            cursor: "pointer"
        },
      }
  }),
);


export const ProjectIndex: React.FC<Props & RouteComponentProps> = (props) => {

    const {showEnc, userInfo, importedClasses, timerId, setTimerProjectId } = props;
    const [showTable, setShowTable] =  useState(false);
    const classes = useStyles();
    const [projects, setProjects] = useState<any[]>([]);
    const [project, setProject] = useState<any>(undefined);
    const [selectedProject, setSelectProject] = useState<any>(undefined);
    const [selectedTab, setSelectedTab] = useState<any>(0);
    const [projectUpdate, setProjectUpdate] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [timeEntry, setTimeEntry] = useState(undefined);
    const [isUpdate, setIsUpdate] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [sprint, setSprint] = useState<any>(undefined);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleDateChange = (date: Date | null) => {
        if (date) {
            setSelectedDate(date);
            // setYear(dayjs(date).year());
        }
    };

    React.useEffect(() => {
        getSprints();
    }, [selectedDate]);

    const getSprints = async () => {
        const response: any = await callSprints(dayjs(selectedDate).format("YYYYMMDD"));
        if (response.success) {
          let list = response.result;
          if (list) {
            setSprint(list);
            hideLoader();
          }
        }  
      }

    const setTimeEntryState = (timeEntry: any) => {
        setTimeEntry(timeEntry);
        setIsUpdate(false);
    }

    const setTimeEntryStateUpdate = (timeEntry: any) => {
        setTimeEntry(timeEntry);
        setIsUpdate(true);
    }

    const setSelectedProjectTask = (id: number, companyName: string) => {
        setSelectProject(id);
        setShowTable(true);
        setCompanyName(companyName);
        setTimerProjectId(id);
    }

    const projectSearch = (id: number, role: number) => {
        findProjects(id, role);
    }

    const findProjects  = async (id: number, role: number) => {
        const response: any = await getAllProjects(
            id, userInfo.isAdmin === true ? 1 : role, userInfo.userId,
            sprint.year, sprint.sprintNumber
        );
        if (response && response.success) {
            setProjects(response.results);
            hideLoader();
        }        
    };

    const goBackToTable = () => {
        setShowTable(true);
        setProject(undefined);
        setSelectProject(undefined);
        setShowNew(false);
        hideLoader();
        setSelectedTab(0);
        setTimeEntry(undefined);
        setTimerProjectId('0');
        projectSearch(userInfo.userId, userInfo.userType);
    }

    const findById  = async () => {
        const response: any = await callProjectById(
            selectedProject
        );
        if (response.success && response.result) {
            setProjectUpdate(true);
            setProject(response.result);
            setShowTable(false);
            window.scrollTo(0, 0);
            hideLoader();
        }        
    };

    React.useEffect(() => {
        if (selectedProject) {
            displayLoader();
            findById();
        }        
    }, [selectedProject, timerId]);

    React.useEffect(() => {
        if (selectedTab === 1) {
            displayLoader();
            findById();
        }        
    }, [selectedTab]);

    React.useEffect(() => {
        displayLoader();
        if (sprint) {
            projectSearch(userInfo.userId, userInfo.userType)
            setShowTable(true);
        }
        hideLoader();
    }, [userInfo, sprint]);

    React.useEffect(() => {
        hideLoader();
    }, [showEnc]);

    const handleTabsChange = (event: React.ChangeEvent<{}>, newValue: number) =>{
        setSelectedTab(newValue);
    }

    const openNew = () => {
        setShowNew(true);
    }

    const selectTimeEntry = (event: any) => {
        // setTimeEntrySelected(true);
        // setSelectedTimeEntry(event.currentTarget.value);
    }

    const a11yProps = (index: any) =>{
        return {
          id: `full-width-tab-${index}`,
          'aria-controls': `full-width-tabpanel-${index}`,
        };
      }

      interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index: any;
        value: any;
      }
      
      const TabPanel = (props: TabPanelProps) =>{
        const { children, value, index, ...other } = props;
      
        return (
          <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
          >
            <Box p={3}>{children}</Box>
          </Typography>
        );
      }

      const toggleStatus = async (id: number) => {
        await deactivateProject(id);
        projectSearch(userInfo.userId, userInfo.userType);
      }

  return (
      <>        
        {showNew && 
            <Paper>
                <Link onClick={goBackToTable} className={importedClasses.link} style={{float: 'left'}}>
                    &lt;&nbsp;Back
                </Link>
                <br/><br/>
                <Grid container sm>
                    <ProjectForm isUpdate={false} handleClose={goBackToTable} patient={project} updateProject={setSelectProject} userInfo={userInfo} importedClasses={importedClasses}/>
                </Grid>
            </Paper>
        }
        {!showNew && 
        <>
        
        <Grid container>
        {userInfo.userType !== '4' && 
            <Grid item lg={3}>
                <Button onClick={openNew} variant="contained" className={importedClasses.clockTrackButton} style={{float: "left"}}>New Project</Button>
            </Grid>
        }
            {/* <Grid item lg={9}>
                <img src="/images/png/workflow.png" alt="FHG workflow" style={{marginLeft: "150px"}}/>
            </Grid> */}
        </Grid>
        <Grid
        direction="row"
        justify="center"
        alignItems="center"
        >
            {/* <ProjectRegionToggle value={toggleValue} handleToggleButtonChange={handleToggleButtonChange}/> */}
            <Grid item lg={12}>
                <Paper className={classes.paper}>
                {showTable && project === undefined &&
                <>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            autoOk={true}
                            margin="normal"
                            id="date-picker-dialog"
                            label="Sprint"
                            format="MM/dd/yyyy"
                            value={selectedDate}
                            onChange={handleDateChange}
                            style={{marginLeft: '20px'}}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    >
                    <Grid item xl={12} lg={12} md={12} alignItems="stretch">
                    <Grid item>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        >
                                    <Grid item xl={12} lg={12} md={12} sm={10} xs={10} alignItems="stretch">
                                    {userInfo.isPm === true || userInfo.isAdmin === true?
                                     <p style={{color:'#271bc0', fontWeight:'bold'}}>
                                        {/* Submitted time, but not yet approved time is highlighted in blue. */}
                                        </p>
                                     :
                                     <p style={{color:'#271bc0', fontWeight:'bold'}}>Entered time, but not yet submitted time is highlighted in blue. Change the Sprint to see if other times are submitted.</p>
                                    }
                            <MaterialTable
                                components={{}}
                                columns={[
                                    {
                                        title: `Project `,
                                        field: 'name',
                                        render: (row: any) => {
                                            return (
                                            <>
                                            {userInfo.isPm === true || userInfo.isAdmin === true?
                                            //time_entered  = approved for PM
                                            <Link onClick={() => {setSelectedProjectTask(row.id, row.companyName)}} className={row.time_entered === false && row.submitted === true ? importedClasses.highlight : importedClasses.link} >
                                                {row.name}
                                            </Link>
                                            :
                                            <Link onClick={() => {setSelectedProjectTask(row.id, row.companyName)}} className={row.time_entered === true && row.submitted === false ? importedClasses.highlight : importedClasses.link} >
                                                {row.name}
                                            </Link>
                                            }
                                            </>
                                        );
                                        },
                                        customFilterAndSearch: (value, rowData) => {
                                            return  rowData.name.toLowerCase().search(value) > -1;
                                        }
                                    },
                                    {
                                    title: 'Customer',
                                    field: 'companyName'
                                    },
                                    {
                                    title: 'Contact',
                                    field: 'contact'
                                    },
                                    {
                                    title: 'Phone',
                                    field: 'phone', 
                                    render: (rowData: any) => {
                                        if (rowData.phone) {
                                            return (<>{rowData.phone.replace(phoneRegex, "($1) $2-$3")}</>);
                                        } 
                                    }
                                    },
                                    {
                                        title: 'Status',
                                        field: 'name',
                                        render: (row: any) => {
                                            return (
                                                <>
                                                {parseInt(userInfo.userType) === 1 ?
                                                <Link onClick={() => {toggleStatus(row.id)}} className={importedClasses.link} >
                                                    {row.active ? 'Deactivate' : <span className={classes.inactive}>Activate</span>}
                                                </Link>
                                                :  <>{row.active ? 'Active' : <span className={classes.inactive}>Inactive</span>}</>
                                                }
                                            </>
                                        );
                                        },
                                        customFilterAndSearch: (value, rowData) => {
                                            return  rowData.name.toLowerCase().search(value) > -1;
                                        }
                                    },
                                ]}
                                options={{
                                    exportButton: true,
                                    search: true,
                                    showTitle: false,
                                    exportFileName: "projects",
                                    rowStyle: {
                                        verticalAlign: "baseline"
                                    },
                                    pageSize: 20,
                                    exportAllData: true,
                                }}
                                data={projects}
                            />
                        </Grid>
                    </Grid>
            </Grid>
                </Grid>
                </Grid>
                </>
                }

                {project && 
                <>
                <div style={{float: "left"}}>
                    {/* <Button onClick={goBackToTable} style={{float: "left"}}>&lt;&nbsp;Back</Button> */}
                    <Link onClick={goBackToTable} className={importedClasses.link} style={{float: 'left'}}>
                        &lt;&nbsp;Back
                    </Link>
                    &nbsp;&nbsp;
                    <Link onClick={() => {return false}} className={importedClasses.linkNone} >
                        Customer: {companyName}, Project: {project?.name}
                    </Link>
                </div>
                <br/>
                <br/>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabsChange}
                    indicatorColor="primary"
                    // textColor="primary"
                    // style={{paddingLeft: "70px"}}
                >
                    {project && <Tab label="Time Entries" {...a11yProps(0)} className={importedClasses.link}/>}
                    <Tab label={"Details"} {...a11yProps(1)} className={importedClasses.link}/>                        
                    {project && userInfo.userType !== '4' && <Tab label="Developers" {...a11yProps(2)} className={importedClasses.link}/> }


                </Tabs>
                <TabPanel value={selectedTab} index={1}>
                    {selectedTab === 1 &&
                        <ProjectForm isUpdate={projectUpdate} handleClose={goBackToTable} patient={project} updateProject={setSelectProject} userInfo={userInfo} importedClasses={importedClasses}/>
                    }
                </TabPanel>
                <TabPanel value={selectedTab} index={0}>
                    {selectedTab === 0 &&
                    <TimeEntryTable setTimeEntryState={setTimeEntryState} setTimeEntryStateUpdate={setTimeEntryStateUpdate} projectId={selectedProject} showEdit={true} timeEntry={timeEntry}
                        isUpdate={isUpdate} setIsUpdate={setIsUpdate} userInfo={userInfo} importedClasses={importedClasses}/>
                    }   
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                    {selectedTab === 2 &&
                        <ProjectUserTable handleClick={selectTimeEntry} userInfo={userInfo} projectId={selectedProject} user={userInfo.userId} importedClasses={importedClasses}/>
                        
                    }   
                </TabPanel>
                
                </>
                
                }
                </Paper>
            </Grid>
            
        </Grid>
    </>
    }
       
    </>
  );
};

export default ProjectIndex;