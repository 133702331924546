import Axios from "axios";
import { TimeEntryModelType } from "../../model/entries/TimeEntryModelType";
import { TimeEntryReportModelType } from "../../model/entries/TimeEntryReportModelType";

export const getAllTimeReports = async (
) => {
  try {
    
    
    const { data }: { data: TimeEntryReportModelType[] } = await Axios.post(
      `/api/timeReport/all`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        id: response.id,
        companyId: response.companyId ,
        company: response.company ,
        projectId: response.projectId ,
        devHours: response.devHours ,
        devAmount: response.devAmount,
        devRate: response.devRate,
        pmHours: response.pmHours ,
        pmAmount: response.pmAmount,
        pmRate: response.pmRate,
        total: response.total ,
        totalAmount: response.totalAmount,
        username: response.username ,
        pmUsername: response.pmUsername ,
        projectName: response.projectName ,
        startDate: response.startDate ,
        endDate: response.endDate ,
        comments: response.comments
      };
    } else {
      return {
        success: false,
        error: response,
        id: null,
        companyId: null ,
        company: '',
        projectId: null ,
        devHours: null,
        devAmount: null,
        devRate: null,
        pmHours: null,
        pmAmount: null,
        pmRate: null,
        total: null,
        totalAmount: null,
        username: '',
        pmUsername: '',
        projectName: '',
        startDate: null,
        endDate: null,
        comments: ''
      };
    }
  }
};

export const getAllTimeEntriesReports = async (
  ) => {
    try {
      
      
      const { data }: { data: TimeEntryReportModelType[] } = await Axios.post(
        `/api/timeReport/allEntries`,
        {
  
        },
        
      );
  
      return { ...data, success: true };
    } catch (error) {
      const response: any = error;
      if (response) {
        return {
          success: false,
          error: response,
          id: response.id,
          companyId: response.companyId ,
          company: response.company ,
          projectId: response.projectId ,
          devHours: response.devHours ,
          devAmount: response.devAmount,
          devRate: response.devRate,
          pmHours: response.pmHours ,
          pmAmount: response.pmAmount,
          pmRate: response.pmRate,
          total: response.total ,
          totalAmount: response.totalAmount,
          username: response.username ,
          pmUsername: response.pmUsername ,
          projectName: response.projectName ,
          startDate: response.startDate ,
          endDate: response.endDate ,
          comments: response.comments
        };
      } else {
        return {
          success: false,
          error: response,
          id: null,
          companyId: null ,
          company: '',
          projectId: null ,
          devHours: null,
          devAmount: null,
          devRate: null,
          pmHours: null,
          pmAmount: null,
          pmRate: null,
          total: null,
          totalAmount: null,
          username: '',
          pmUsername: '',
          projectName: '',
          startDate: null,
          endDate: null,
          comments: ''
        };
      }
    }
  };

export const callTimeReportByProjectId = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      `/api/timeReport/timeReportByProjectId/${id}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        id: response.id,
        companyId: response.companyId ,
        company: response.company ,
        projectId: response.projectId ,
        devHours: response.devHours ,
        pmHours: response.pmHours ,
        total: response.total ,
        username: response.username ,
        pmUsername: response.pmUsername ,
        projectName: response.projectName ,
        startDate: response.startDate ,
        endDate: response.endDate ,
        comments: response.comments,
        contact: response.contact,
      };
    } else {
      return {
        success: false,
        error: response,
        id: null,
        companyId: null ,
        company: '',
        projectId: null ,
        devHours: null,
        pmHours: null,
        total: null,
        username: '',
        pmUsername: '',
        projectName: '',
        startDate: null,
        endDate: null,
        comments: '',
        contact: '',
      };
    }
  }
};

export const callTimeReportByCompanyId = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      `/api/timeReport/timeReportByCompanyId/${id}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        id: response.id,
        companyId: response.companyId ,
        company: response.company ,
        projectId: response.projectId ,
        devHours: response.devHours ,
        pmHours: response.pmHours ,
        total: response.total ,
        username: response.username ,
        pmUsername: response.pmUsername ,
        projectName: response.projectName ,
        startDate: response.startDate ,
        endDate: response.endDate ,
        comments: response.comments,
        contact: response.contact,
      };
    } else {
      return {
        success: false,
        error: response,
        id: null,
        companyId: null ,
        company: '',
        projectId: null ,
        devHours: null,
        pmHours: null,
        total: null,
        username: '',
        pmUsername: '',
        projectName: '',
        startDate: null,
        endDate: null,
        comments: '',
        contact: '',
      };
    }
  }
};

export const callTimeReportBySprint = async (
  startDate : string,
  endDate: string
) => {
  try {
    
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      `/api/timeReport/timeReportBySprint/${startDate}/${endDate}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        id: response.id,
        companyId: response.companyId ,
        company: response.company ,
        projectId: response.projectId ,
        devHours: response.devHours ,
        pmHours: response.pmHours ,
        total: response.total ,
        username: response.username ,
        pmUsername: response.pmUsername ,
        projectName: response.projectName ,
        startDate: response.startDate ,
        endDate: response.endDate ,
        comments: response.comments,
        contact: response.contact,
      };
    } else {
      return {
        success: false,
        error: response,
        id: null,
        companyId: null ,
        company: '',
        projectId: null ,
        devHours: null,
        pmHours: null,
        total: null,
        username: '',
        pmUsername: '',
        projectName: '',
        startDate: null,
        endDate: null,
        comments: '',
        contact: '',
      };
    }
  }
};

export const approveTimeEntry = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      `/api/timeReport/${id}/delete`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response
      };
    } else {
      return {
        success: false,
        error: response
      };
    }
  }
};
