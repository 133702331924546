import Axios from "axios";
import { ProjectsModelType } from "../../model/project/ProjectsModelType";
import { ProjectFullModelType } from "../../model/project/ProjectFullModelType";
import { ProjectNameViewModelType } from "../../model/project/ProjectNameViewModelType";
import { validJwt } from "../../dashboard/utility/Utils";

export const callProjectByDob = async (
  dob : string,
  ownerId: number
) => {
  try {
    
    
    const { data }: { data: ProjectsModelType[] } = await Axios.post(`/api/project/${ownerId}/dob/${dob}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        firstNameRead: response.firstNameRead,
        lastNameRead: response.lastNameRead,
        id: response.id,
        dob: response.dob,
        physicianId: response.physicianId
      };
    } else {
      return {
        success: false,
        firstNameRead: "",
        lastNameRead: "",
        id: "",
        dob: "",
        physicianId: ""
      };
    }
  }
};

export const callProjectById = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: ProjectsModelType[] } = await Axios.post(`/api/project/${id}/full`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        firstNameRead: response.firstNameRead,
        lastNameRead: response.lastNameRead,
        id: response.id,
        dob: response.dob,
        physicianId: response.physicianId,
        error: response
      };
    } else {
      return {
        success: false,
        firstNameRead: "",
        lastNameRead: "",
        id: "",
        dob: "",
        physicianId: "",
        error: "Call 'callProjectById' Failed"
      };
    }
  }
};

export const callProjectByName = async (
  name : string
) => {
  try {
    
    
    const { data }: { data: ProjectNameViewModelType[] } = await Axios.post(`/api/project/name/${name}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        projectName: response.projectName,
        doctor: response.doctor,
        id: response.id,
        physicianId: response.phsycianId,
        error: response
      };
    } else {
      return {
        success: false,
        projectName: "",
        doctor: "",
        id: "",
        dob: "",
        physicianId: "",
        error: "Call 'callProjectByName' Failed"
      };
    }
  }
};


export const updateOrCreateProject = async (
  project : ProjectFullModelType,
  isUpdate: boolean
) => {
  try {

    const url = isUpdate ? `/api/project/${project.id}/full/update` : `/api/project/new/full`;
    
    const { data }: { data: ProjectFullModelType[] } = await Axios.post(
      url,
      {
        project: project
      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        firstNameRead: response.firstNameRead,
        lastNameRead: response.lastNameRead,
        id: response.id,
        dob: response.dob,
        physicianId: response.physicianId,
        error: response
      };
    } else {
      return {
        success: false,
        firstNameRead: "",
        lastNameRead: "",
        id: "",
        dob: "",
        physicianId: "",
        error: "Call 'updateOrCreateProject' Failed"
      };
    }
  }
};

export const getAllProjects = async (
  id? : number,
  role?: number,
  userId?: number,
  sprintYear?: number,
  sprintNumber?: number,
) => {
  if (role) {
    try {

      let url = `/api/project/all`;

      if (role !== undefined 
        && userId !== undefined) {
        url = `/api/project/role/${role}/${userId}/${sprintNumber}/${sprintYear}`;
      } else if (role !== undefined) {
        url = `/api/project/role/${role}/${id}`;
      }
    
      const { data }: { data: ProjectsModelType[] } = await Axios.post(url,
        {
  
        },
      );
  
      return { ...data, success: true };
    } catch (error) {
      const response: any = error;
      validJwt(error);
      if (response) {
        return {
          success: false,
          results: response.results
        };
      } else {
        return {
          success: false,
          results: []
        };
      }
    }
  } else if (id) {
    try {
    
      const { data }: { data: ProjectsModelType[] } = await Axios.post(
        `/api/project/${id}`,
        {
  
        },
      );
  
      return { ...data, success: true };
    } catch (error) {
      const response: any = error;
      if (response) {
        return {
          success: false,
          results: response.results
        };
      } else {
        return {
          success: false,
          results: []
        };
      }
    }
  }
};

export const allProjects = async (
  id? : number,
  role?: number
) => {
    try {
    
      const { data }: { data: ProjectsModelType[] } = await Axios.post(
        `/api/project/all`,
        {
  
        },
      );
  
      return { ...data, success: true };
    } catch (error) {
      const response: any = error;
      if (response) {
        return {
          success: false,
          results: response.results
        };
      } else {
        return {
          success: false,
          results: []
        };
      }
    }
};

export const deactivateProject = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: ProjectsModelType[] } = await Axios.post(
      `/api/project/${id}/toggleStatus`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response
      };
    } else {
      return {
        success: false,
        error: response
      };
    }
  }
};