import * as React from "react";
import { Field, Form, Formik } from "formik";
import { Grid, makeStyles, Theme, MenuItem, Button} from "@material-ui/core";
import { ProjectFullModelType } from "../../model/project/ProjectFullModelType";
import { useState } from "react";
import { updateOrCreateProject } from "../../services/entries/callProjectApi";
import { TextField } from 'formik-material-ui';
import { callCompanies } from "../../services/utility/callCompanies";
import DropDown from "../common/DropDown";
import { callProjectManagers } from "../../services/utility/callProjectManagers";
import { callSalesPeople } from "../../services/utility/callSalesPeople";
import { keyUpPhone, keyDownPhone, phoneRegex } from '../utility/Utils';

interface Props {
    patient?: any;
    isUpdate: boolean;
    handleClose: any;
    updateProject: any;
    userInfo : any;
    importedClasses: any;
}

const useStyle = makeStyles((theme: Theme) => {
    return {
      formControl: {
        minWidth: 120,
        display: "flex",
        flexDirection: "column",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        width: "85%"
      },
      autocomplete: {
        // marginBottom: 25
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 300
      },
      checkbox: {
        margin: theme.spacing(2)
      }
    };
  });



const ProjectForm: React.FC<Props> = props => {
    const { patient, isUpdate, handleClose, updateProject, userInfo, importedClasses } = props;
    const [error, setError] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);
    const [companies, setCompanies] = useState<any[]>([]);
    const [projectManagers, setProjectManagers] = useState<any[]>([]);
    const [salesPeople, setSalesPeople] = useState<any[]>([]);
    const classes = useStyle();

    const initialValues = isUpdate ? patient : {
        name: '',
        id: null,
        companyId: null,
        contact: '',
        phone: '',
        email: '',
        active: true,
        salesPerson: null,
        projMgr: null,
        startDate: null,
        endDate: null,
        success: false
      };
      
    const getCompanies = async () => {
        const response: any = await callCompanies();
        if (response.success && response.results) {
            setCompanies(response.results);
        }  
    }

    const getProjectManagers = async () => {
      const response: any = await callProjectManagers();
      if (response.success && response.results) {
          setProjectManagers(response.results);
      }  
    }

    const getSalesPeople = async () => {
      const response: any = await callSalesPeople();
      if (response.success && response.results) {
          setSalesPeople(response.results);
      }  
    }


    React.useEffect(() => {
        getCompanies();
        getProjectManagers();
        getSalesPeople();
    }, []);

    const updateProjectResult = async (updatedProject : ProjectFullModelType) => {
        updateProject(updatedProject.id);
    }

    return <>
    
    <Formik
        isInitialValid={isUpdate ? true : false}
        initialValues={initialValues}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            const result: any = await updateOrCreateProject(values, isUpdate);
            if (result.success) {
              setError(result.error);
              setSubmitting(false);
              if (!isUpdate) {
                handleClose();
                updateProjectResult(result.result);
              }
            } else {
              setSubmitting(false);
              setError("");
              const fileInput: any = document.getElementById(
                "admin-add-customer-logo"
              );
              resetForm(initialValues);
            //   handleClose();
            }
          }}
    >
        <Form className={classes.formControl}>
        <Grid container spacing={3}
            direction="row"
            justify="space-evenly"
            alignItems="baseline">
            <Grid container spacing={1}>
                <Grid item>  
                    <Field
                    name="name"
                    placeholder={"Project Name"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Project Name"
                    disabled={userInfo.userType === '4'}
                    />
                </Grid>
                <Grid item>  
                    <Field
                    name="contact"
                    placeholder={"Project Contact"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Project Contact"
                    disabled={userInfo.userType === '4'}
                    />
                </Grid>
                <Grid item>  
                    <Field
                    name="managed"
                    margin="normal"
                    variant="outlined"
                    type="checkbox"
                    className={classes.checkbox}
                    disabled={userInfo.userType === '4'}
                    />
                    <label htmlFor='managed'>Add 10% Project Manager Time</label>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item> 
                    <Field
                    name="phone"
                    placeholder={"Phone"}
                    isParent={true}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Phone"
                    onKeyDown={keyDownPhone}
                    onKeyUp={keyUpPhone}
                    disabled={userInfo.userType === '4'}
                    render={({ field }: any) => (
                      <Field
                        className={classes.autocomplete}
                        component={TextField}
                        name="phone"
                        placeholder={"Phone"}
                        value={field.value ? field.value.replace(phoneRegex, "($1) $2-$3") : ''}
                        margin="normal"
                        variant="outlined"
                        label="Phone"
                        onKeyDown={keyDownPhone}
                        onKeyUp={keyUpPhone}
                        disabled={userInfo.userType === '4'}
                      />
                    )}
                  />
                </Grid>
                <Grid item> 
                    <Field
                    name="email"
                    placeholder={"Email"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Email"
                    type="email"
                    disabled={userInfo.userType === '4'}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item> 
                    <Field
                    disabled={userInfo.userType === '4'}
                    type="text"
                    name="companyId"
                    select
                    className={classes.autocomplete}
                    component={TextField}
                    helperText=""
                    margin="normal"
                    variant="outlined"
                    label="Customer"
                    InputLabelProps={{
                        shrink: true,
                      }}
                    required={true}
                    >
                    {companies.map(option => (
                        <MenuItem key={option.name} value={option.id}>
                        {option.name}
                        </MenuItem>
                    ))}
                    </Field>
                </Grid>
                <Grid item> 
                  <DropDown data={projectManagers} label={"Project Manager"} name={"projMgr"} classes={classes} userInfo={userInfo} required={true}/>
                </Grid>
                <Grid item> 
                  <DropDown data={salesPeople} label={"Sales Person"} name={"salesPerson"} classes={classes} userInfo={userInfo}/>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item sm={6} lg={6}>  
                      <Field
                      name="notes"
                      placeholder={"Notes"}
                      isParent={false}
                      className={classes.autocomplete}
                      component={TextField}
                      margin="normal"
                      variant="outlined"
                      label="Notes"
                      multiline
                      rowsMax="4"
                      rows={4}
                      style={{width: "91%"}}
                      />
              </Grid>
            </Grid>
        </Grid>
        {userInfo.userType !== '4' && 
        <Grid container sm>
            <Grid item style={{marginTop: "20px"}}>
                <Button type="submit" variant="contained" className={importedClasses.clockTrackButton} style={{float: "left", width: "92px"}}>Save</Button>
            </Grid>
        </Grid>
        }
        </Form>

    </Formik>
  
    </>
};

export default ProjectForm;
