
import Axios from "axios";

const LOGOUT_ENDPOINT: string = "api/v1/logout";

export const callLogout = async () => {
  try {
    await Axios.get(LOGOUT_ENDPOINT);
    localStorage.clear();
    sessionStorage.clear();
    return { success: true };
  } catch (error) {
    console.error(error);
    return { success: false };
  }
};