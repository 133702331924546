import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Grid, makeStyles, Theme, createStyles, Paper, Button, Link } from "@material-ui/core";
import { useState } from "react";
import { callVacationById, deleteVacation, getAllVacations } from "../../services/vacations/callVacation";
import VacationForm from "../vacation/VacationForm";
import { displayLoader, hideLoader } from '../../services/utility/loader';
import MaterialTable from "material-table";
import dayjs from "dayjs";

interface Props {
    setShowEnc?: any;
    showEnc?: boolean;
    userInfo: any;
    importedClasses: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      dense: {
        marginTop: theme.spacing(2),
      },
      menu: {
        width: 200,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      inactive: {
        color: 'red',
        '&:hover': {
            textDecoration: "none",
            color: "#25ca94",
            cursor: "pointer"
        },
      }
  }),
);


export const VacationIndex: React.FC<Props & RouteComponentProps> = (props) => {

    const {showEnc, userInfo, importedClasses } = props;
    const [showTable, setShowTable] =  useState(false);
    const classes = useStyles();
    const [vacations, setVacations] = useState<any[]>([]);
    const [vacation, setVacation] = useState<any>(undefined);
    const [selectedVacation, setSelectVacation] = useState<any>(undefined);
    const [vacationUpdate, setVacationUpdate] = useState(false);
    const [showNew, setShowNew] = useState(false);

    const setSelectedVacationTask = (id: number, vacationName: string) => {
        setSelectVacation(id);
        setShowTable(true);
    }

    const vacationSearch = () => {
        findVacations();
    }

    const findVacations  = async () => {
        const response: any = await getAllVacations();
        if (response && response.success) {
            setVacations(response.result);
            hideLoader();
        }        
    };

    const goBackToTable = () => {
        setShowTable(true);
        setVacation(undefined);
        setSelectVacation(undefined);
        setShowNew(false);
        hideLoader();
        findVacations();
    }

    const findById  = async () => {
        const response: any = await callVacationById(
            selectedVacation
        );
        if (response.success && response.result) {
            setVacationUpdate(true);
            setVacation(response.result);
            setShowTable(false);
            window.scrollTo(0, 0);
            hideLoader();
        }        
    };

    React.useEffect(() => {
        if (selectedVacation) {
            displayLoader();
            findById();
        }        
    }, [selectedVacation]);

    React.useEffect(() => {
        displayLoader();
        vacationSearch()
        setShowTable(true);
        hideLoader();
    }, [userInfo]);

    React.useEffect(() => {
        hideLoader();
    }, [showEnc]);

    const openNew = () => {
        setShowNew(true);
    }

    const deleteVacay = async(id: number) => {
        await deleteVacation(id);
        findVacations();
    }

  return (
      <>        
        {showNew && 
            <Paper>
                <Link onClick={goBackToTable} className={importedClasses.link} style={{float: 'left'}}>
                    &lt;&nbsp;Back
                </Link>
                <br/><br/>
                <Grid container sm>
                    <VacationForm isUpdate={false} handleClose={goBackToTable} vacation={vacation} updateVacation={setSelectVacation} userInfo={userInfo} importedClasses={importedClasses} refresh={getAllVacations}/>
                </Grid>
            </Paper>
        }
        {

        }
        {!showNew && 
        <>
        
        <Grid container>
            <Grid item lg={3}>
                <Button onClick={openNew} variant="contained" className={importedClasses.clockTrackButton} style={{float: "left"}}>New Vacation</Button>
            </Grid>
        </Grid>        
        <Grid
        direction="row"
        justify="center"
        alignItems="center"
        >
            {/* <VacationRegionToggle value={toggleValue} handleToggleButtonChange={handleToggleButtonChange}/> */}
            <Grid item lg={12}>
                <Paper className={classes.paper}>
                {showTable && vacation === undefined &&
                <>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    >
                    <Grid item xl={12} lg={12} md={12} alignItems="stretch">
                    <Grid item>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        >
                                    <Grid item xl={12} lg={12} md={12} sm={10} xs={10} alignItems="stretch">
                            <MaterialTable
                                components={{}}
                                columns={[
                                    {
                                        title: 'Name',
                                        field: 'name',
                                        render: (row: any) => {
                                            return (
                                            <>
                                            <Link onClick={() => {setSelectedVacationTask(row.id, row.vacationName)}} className={importedClasses.link} >
                                                {row.name}
                                            </Link>
                                            </>
                                        );
                                        },
                                        customFilterAndSearch: (value, rowData) => {
                                            return  rowData.name.toLowerCase().search(value) > -1;
                                        }
                                    },
                                    {
                                    title: 'Reason',
                                    field: 'reason'
                                    },
                                    {
                                    title: 'Contingency',
                                    field: 'contingency'
                                    },
                                    {
                                    title: 'Start Date',
                                    field: 'stateDateTime', 
                                    render: (rowData: any) => {
                                        if (rowData.startDateTime) {
                                            return (<>{dayjs(rowData.startDateTime).format("MM/DD/YYYY")}</>);
                                        } 
                                    }
                                    },
                                    {
                                    title: 'End Date',
                                    field: 'endDateTime', 
                                    render: (rowData: any) => {
                                        if (rowData.endDateTime) {
                                            return (<>{dayjs(rowData.endDateTime).format("MM/DD/YYYY")}</>);
                                        } 
                                    }
                                    },
                                    {
                                        title: '',
                                        field: 'id',
                                        render: (row: any) => {
                                            return (
                                                <>
                                                {parseInt(userInfo.userId) === row.user ?
                                                <Link onClick={() => {deleteVacay(row.id)}} className={importedClasses.link} >
                                                    {row.active ? '' : <span className={classes.inactive}>Delete</span>}
                                                </Link>
                                                :  ''
                                                }
                                            </>
                                        );
                                        },
                                        customFilterAndSearch: (value, rowData) => {
                                            return  rowData.name.toLowerCase().search(value) > -1;
                                        }
                                    }
                                ]}
                                options={{
                                    exportButton: true,
                                    search: true,
                                    showTitle: false,
                                    exportFileName: "vacations",
                                    rowStyle: {
                                        verticalAlign: "baseline"
                                    },
                                    pageSize: 20,
                                    exportAllData: true,
                                }}
                                data={vacations}
                            />
                        </Grid>
                    </Grid>
            </Grid>
                </Grid>
                </Grid>
                </>
                }

                {vacation && 
                <>
                <div style={{float: "left"}}>
                    {/* <Button onClick={goBackToTable} style={{float: "left"}}>&lt;&nbsp;Back</Button> */}
                    <Link onClick={goBackToTable} className={importedClasses.link} style={{float: 'left'}}>
                        &lt;&nbsp;Back
                    </Link>
                    &nbsp;&nbsp;
                    <Link onClick={() => {return false}} className={importedClasses.linkNone} >
                    Reason : {vacation?.reason} | FHG resource: {vacation?.name}
                    </Link>
                </div>
                <VacationForm isUpdate={vacationUpdate} handleClose={goBackToTable} vacation={vacation} 
                    refresh={findVacations}
                    updateVacation={setSelectVacation} userInfo={userInfo} importedClasses={importedClasses}/>
                </>
                
                }
                </Paper>
            </Grid>
            
        </Grid>
    </>
    }
       
    </>
  );
};

export default VacationIndex;