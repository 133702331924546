import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Table, TableRow, Grid, makeStyles, Theme, createStyles, Paper, TextField, Button, TableHead, TableCell, TableBody, Link, Tabs, Tab, Typography, Box } from "@material-ui/core";
import { useState } from "react";
import { callUserById, deactivateUser, getAllUsers } from "../../services/users/callUsers";
import UserForm from "./UserForm";
import { displayLoader, hideLoader } from '../../services/utility/loader';
import TimeEntryTable from "../entries/TimeEntryTable";
import MaterialTable from "material-table";
import dayjs from "dayjs";
import { phoneRegex } from "../utility/Utils";

interface Props {
    setShowEnc?: any;
    showEnc?: boolean;
    userInfo: any;
    importedClasses: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      dense: {
        marginTop: theme.spacing(2),
      },
      menu: {
        width: 200,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      fakelink: {
        cursor: "pointer",
        color: "blue",
        textDecoration: "underline"
      },
        hidden: {
        display: "none",
        visibility: "hidden"
        },
        inactive: {
            color: 'red',
            '&:hover': {
                textDecoration: "none",
                color: "#25ca94",
                cursor: "pointer"
            },
        }
  }),
);


export const UserIndex: React.FC<Props & RouteComponentProps> = (props) => {

    const {showEnc, setShowEnc, userInfo, importedClasses} = props;
    const [physician, setPhysician] = useState(3);
    const [showTable, setShowTable] =  useState(false);
    const classes = useStyles();
    const [users, setUsers] = useState<any[]>([]);
    const [user, setUser] = useState<any>(undefined);
    const [selectedUser, setSelectUser] = useState<any>(undefined);
    const [selectedTab, setSelectedTab] = useState<any>(0);
    const [selectedTimeEntry, setSelectedTimeEntry] = useState<any>(undefined);
    const [encounterSelected, setTimeEntrySelected] = useState(false);
    const [userUpdate, setUserUpdate] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const setUserState = (user: any) => {
        setUser(user);
        setUserUpdate(true);
    }

    const setSelectedUserTask = (id: number) => {
        setSelectUser(id);
        setShowTable(true);
    }

    const setClearUserForm = () => {
        setUsers([]);
        setShowEnc(false);
        setSelectUser(undefined);
        hideLoader();
    }
    
    const handleChange = (event: any)  => {
        setPhysician(event.target.value);
    }

    const userSearch = () => {
        findUsers();
    }

    const findUsers  = async () => {
        const response: any = await getAllUsers();
        if (response && response.success) {
            setUsers(response.results);
            hideLoader();
        }        
    };

    const goBackToTable = () => {
        setShowTable(true);
        setUser(undefined);
        setSelectUser(undefined);
        setShowNew(false);
        hideLoader();
        setSelectedTab(0);
    }

    const findById  = async () => {
        const response: any = await callUserById(
            selectedUser
        );
        if (response.success && response.result) {
            setUserUpdate(true);
            setUser(response.result);
            setShowTable(false);
            window.scrollTo(0, 0);
            hideLoader();
        }        
    };

    const goToUser = (event: any) => {
        setSelectUser(event.target.value);
    }

    React.useEffect(() => {
        if (selectedUser) {
            displayLoader();
            findById();
        }        
    }, [selectedUser]);

    React.useEffect(() => {
        displayLoader();
        // userSearch(physician);   
        userSearch();
        setShowTable(true);
        hideLoader();
    }, [userInfo]);

    React.useEffect(() => {
        hideLoader();
    }, [showEnc]);

    const handleTabsChange = (event: React.ChangeEvent<{}>, newValue: number) =>{
        setSelectedTab(newValue);
    }

    const openNew = () => {
        setShowNew(true);
    }

    const selectTimeEntry = (event: any) => {
        setTimeEntrySelected(true);
        setSelectedTimeEntry(event.currentTarget.value);
    }

    const a11yProps = (index: any) =>{
        return {
          id: `full-width-tab-${index}`,
          'aria-controls': `full-width-tabpanel-${index}`,
        };
      }

      interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index: any;
        value: any;
      }
      
      const TabPanel = (props: TabPanelProps) =>{
        const { children, value, index, ...other } = props;
      
        return (
          <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
          >
            <Box p={3}>{children}</Box>
          </Typography>
        );
      }

      const updateInsurance = (insurance: any) => {
          user.insurance = insurance;
          setUser(user);
      }

      const toggleStatus = async (id: number) => {
        await deactivateUser(id);
        findUsers();
      }

  return (
      <>
            {showNew && 
                <Paper>
                    <Link onClick={goBackToTable} className={importedClasses.link} style={{float: 'left'}}>
                        &lt;&nbsp;Back
                    </Link>
                    <br/><br/>
                    <Grid container sm>
                        <UserForm isUpdate={false} handleClose={goBackToTable} user={user} updateUser={setSelectUser} refresh={findUsers} 
                        importedClasses={importedClasses} userInfo={userInfo} setUserAfterUpdate={setUser}/>
                    </Grid>
                </Paper>
            }
            {!showNew && 
            <>
            <Button onClick={openNew} variant="contained" className={importedClasses.clockTrackButton} style={{float: "left"}}>New User</Button>
            <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            >
                {/* <UserRegionToggle value={toggleValue} handleToggleButtonChange={handleToggleButtonChange}/> */}
                <Grid item lg={12}>
                    <Paper className={classes.paper}>
                    {showTable && user === undefined &&
                    <>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        >
                        <Grid item xl={12} lg={12} md={12} sm={6} xs={6} alignItems="stretch">
                            <MaterialTable
                                components={{}}
                                columns={[
                                    {
                                        title: 'Name',
                                        field: '',
                                        render: (rowData: any) => {
                                            return (
                                            <>
                                            <Link onClick={() => {setUserState(rowData)}} className={importedClasses.link} >
                                                {`${rowData.lastName},  ${rowData.firstName}`}
                                            </Link>
                                            </>
                                        );
                                        }
                                    },
                                    {
                                    title: 'Company',
                                    field: 'title'
                                    },
                                    {
                                    title: 'Email',
                                    field: 'email'
                                    },
                                    {
                                    title: 'Cell Phone',
                                    field: 'cellphone', 
                                    render: (rowData: any) => {
                                        if (rowData.cellphone) {
                                            return (<>{rowData.cellphone.replace(phoneRegex, "($1) $2-$3")}</>);
                                        } 
                                    }
                                    },
                                    {
                                        title: 'Status',
                                        field: 'name',
                                        render: (row: any) => {
                                            return (
                                                <>
                                                {parseInt(userInfo.userType) === 1 ?
                                                <Link onClick={() => {toggleStatus(row.id)}} className={importedClasses.link} >
                                                    {row.active ? 'Deactivate' : <span className={classes.inactive}>Activate</span>}
                                                </Link>
                                                :  <>{row.active ? 'Active' : <span className={classes.inactive}>Inactive</span> }</>
                                                }
                                            </>
                                        );
                                        },
                                        customFilterAndSearch: (value, rowData) => {
                                            return  rowData.name ? rowData.name.toLowerCase().search(value) > -1 : false;
                                        }
                                    },
                                ]}
                                options={{
                                    exportButton: true,
                                    search: true,
                                    showTitle: false,
                                    exportFileName: "time_entries",
                                    rowStyle: {
                                        verticalAlign: "baseline"
                                    },
                                    pageSize: 20,
                                    exportAllData: true,
                                }}
                                data={users}
                            />
                        </Grid>
                    </Grid>
                    </>
                    }

                    {user && 
                    <>
                        <Link onClick={goBackToTable} className={importedClasses.link} style={{float: 'left'}}>
                            &lt;&nbsp;Back
                        </Link>
                        <br/><br/>
                        <UserForm isUpdate={userUpdate} handleClose={goBackToTable} user={user} updateUser={setSelectUser} refresh={findUsers} 
                        importedClasses={importedClasses} userInfo={userInfo} setUserAfterUpdate={setUser}/>
                    </>
                    
                    }
                    </Paper>
                </Grid>
                
            </Grid>
            </>
            }        
    </>
  );
};

export default UserIndex;