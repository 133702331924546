import Axios from "axios";
import { ApprovedTimeModel } from "../../model/entries/ApprovedTimeModel";

export const callApprovedTimeByProjectIdAndSprintAndYear = async (
  projectId : number,
  sprintId: number,
  year: number
) => {
  try {
    
    
    const { data }: { data: ApprovedTimeModel[] } = await Axios.post(
      `/api/approvedTime/callApprovedTimeByProjectIdAndSprintAndYear/${projectId}/${sprintId}/${year}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        id: response.id,
        approved: response.approved,
        submitted: response.submitted,
        name: response.name,
        sprint: response.sprint,
        year: response.year,
        projectCnt: response.projectCnt,
        userCnt: response.userCnt,
        projectId: response.projectId
      };
    } else {
      return {
        success: false,
        error: response,
        id: null,
        approved: false,
        submitted: false,
        name: '',
        sprint: null,
        year: null,
        projectCnt: null,
        userCnt: null,
        projectId: null
      };
    }
  }
};
