import * as React from "react";
import {
  Button,
  Theme,
  Grid,
  OutlinedInput
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useState } from "react";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { changePassword } from "../services/utility/callChangePassword";


interface Props {
  importedClasses?: any;
  userInfo?: any;
  navToHome?: boolean;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
  
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '300px',
      },
      '& .MuiButtonBase-root': {
        margin: theme.spacing(2),
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        margin: theme.spacing(1),
        minWidth: 300,
      },
      dense: {
        marginTop: theme.spacing(2),
      },
      menu: {
        width: 200,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      cardStyle: {
        border: 'gray solid thin',
        marginTop: theme.spacing(10),
        width: 374
      },
      clockTrackButton: {
          margin: theme.spacing(1),
          color: "#333333",
          '&:hover': {
              textDecoration: "none",
              color: "#25ca94"
          },
          fontWeight: "bold"
      }
  }),
);

/**
 * Login component.
 */
const ChangePassword: React.FC<Props> = (props) => {
  const { userInfo, navToHome } = props;
  const [password, setPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState("");
  const [message, setMessage] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordMatch, setShowPasswordMatch] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const classes = useStyles();

  const handleChangePw = (event: any) => {
    let value = event.target.value.trim() || '';
    if (value === passwordMatch && value !== '') {
      setMessage('');
      setIsDisabled(false);
    } else {
      setMessage('Passwords do not match');
      setIsDisabled(true);
    }
    setPassword(value);
  }

  const handleChangePwMatch = (event: any) => {
    let value = event.target.value.trim() || '';
    if (value === password && value !== '') {
      setMessage('');
      setIsDisabled(false);
    } else {
      setMessage('Passwords do not match');
      setIsDisabled(true);
    }
    setPasswordMatch(value);
  }

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handleShowPasswordMatchClick = () => {
    setShowPasswordMatch(!showPasswordMatch);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleMouseDownPasswordMatch = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const changePw = async () => {
    userInfo.password = password;
    userInfo.userId = userInfo.id | userInfo.userId;
    const response = await changePassword(userInfo);

    if (response && response.success) {
      setSuccessMsg("Password changed");
      setPassword('');
      setPasswordMatch('');
      setIsDisabled(true);

      if (navToHome) {
        window.location.href = "/";
      }
    }
  };
  
  return <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start">
        <div style={{color: 'green', margin: "15px"}}>{successMsg}</div>
           </Grid>
          <Grid item>
          <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handleChangePw}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPasswordClick}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                className={classes.textField}
                autoComplete="false"
              />
            </FormControl>
            </Grid>
            <Grid item>
          <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password-match">Re-type Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-match"
                type={showPasswordMatch ? 'text' : 'password'}
                value={passwordMatch}
                onChange={handleChangePwMatch}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPasswordMatchClick}
                      onMouseDown={handleMouseDownPasswordMatch}
                      edge="end"
                    >
                      {showPasswordMatch ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Re-type Password"
                className={classes.textField}
                autoComplete="false"
              />
            </FormControl>
            <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start">
            <div style={{color: 'red'}}>{message}</div>
           </Grid>
            </Grid>
            <Grid item></Grid>
            <Grid item>
              
          <Button disabled={isDisabled} onClick={changePw} variant="contained" className={classes.clockTrackButton} >Change Password</Button>
          </Grid>
    </>
  ;
};

export default ChangePassword;

