import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Grid, makeStyles, Theme, createStyles, Paper } from "@material-ui/core";
import { useState, useEffect } from "react";
import { getAllTimeEntriesReports  } from "../../services/report/callTimeReport";
// import UserForm from "./UserForm";
import { displayLoader, hideLoader } from '../../services/utility/loader';
import MaterialTable from "material-table";
import dayjs from "dayjs";

interface Props {
    setShowEnc?: any;
    showEnc?: boolean;
    userInfo: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      dense: {
        marginTop: theme.spacing(2),
      },
      menu: {
        width: 200,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      fakelink: {
        cursor: "pointer",
        color: "blue",
        textDecoration: "underline"
      },
        hidden: {
        display: "none",
        visibility: "hidden"
        }
  }),
);


export const TimeReportsAll: React.FC<Props & RouteComponentProps> = (props) => {

    const {userInfo} = props;
    const [showTable, setShowTable] =  useState(false);
    const [timeReports, setTimeReports] = useState<any[]>([]);

    const userSearch = () => {
        findTimeReports();
    }

    const findTimeReports  = async () => {
        const response: any = await getAllTimeEntriesReports();
        if (response && response.success) {
            setTimeReports(response.results);
            hideLoader();
        }        
    };

    useEffect(() => {
        displayLoader();
        userSearch();
        setShowTable(true);
        hideLoader();
    }, [userInfo]);


  return (
      <>
            <Paper style={{margin: "3%"}}>
               
            <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            >
                    {/* <Paper className={classes.paper}> */}
                    {showTable && 
                    <>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        >
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} alignItems="stretch">
                            <MaterialTable
                                components={{}}
                                columns={[  
                                    {
                                    title: 'Developer',
                                    field: 'username'
                                    },
                                    {
                                    title: 'Project Manager',
                                    field: 'pmUsername'
                                    },
                                    {
                                    title: 'Customer',
                                    field: 'company'
                                    },
                                    {
                                    title: 'Dev Hours',
                                    field: 'devHours',
                                    align: 'right'
                                    },
                                    {
                                    title: 'PM Hours',
                                    field: 'pmHours',
                                    align: 'right'
                                    },
                                    {
                                    title: 'Total',
                                    field: 'total',
                                    align: 'right'
                                    },
                                    {
                                    title: 'Dev Rate',
                                    field: 'devRate',
                                    align: 'right'
                                    },
                                    {
                                    title: 'Dev Pay Rate',
                                    field: 'dmPayableRate',
                                    align: 'right'
                                    },
                                    {
                                    title: 'PM Rate',
                                    field: 'pmRate',
                                    align: 'right'
                                    },
                                    {
                                    title: 'PM Pay Rate',
                                    field: 'pmPayableRate',
                                    align: 'right'
                                    },
                                    {
                                    title: 'Project Name',
                                    field: 'projectName'
                                    },
                                    // {
                                    // title: 'Start Date',
                                    // field: 'startDate',
                                    //     render: (rowData: any) => {
                                    //         return (
                                    //         <>{dayjs(rowData.startDate).format("MM/DD/YYYY")}</>
                                    //     );
                                    //     }
                                    // },
                                    // {
                                    // title: 'End Date',
                                    // field: 'endDate',
                                    // render: (rowData: any) => {
                                    //     return (
                                    //     <>{dayjs(rowData.endDate).format("MM/DD/YYYY")}</>
                                    // );
                                    // }
                                    // },
                                    {
                                    title: 'Work Date',
                                    field: 'workDate',
                                        render: (rowData: any) => {
                                            return (
                                            <>{dayjs(rowData.workDate).format("MM/DD/YYYY")}</>
                                        );
                                        }
                                    },
                                    {
                                    title: 'Comments',
                                    field: 'comments'
                                    }
                                ]}
                                    options={{
                                        exportButton: true,
                                        search: true,
                                        showTitle: false,
                                        exportFileName: "time_entries",
                                        rowStyle: {
                                            verticalAlign: "baseline"
                                        },
                                        columnsButton: true,
                                        pageSize: 20,
                                        exportAllData: true,
                                    }}
                                data={timeReports}
                            />
                        </Grid>
                    </Grid>
                    </>
                    }

                </Grid>
                </Paper>
            </>
  );
};

export default TimeReportsAll;