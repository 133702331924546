import Axios from "axios";

export const callSprints = async (
  date : string,
) => {
  try {
    
    
    const { data }: { data: any } = await Axios.post(
      `/api/utility/sprint/${date}`,
      {

      }, 
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        id: response.id,
        startDate: response.startDate,
        endDate: response.endDate,
        sprintNumber: response.sprintNumber,
        year: response.year
      };
    } else {
      return {
        success: false,
        id: null,
        startDate: '',
        endDate: '',
        sprintNumber: '',
        year: ''
      };
    }
  }
};