export const displayLoader = () => {
    let elem = document.getElementById('lds-spinner');
    if (elem) {
        elem.setAttribute("class", "lds-spinner");
    }
};

export const hideLoader = () => {
    let elem = document.getElementById('lds-spinner');
    if (elem) {
        elem.setAttribute("class", "hidden");
    }
};