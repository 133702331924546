import * as React from "react";
import { Field, Form, Formik } from "formik";
import { Grid, makeStyles, Theme, Button} from "@material-ui/core";
import { VacationModelType } from "../../model/entries/VacationModelType";
import { useState } from "react";
import { updateOrCreateVacation } from "../../services/vacations/callVacation";
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";


interface Props {
    vacation?: any;
    isUpdate: boolean;
    handleClose: any;
    updateVacation: any;
    userInfo: any;
    importedClasses: any;
    refresh: any;
}

const useStyle = makeStyles((theme: Theme) => {
    return {
      formControl: {
        minWidth: 120,
        display: "flex",
        flexDirection: "column",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        width: "85%"
      },
      autocomplete: {
        // marginBottom: 25
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 300
      },
      checkbox: {
        margin: theme.spacing(2)
      }
    };
  });



const VacationForm: React.FC<Props> = props => {
    const { vacation, isUpdate, handleClose, updateVacation, userInfo, importedClasses, refresh } = props;
    const [error, setError] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);
    const classes = useStyle();
    const [startDate, setStartDate] = useState(vacation?.startDateTime ? moment(vacation.startDateTime) : moment(new Date()));
    const [endDate, setEndDate] = useState(vacation?.endDateTime  ? moment(vacation.endDateTime) : moment(new Date()));

    const initialValues = isUpdate ? vacation : {
        updated: null,
        created: null,
        updatedBy: null,
        createdBy: null,
        version: null,
        id: null,
        user: userInfo.userId,
        reason: '',
        contigency: '',
        startDateTime: moment(startDate),
        endDateTime: moment(endDate),
        success: false,
        name: `${userInfo.firstName} ${userInfo.lastName}`
      };

    const [initVals, setInitVals] = useState(initialValues);

    const updateVacationResult = async (updatedVacation : VacationModelType) => {
        updateVacation(updatedVacation.id);
    }

    const handleStartDateChange = (date: any | null) => {
      if (date) {
        setStartDate(date);

        const localValues =  {
          updated: initVals.updated,
          created: initVals.created,
          updatedBy: initVals.updatedBy,
          createdBy: initVals.createdBy,
          version: initVals.version,
          id: initVals.id,
          user: initVals.user,
          reason: initVals.reason,
          contigency: initVals.reason,
          startDateTime: moment(date).set('hour', 12).set('minute', 0).set('second', 0),
          endDateTime: initVals.endDateTime,
          success: false,
          name: initVals.name
        }

        setInitVals(localValues);
      }
    };

    const handleEndDateChange = (date: any | null) => {
      if (date) {
        setEndDate(date);

        const localValues =  {
          updated: initVals.updated,
          created: initVals.created,
          updatedBy: initVals.updatedBy,
          createdBy: initVals.createdBy,
          version: initVals.version,
          id: initVals.id,
          user: initVals.user,
          reason: initVals.reason,
          contigency: initVals.reason,
          startDateTime: initVals.startDateTime,
          endDateTime: moment(date).set('hour', 12).set('minute', 0).set('second', 0),
          success: false,
          name: initVals.name
        }

        setInitVals(localValues);
      }
    };

    return <>
    
    <Formik
        isInitialValid={isUpdate ? true : false}
        initialValues={initVals}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if (endDate >= startDate) {
              values.startDateTime = startDate;
              values.endDateTime = endDate;
              values.name = values.name ? values.name : `${userInfo.firstName} ${userInfo.lastName}`;

              const result: any = await updateOrCreateVacation(values, isUpdate);
              if (result.success) {
                setError(result.error);
                setSubmitting(false);
                handleClose();
                if (!isUpdate) {
                  updateVacationResult(result.result);
                  refresh();
                }              
              } else {
                setSubmitting(false);
                setError("");
                const fileInput: any = document.getElementById(
                  "admin-add-customer-logo"
                );
                resetForm(initialValues);
              //   handleClose();
              }
            }

            
          }}
    >
        <Form className={classes.formControl}>
        <Grid container spacing={3}
            justify="space-evenly"
            alignItems="baseline">
            <Grid container spacing={1}>
                <Grid item>  
                    <Field
                    name="reason"
                    placeholder={"Reason"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Reason"
                    disabled={vacation ? parseInt(userInfo.userId) !== vacation.user : false}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
            <Grid item>  
                    <Field
                    name="contingency"
                    placeholder={"Contingency"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Contingency"
                    multiline
                    rowsMax="4"
                    rows={4}
                    style={{width: "91%"}}
                    disabled={vacation ? (parseInt(userInfo.userType) === 1  || parseInt(userInfo.userType) === 2)? false : parseInt(userInfo.userId) !== vacation.user : false}
                    />
                </Grid>
            </Grid>
          </Grid>
          <br/>

          <Grid container
            direction="row"
            justify="space-evenly"
            alignItems="baseline"
            >  
            <Grid container spacing={1}>
              <Grid item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                      variant="inline"
                      autoOk={true}
                      margin="normal"
                      id="date-picker-dialog"
                      label="Start Date"
                      format="MM/dd/yyyy"
                      value={startDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={vacation ? parseInt(userInfo.userId) !== vacation.user : false}
                    />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                      variant="inline"
                      autoOk={true}
                      margin="normal"
                      id="date-picker-dialog"
                      label="End Date"
                      format="MM/dd/yyyy"
                      value={endDate}
                      minDate={startDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={vacation ? parseInt(userInfo.userId) !== vacation.user : false}
                      minDateMessage={'Date cannot be before Start Date'}
                    />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
        </Grid>
        <Grid container sm>
            <Grid item style={{marginTop: "20px"}}>
                <Button type="submit" variant="contained" className={importedClasses.clockTrackButton} style={{float: "left", width: "92px"}}>Save</Button>
            </Grid>
        </Grid>
        </Form>

    </Formik>
  
    </>
};

export default VacationForm;