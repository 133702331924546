import * as React from "react";
import Axios from "axios";
import { UserResponseType } from "./model/authentication/UserResponseType";

type Action =
  | { type: "resetState" }
  | { type: "sessionValid"; sessionValid: boolean }
  | { type: "refreshToken"; sessionTimeout: number }
  | { type: "sessionLoading"; sessionLoading: boolean }
  | { type: "invalidateSession" }
  | {
      type: "refreshSession";
      sessionData: UserResponseType;
    };

type Dispatch = (action: Action) => void;

type State = {
  sessionValid: boolean;
  sessionLoading: boolean;
  sessionTimeout: number;
  sessionData?: UserResponseType;
};

type TokenContextProviderProps = { children: React.ReactNode };

const TokenContextStateContext = React.createContext<State | undefined>(
  undefined
);
const TokenContextDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function TokenContextReducer(state: State, action: Action) {
  switch (action.type) {
    case "refreshSession": {
      return {
        ...state,
        sessionData: action.sessionData,
        sessionTimeout: action.sessionData.sessionTimeout || 0,
        sessionValid: true
      };
    }
    case "refreshToken": {
      return {
        ...state,
        sessionTimeout: action.sessionTimeout || 0,
        sessionValid: true
      };
    }
    case "sessionValid": {
      return { ...state, sessionValid: action.sessionValid };
    }
    case "sessionLoading": {
      return { ...state, sessionLoading: action.sessionLoading };
    }
    case "invalidateSession": {
      return { ...state, sessionValid: false, sessionData: undefined };
    }
    case "resetState": {
      return initialState;
    }
  }
}
const initialState = {
  sessionValid: false,
  sessionData: undefined,
  sessionTimeout: 600,
  sessionLoading: true
};

function TokenContextProvider({ children }: TokenContextProviderProps) {
  const [state, dispatch] = React.useReducer(TokenContextReducer, initialState);

  React.useEffect(() => {
    const timeAmount =
      String(process.env.NODE_ENV) === "development"
        ? 10000
        : state.sessionTimeout * 1000 - 10000;
    let interval: number;
    interval = setInterval(() => {
      if (state.sessionValid) {
        // refreshToken(dispatch);
      }
    }, timeAmount);
    return () => {
      clearTimeout(interval);
    };
  }, [state.sessionTimeout, state.sessionValid]);

  React.useEffect(() => {
    // attempt to establish session
    refreshSession(dispatch);
  }, []);

  return (
    <TokenContextStateContext.Provider value={state}>
      <TokenContextDispatchContext.Provider value={dispatch}>
        {!state.sessionLoading && children}
      </TokenContextDispatchContext.Provider>
    </TokenContextStateContext.Provider>
  );
}

function useTokenContextState() {
  const context = React.useContext(TokenContextStateContext);
  if (context === undefined) {
    throw new Error(
      "useTokenContextState must be used within a TokenContextProvider"
    );
  }
  return context;
}

function useSessionValid() {
  const context = React.useContext(TokenContextStateContext);
  if (context === undefined) {
    throw new Error(
      "useTokenContextState must be used within a TokenContextProvider"
    );
  }
  return context.sessionValid;
}

function useTokenContextDispatch() {
  const context = React.useContext(TokenContextDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useTokenContextDispatch must be used within a TokenContextProvider"
    );
  }
  return context;
}

const refreshToken = async (dispatch: Dispatch) => {
  try {
    const { data }: { data: UserResponseType } = await fetchSession();
    if (data && data.sessionTimeout) {
      dispatch({ type: "refreshToken", sessionTimeout: data.sessionTimeout });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "sessionValid", sessionValid: false });
  } finally {
    dispatch({ type: "sessionLoading", sessionLoading: false });
  }
};

const refreshSession = async (dispatch: Dispatch) => {
  try {
    const { data }: { data: UserResponseType } = await fetchSession();
    if (data) {
      dispatch({ type: "refreshSession", sessionData: data });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "sessionValid", sessionValid: false });
  } finally {
    dispatch({ type: "sessionLoading", sessionLoading: false });
  }
};

async function fetchSession(): Promise<{ data: UserResponseType }> {
  // return await Axios.get("/api/v1/refresh", {
  return await Axios.get("", {
    headers: {
      Accept: "application/json"
    },
    withCredentials: true,
    baseURL: window.location.origin
  });
}

export {
  TokenContextProvider,
  useTokenContextState,
  useTokenContextDispatch,
  refreshSession,
  useSessionValid
};
