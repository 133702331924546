import Axios from "axios";
import { UserProject } from "../../model/project/UserProject";

export const callUserProjectById = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: UserProject[] } = await Axios.post(`/api/userProject/${id}/full`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        id: response.id,
        pojectId: response.pojectId,
        userId: response.userId,
        user: response.user,
        project: response.project,
        error: response
      };
    } else {
      return {
        success: false,
        id: null,
        pojectId: null,
        userId: null,
        error: "Call 'callProjectById' Failed"
      };
    }
  }
};


export const updateOrCreateUserProject = async (
  userProject : UserProject,
  isUpdate: boolean
) => {
  try {

    const url = isUpdate ? `/api/userProject/${userProject.id}/full/update` : `/api/userProject/new/full`;
    
    const { data }: { data: UserProject[] } = await Axios.post(
      url,
      {
        userProject: userProject
      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        id: response.id,
        pojectId: response.pojectId,
        user: response.user,
        project: response.project,
        error: response
      };
    } else {
      return {
        success: false,
        id: null,
        pojectId: null,
        userId: null,
        error: "Call 'updateOrCreateProject' Failed"
      };
    }
  }
};

export const allUserProjects = async (
) => {
    try {
    
      const { data }: { data: UserProject[] } = await Axios.post(
        `/api/userProject/all`,
        {
  
        },
      );
  
      return { ...data, success: true };
    } catch (error) {
      const response: any = error;
      if (response) {
        return {
          success: false,
          results: response.results
        };
      } else {
        return {
          success: false,
          results: []
        };
      }
    }
};

export const callUserByProjectId = async (
  projectId : number
) => {
  try {
    
    
    const { data }: { data: UserProject[] } = await Axios.post(`/api/userProject/byProject/${projectId}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        id: response.id,
        pojectId: response.pojectId,
        user: response.user,
        project: response.project,
        error: response
      };
    } else {
      return {
        success: false,
        id: null,
        pojectId: null,
        userId: null,
        error: "Call 'callProjectById' Failed"
      };
    }
  }
};

export const inactivateUserProject = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: UserProject[] } = await Axios.post(
      `/api/userProject/${id}/toggleStatus`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response
      };
    } else {
      return {
        success: false,
        error: response
      };
    }
  }
};
