import Axios from "axios";
import { VacationModelType } from "../../model/entries/VacationModelType";

export const getAllVacations = async () => {
  try {
    
    const { data }: { data: VacationModelType[] } = await Axios.post(
      `/api/vacation/all`,
      {

      },
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        results: response.results
      };
    } else {
      return {
        success: false,
        results: []
      };
    }
  }
};


export const callVacationById = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: VacationModelType[] } = await Axios.post(
      `/api/vacation/${id}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        updated: response.updated,
        created: response.created,
        updatedBy: response.updatedBy,
        createdBy: response.createdBy,
        version: response.version,
        id: response.id,
        user: response.user,
        reason: response.reason,
        contigency: response.contingency,
        startDateTime: response.startDateTime,
        endDateTime: response.endDateTime,
      };
    } else {
      return {
        success: false,
        error: response,
        updated: null,
        created: null,
        updatedBy: null,
        createdBy: null,
        version: null,
        id: null,
        user: null,
        reason: null,
        contigency: null,
        startDateTime: null,
        endDateTime: null,
      };
    }
  }
};

export const updateOrCreateVacation = async (
  vacation : any,
  isUpdate: boolean
) => {
  try {
    
    const url = isUpdate ? `/api/vacation/${vacation.id}/full/update` : `/api/vacation/new`;
    
    const { data }: { data: VacationModelType[] } = await Axios.post(
      url,
      {
        vacation: vacation
      },
      
    );

    return { ...data };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        updated: response.updated,
        created: response.created,
        updatedBy: response.updatedBy,
        createdBy: response.createdBy,
        version: response.version,
        id: response.id,
        user: response.user,
        reason: response.reason,
        contigency: response.contingency,
        startDateTime: response.startDateTime,
        endDateTime: response.endDateTime,
      };
    } else {
      return {
        success: false,
        error: response,
        updated: null,
        created: null,
        updatedBy: null,
        createdBy: null,
        version: null,
        id: null,
        user: null,
        reason: null,
        contigency: null,
        startDateTime: null,
        endDateTime: null,
      };
    }
  }
};

export const callVacationByUserId = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: VacationModelType[] } = await Axios.post(
      `/api/vacation/callVacationByUserId/${id}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        updated: response.updated,
        created: response.created,
        updatedBy: response.updatedBy,
        createdBy: response.createdBy,
        version: response.version,
        id: response.id,
        user: response.user,
        reason: response.reason,
        contigency: response.contingency,
        startDateTime: response.startDateTime,
        endDateTime: response.endDateTime,
      };
    } else {
      return {
        success: false,
        error: response,
        updated: null,
        created: null,
        updatedBy: null,
        createdBy: null,
        version: null,
        id: null,
        user: null,
        reason: null,
        contigency: null,
        startDateTime: null,
        endDateTime: null,
      };
    }
  }
};

export const newVacation = async (
  calendarId : number,
  visitType: number,
  patientId?: number
) => {
  try {
    
    let url = `/api/vacation/new/${calendarId}/${patientId}`;

    if (visitType === 2) {
      url = `/api/daily/new/${patientId}/${calendarId}`;
    } else if (visitType === 3) {
      url = `/api/reexam/new/${patientId}/${calendarId}`;
    }
    
    const { data }: { data: VacationModelType[] } = await Axios.post(
      url,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        updated: response.updated,
        created: response.created,
        updatedBy: response.updatedBy,
        createdBy: response.createdBy,
        version: response.version,
        id: response.id,
        user: response.user,
        reason: response.reason,
        contigency: response.contingency,
        startDateTime: response.startDateTime,
        endDateTime: response.endDateTime,
      };
    } else {
      return {
        success: false,
        error: response,
        updated: null,
        created: null,
        updatedBy: null,
        createdBy: null,
        version: null,
        id: null,
        user: null,
        reason: null,
        contigency: null,
        startDateTime: null,
        endDateTime: null,
      };
    }
  }
};

export const deleteVacation = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: VacationModelType[] } = await Axios.post(
      `/api/vacation/${id}/delete`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response
      };
    } else {
      return {
        success: false,
        error: response
      };
    }
  }
};