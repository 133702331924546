import * as React from "react";
// import { AppStateContext, AppType } from "../AppStateContext";
import { RouteComponentProps } from "@reach/router";

/**
 * Component utilized to handle access to private routes.
 * If a user doesn't have access to a route/url, they will be redirect to an Unauthorized page.
 */
export const ResetRoute: React.FC<
  {
    component: React.FunctionComponent | React.ComponentClass;
  } & RouteComponentProps<{ currentApp: string }>
> = ({ component: C}) => {
  

  return (
    <C />
    );
};