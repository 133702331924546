import Axios from "axios";
import { TimeEntryModelType } from "../../model/entries/TimeEntryModelType";


export const callTimeEntryById = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      `/api/timeEntry/${id}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        updated: response.updated,
        created: response.created,
        updatedBy: response.updatedBy,
        createdBy: response.createdBy,
        version: response.version,
        id: response.id,
        hours: response.hours,
        user: response.userId,
        sprint: response.sprint,
        week: response.week,
        day: response.day,
        year: response.year,
        month: response.month,
        managed: response.managed,
        comments: response.comments,
        projectId: response.projectId
      };
    } else {
      return {
        success: false,
        error: response,
        updated: null,
        created: null,
        updatedBy: null,
        createdBy: null,
        version: null,
        id: null,
        hours: null,
        user: null,
        sprint: null,
        week: null,
        day: null,
        year: null,
        month: null,
        managed: null,
        comments: null,
        projectId: null
      };
    }
  }
};

export const updateOrCreateTimeEntry = async (
  timeEntry : any,
  isUpdate: boolean
) => {
  try {
    
    const url = isUpdate ? `/api/timeEntry/${timeEntry.id}/full/update` : `/api/timeEntry/new`;
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      url,
      {
        timeEntry: timeEntry
      },
      
    );

    return { ...data };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        updated: response.updated,
        created: response.created,
        updatedBy: response.updatedBy,
        createdBy: response.createdBy,
        version: response.version,
        id: response.id,
        hours: response.hours,
        user: response.userId,
        sprint: response.sprint,
        week: response.week,
        day: response.day,
        year: response.year,
        month: response.month,
        managed: response.managed,
        comments: response.comments
      };
    } else {
      return {
        success: false,
        error: response,
        updated: null,
        created: null,
        updatedBy: null,
        createdBy: null,
        version: null,
        id: null,
        hours: null,
        user: null,
        sprint: null,
        week: null,
        day: null,
        year: null,
        month: null,
        managed: null,
        comments: null
      };
    }
  }
};

export const startStopWatch = async (
  timeEntry : any
) => {
  try {
    
    const url = `/api/timeEntry/start`;
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      url,
      {
        timeEntry: timeEntry
      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: true,
        error: response,
        updated: response.updated,
        created: response.created,
        updatedBy: response.updatedBy,
        createdBy: response.createdBy,
        version: response.version,
        id: response.id,
        hours: response.hours,
        user: response.userId,
        sprint: response.sprint,
        week: response.week,
        day: response.day,
        year: response.year,
        month: response.month,
        managed: response.managed,
        comments: response.comments
      };
    } else {
      return {
        success: false,
        error: response,
        updated: null,
        created: null,
        updatedBy: null,
        createdBy: null,
        version: null,
        id: null,
        hours: null,
        user: null,
        sprint: null,
        week: null,
        day: null,
        year: null,
        month: null,
        managed: null,
        comments: null
      };
    }
  }
};

export const stopTimer = async (
  timeEntryId : number,
  timeEntry : any
) => {
  try {
    
    const url = `/api/timeEntry/stop/${timeEntryId}`;
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      url,
      {
        timeEntry: timeEntry
      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: true,
        error: response,
        updated: response.updated,
        created: response.created,
        updatedBy: response.updatedBy,
        createdBy: response.createdBy,
        version: response.version,
        id: response.id,
        hours: response.hours,
        user: response.userId,
        sprint: response.sprint,
        week: response.week,
        day: response.day,
        year: response.year,
        month: response.month,
        managed: response.managed,
        comments: response.comments
      };
    } else {
      return {
        success: false,
        error: response,
        updated: null,
        created: null,
        updatedBy: null,
        createdBy: null,
        version: null,
        id: null,
        hours: null,
        user: null,
        sprint: null,
        week: null,
        day: null,
        year: null,
        month: null,
        managed: null,
        comments: null
      };
    }
  }
};

export const callTimeEntryByUserId = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      `/api/timeEntry/callTimeEntryByUserId/${id}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        id: response.id,
        hours: response.hours,
        user: response.userId,
        sprint: response.sprint,
        week: response.week,
        day: response.day,
        year: response.year,
        month: response.month,
        managed: response.managed,
        comments: response.comments,
        projectId: response.projectId
      };
    } else {
      return {
        success: false,
        error: response,
        id: null,
        hours: null,
        user: null,
        sprint: null,
        week: null,
        day: null,
        year: null,
        month: null,
        managed: null,
        comments: null,
        projectId: null
      };
    }
  }
};

export const callTimeEntryByProjectIdAndSprint = async (
  projectId : number,
  sprintId: number,
  year: number
) => {
  try {
    
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      `/api/timeEntry/callTimeEntryByProjectIdAndSprint/${projectId}/${sprintId}/${year}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        id: response.id,
        hours: response.hours,
        user: response.userId,
        sprint: response.sprint,
        week: response.week,
        day: response.day,
        year: response.year,
        month: response.month,
        managed: response.managed,
        comments: response.comments,
        projectId: response.projectId
      };
    } else {
      return {
        success: false,
        error: response,
        id: null,
        hours: null,
        user: null,
        sprint: null,
        week: null,
        day: null,
        year: null,
        month: null,
        managed: null,
        comments: null,
        projectId: null
      };
    }
  }
};

export const callTimeEntryByUserIdAndSprint = async (
  id : number,
  sprintId: number,
  projectId: number,
  year: number
) => {
  try {
    
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      `/api/timeEntry/callTimeEntryByUserId/${id}/${sprintId}/${projectId}/${year}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        id: response.id,
        hours: response.hours,
        user: response.userId,
        sprint: response.sprint,
        week: response.week,
        day: response.day,
        year: response.year,
        month: response.month,
        managed: response.managed,
        comments: response.comments,
        projectId: response.projectId
      };
    } else {
      return {
        success: false,
        error: response,
        id: null,
        hours: null,
        user: null,
        sprint: null,
        week: null,
        day: null,
        year: null,
        month: null,
        managed: null,
        comments: null,
        projectId: null
      };
    }
  }
};

export const callTimeEntryByPatientIdLastFive = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      `/api/timeEntry/callTimeEntryByPatientIdLastFive/${id}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response,
        id: response.id,
        hours: response.hours,
        user: response.userId,
        sprint: response.sprint,
        week: response.week,
        day: response.day,
        year: response.year,
        month: response.month,
        managed: response.managed,
        comments: response.comments,
        projectId: response.projectId
      };
    } else {
      return {
        success: false,
        error: response,
        id: null,
        hours: null,
        user: null,
        sprint: null,
        week: null,
        day: null,
        year: null,
        month: null,
        managed: null,
        comments: null,
        projectId: null
      };
    }
  }
};

export const newTimeEntry = async (
  calendarId : number,
  visitType: number,
  patientId?: number
) => {
  try {
    
    let url = `/api/timeEntry/new/${calendarId}/${patientId}`;

    if (visitType === 2) {
      url = `/api/daily/new/${patientId}/${calendarId}`;
    } else if (visitType === 3) {
      url = `/api/reexam/new/${patientId}/${calendarId}`;
    }
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      url,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: true,
        error: response,
        updated: response.updated,
        created: response.created,
        updatedBy: response.updatedBy,
        createdBy: response.createdBy,
        version: response.version,
        id: response.id,
        hours: response.hours,
        user: response.userId,
        sprint: response.sprint,
        week: response.week,
        day: response.day,
        year: response.year,
        month: response.month,
        managed: response.managed,
        comments: response.comments,
        projectId: response.projectId
      };
    } else {
      return {
        success: false,
        error: response,
        updated: null,
        created: null,
        updatedBy: null,
        createdBy: null,
        version: null,
        id: null,
        hours: null,
        user: null,
        sprint: null,
        week: null,
        day: null,
        year: null,
        month: null,
        managed: null,
        comments: null,
        projectId: null
      };
    }
  }
};

export const deleteTimeEntry = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: TimeEntryModelType[] } = await Axios.post(
      `/api/timeEntry/${id}/delete`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response
      };
    } else {
      return {
        success: false,
        error: response
      };
    }
  }
};


export const allOpenTimerProjects = async (
  id : number
) => {
    try {
    
      const { data }: { data: TimeEntryModelType[] } = await Axios.post(
        `/api/timeEntry/runningTimer/${id}`,
        {
  
        },
      );
  
      return { ...data, success: true };
    } catch (error) {
      const response: any = error;
      if (response) {
        return {
          success: false,
          results: response.results
        };
      } else {
        return {
          success: false,
          results: []
        };
      }
    }
};