import * as React from "react";
import { useState, useEffect } from "react";
import ProjectIndex from "./project/ProjectIndex";
import { Typography, makeStyles, Theme, createStyles, Paper, 
AppBar, Box, Toolbar, Button, IconButton, Grid, MenuItem, TextField } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import CompanyIndex from "./company/CompanyIndex";
import UserIndex from "./user/UserIndex";
import ReportIndex from "./report/ReportIndex";
import UserEditIndex from "./user/UserEditIndex";
import TimeEntryTimer from "./entries/TimeEntryTimer";
import UserTimeReport from "./report/UserTimeReport";
// import DropDown from "./common/DropDown";
import { getAllUsers, callUserById } from "../services/users/callUsers";
import VacationIndex from "./vacation/VacationIndex";
import AppLogout from "../AppLogout";


interface Props {
    showCal?: boolean;
    showPatient?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    barLeft: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-start"
    },
    barRight: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-end"
    },
    link: {
      margin: theme.spacing(1),
      color: "#333333",
      '&:hover': {
          textDecoration: "none",
          color: "#25ca94",
          cursor: "pointer"
      },
      fontWeight: "bold"
    },
    linkSelected: {
        margin: theme.spacing(1),
        textDecoration: "none",
        color: "#25ca94",
        cursor: "pointer",
        fontWeight: "bold"
    },
    linkRight: {
        color: "#333333",
        '&:hover': {
            textDecoration: "none",
            color: "#25ca94",
            cursor: "pointer"
        },
        fontWeight: "bold"
      },
    linkStatic: {
        margin: theme.spacing(1),
        color: "#333333",
        '&:hover': {
            textDecoration: "none",
            color: "#25ca94"
        },
        fontWeight: "bold",
    },
    linkNone: {
        margin: theme.spacing(1),
        color: "#25ca94",
        '&:hover': {
            textDecoration: "none"
        },
        fontWeight: "bold",
        fontSize: '1.2em'
    },
    fixedMenu: {
        backgroundColor: "#fff",
        color: "#333333",
    },
    clockTrackButton: {
        margin: theme.spacing(1),
        color: "#333333",
        '&:hover': {
            textDecoration: "none",
            color: "#25ca94"
        },
        fontWeight: "bold"
    },
    hidden: {
        display: "none",
        visibility: "hidden"
    },
    formControl: {
        minWidth: 120,
        display: "flex",
        flexDirection: "column",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        width: "85%"
    },
    highlight: {
      margin: theme.spacing(1),
      color: "#271bc0",
      '&:hover': {
          textDecoration: "none",
          color: "#25ca94",
          cursor: "pointer"
      },
      fontWeight: "bold"
    }
  }),
);


export const MainIndex: React.FC<Props & RouteComponentProps> = (props) => {

    const [userInfo, setUserInfo] = useState<any>({});
    const [assumedUser, setAssumedUser] = useState<any>(undefined);
    const [assumeUserInfoId, setAssumeUserInfoId] = useState('0');

    const classes = useStyles();
    const handleLogout = () => {
        localStorage.clear();
    }

    const [showPat, setShowPat] = useState(true);    
    const [showComp, setShowComp] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [showUserEdit, setShowUserEdit] = useState(false);
    const [showUserTS, setShowUserTS] = useState(false);
    const [showVacay, setShowVacay] = useState(false);
    const [showAccountEdit, setShowAccountEdit] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSPorPM, setSPorPM] = useState(false);
    const [isAcct, setIsAcct] = useState(false);
    const [timerId, setTimerId] = useState<any>(undefined);
    const [projectId, setProjectId] = useState<string>('0');
    const [users, setUsers] = useState<any>([]);

    const getUsers = async () => {
        const response: any = await getAllUsers();
        if (response.success) {
            if (response.results 
                && response.results.length > 0) {
                    setUsers(response.results);
            }
        }  
    }

    const showPatient = () => {
        setShowPat(true);
        setShowComp(false);
        setShowReport(false);
        setShowUserEdit(false);
        setShowAccountEdit(false);
        setShowUserTS(false);
        setShowVacay(false);
    }

    const showCompany = () => {
        setShowPat(false);
        setShowComp(true);
        setShowReport(false);
        setShowUserEdit(false);
        setShowAccountEdit(false);
        setShowUserTS(false);
        setShowVacay(false);
    }

    const showReporting = () => {
        setShowPat(false);
        setShowComp(false);
        setShowReport(true);
        setShowUserEdit(false);
        setShowAccountEdit(false);
        setShowUserTS(false);
        setShowVacay(false);
    }

    const showUserEditView = () => {
        setShowPat(false);
        setShowComp(false);
        setShowReport(false);
        setShowUserEdit(true);
        setShowAccountEdit(false);
        setShowUserTS(false);
        setShowVacay(false);
    }

    const showAccount = () => {
        setShowPat(false);
        setShowComp(false);
        setShowReport(false);
        setShowUserEdit(false);
        setShowAccountEdit(true);
        setShowUserTS(false);
        setShowVacay(false);
    }

    const showUserTime = () => {
        setShowPat(false);
        setShowComp(false);
        setShowReport(false);
        setShowUserEdit(false);
        setShowAccountEdit(false);
        setShowUserTS(true);
        setShowVacay(false);
    }

    const showVacayView = () => {
        setShowPat(false);
        setShowComp(false);
        setShowReport(false);
        setShowUserEdit(false);
        setShowAccountEdit(false);
        setShowUserTS(false);
        setShowVacay(true);
    }

    useEffect(() => {
        setUserInfo({
                firstName: localStorage.getItem("userFirstName"),
                lastName: localStorage.getItem("userLastName"),
                role: localStorage.getItem('role'),
                expires: localStorage.getItem('expires'),
                userId: localStorage.getItem("userId"),
                userType: localStorage.getItem("userType"),
                ownerId: localStorage.getItem("ownerId"),
                fullName: `${localStorage.getItem("userFirstName")} ${localStorage.getItem("userLastName")}`,
                isSp: localStorage.getItem("sp"),
                isAdmin: localStorage.getItem("admin"),
                isPm: localStorage.getItem("pm"),
            }
        );
        getUsers();
    }, []);

    useEffect(() => {
        setIsAdmin(userInfo.userType === '1');
        setSPorPM((userInfo.userType === '3' || userInfo.userType === '2'));
        setIsAcct(userInfo.userType === '5');
    }, [userInfo]);

    const assumeUserInfo = async (event: any) => {
        let assumedId = event.target.value;

        if (parseInt(assumedId) > 0) {
            const response: any = await callUserById(assumedId);
            if (response.success) {
                setAssumedUser({
                    firstName: response.result.firstName,
                    lastName: response.result.lastName,
                    userId: response.result.id,
                    userType: response.result.admin || response.result.projectManager ? '2' : '4',
                    isAdmin: response.result.admin,
                    isPm: response.result.projectManager,
                    isSp: response.result.salesPerson,
                }
            );
            setAssumeUserInfoId(assumedId);
            } 
        } else {
            setAssumeUserInfoId('0');
            setAssumedUser(undefined);
            window.location.href = "/";
        }
    }

    return (
        <>

        <Box >
            <AppBar position="static" className={classes.fixedMenu}>
                <Toolbar>
                <img className={classes.link} src="images/png/fhg-logo-90.png" alt="FHG logo" onClick={() => {window.location.href = "/"}}/>
                <IconButton
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                >
                </IconButton>
                <Grid container direction="row" justify="space-between">
                    <Grid item className={classes.barLeft}>
                        <Typography>
                            <Button onClick={showPatient} className={showPat ? classes.linkSelected : classes.link}>
                                Project
                            </Button>
                            <Button onClick={showUserTime} className={showUserTS ? classes.linkSelected : classes.link}>
                                My Time &nbsp;&nbsp;&nbsp;
                            </Button>
                            <Button onClick={showVacayView} className={showVacay ? classes.linkSelected : classes.link}>
                                Vacation &nbsp;&nbsp;&nbsp;
                            </Button>
                          
                            {(isSPorPM || isAdmin) &&
                                <Button onClick={showCompany} className={showComp ? classes.linkSelected : classes.link}>
                                    Customers
                                </Button>
                            }
                            {(isAcct || isSPorPM || isAdmin) &&
                                <Button onClick={showReporting} className={showReport ? classes.linkSelected : classes.link}>
                                    Reporting
                                </Button>
                            }
                            {(isSPorPM || isAdmin) &&
                                <Button onClick={showUserEditView} className={showUserEdit ? classes.linkSelected : classes.link}>
                                    Users
                                </Button>
                            }                                    
                        </Typography>
                    </Grid>
                    <Grid item container sm>
                        {(isSPorPM || isAdmin) &&
                            <TextField 
                            id="assumedUserId"
                            value={assumeUserInfoId}
                            label="Assume User"
                            onChange={assumeUserInfo}
                            // fullWidth={true}
                            select
                            variant="outlined"
                            // className={classes.button}
                            >
                            <MenuItem key={`0`} value={`0`}>      
                            Select a User                  
                            </MenuItem>
                            {users.map((option: any) => (
                            <MenuItem key={`${option.name}`} value={option.id}>
                            {`${option.lastName}, ${option.firstName}`}
                            </MenuItem>
                            ))}
                            </TextField >  
                        }
                    </Grid>
                    <Grid item className={classes.barLeft}>
                        <Button onClick={showAccount} className={showAccountEdit ? classes.linkSelected : classes.link}>
                            Hi {userInfo.firstName} &lt;{userInfo.role}&gt;
                        </Button> 
                        <Button href={"/"} onClick={handleLogout} className={classes.linkRight}>
                            Logout
                        </Button>    
                    </Grid>
                    </Grid>
                </Toolbar>
                <Grid container lg style={{marginLeft:'150px'}}>
                    {userInfo && (userInfo.userType === '4') &&
                    <>
                        <span style={{marginTop: "15px"}}>Timer: </span><TimeEntryTimer timerProjectId={projectId} setTimerId={setTimerId} userInfo={assumedUser ? assumedUser : userInfo} importedClasses={classes}/> 
                    </>

                    }
                </Grid>
            </AppBar>
        </Box>
            <Paper style={{marginTop: "15px"}}>
                {showPat && 
                    <>
                        <ProjectIndex userInfo={assumedUser ? assumedUser : userInfo} importedClasses={classes} timerId={timerId} setTimerProjectId={setProjectId}/>
                    </>
                }
                {showUserTS && 
                    <>
                        <UserTimeReport userInfo={assumedUser ? assumedUser : userInfo} />
                    </>
                }
                {showComp && 
                    <>
                        <CompanyIndex userInfo={assumedUser ? assumedUser : userInfo} importedClasses={classes}/>
                    </>
                }
                {showReport && 
                    <>
                        <ReportIndex userInfo={assumedUser ? assumedUser : userInfo} classesImported={classes}/>
                    </>
                }
                {showUserEdit && 
                    <>
                       <UserIndex userInfo={assumedUser ? assumedUser : userInfo} importedClasses={classes}/>
                    </>
                }
                {showAccountEdit && 
                    <>
                       <UserEditIndex userInfo={assumedUser ? assumedUser : userInfo} importedClasses={classes}/>
                    </>
                }
                {showVacay && 
                    <>
                       <VacationIndex userInfo={assumedUser ? assumedUser : userInfo} importedClasses={classes}/>
                    </>
                }
            </Paper>
            <AppLogout/>
        </>
    );
};

export default MainIndex;
