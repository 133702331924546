import * as React from "react";
import { Field, Form, Formik } from "formik";
import { Grid, makeStyles, Theme, Button} from "@material-ui/core";
import { CompanyFullModelType } from "../../model/company/CompanyFullModelType";
import { useState } from "react";
import { updateOrCreateCompany } from "../../services/entries/callCompany";
import { TextField } from 'formik-material-ui';
import DropDown from "../common/DropDown";
import { callProjectManagers } from "../../services/utility/callProjectManagers";
import { callSalesPeople } from "../../services/utility/callSalesPeople";
import { keyUpPhone, keyDownPhone, phoneRegex } from '../utility/Utils';


interface Props {
    company?: any;
    isUpdate: boolean;
    handleClose: any;
    updateCompany: any;
    userInfo: any;
    importedClasses: any;
}

const useStyle = makeStyles((theme: Theme) => {
    return {
      formControl: {
        minWidth: 120,
        display: "flex",
        flexDirection: "column",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        width: "85%"
      },
      autocomplete: {
        // marginBottom: 25
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 300
      },
      checkbox: {
        margin: theme.spacing(2)
      }
    };
  });



const CompanyForm: React.FC<Props> = props => {
    const { company, isUpdate, handleClose, updateCompany, userInfo, importedClasses } = props;
    const [error, setError] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);
    const [projectManagers, setProjectManagers] = useState<any[]>([]);
    const [salesPeople, setSalesPeople] = useState<any[]>([]);
    const classes = useStyle();

    const initialValues = isUpdate ? company : {
        name: '',
        id: null,
        companyId: null,
        contact: '',
        phone: '',
        email: '',
        active: true,
        success: false
      };

    const getProjectManagers = async () => {
      const response: any = await callProjectManagers();
      if (response.success && response.results) {
          setProjectManagers(response.results);
      } 
    }

    const getSalesPeople = async () => {
      const response: any = await callSalesPeople();
      if (response.success && response.results) {
          setSalesPeople(response.results);
      }  
    }



    React.useEffect(() => {
        getProjectManagers();
        getSalesPeople();
    }, []);

    const updateCompanyResult = async (updatedCompany : CompanyFullModelType) => {
        updateCompany(updatedCompany.id);
    }

    return <>
    
    <Formik
        isInitialValid={isUpdate ? true : false}
        initialValues={initialValues}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            const result: any = await updateOrCreateCompany(values, isUpdate);
            if (result.success) {
              setError(result.error);
              setSubmitting(false);
              if (!isUpdate) {
                updateCompanyResult(result.result);
              }
              handleClose();
            } else {
              setSubmitting(false);
              setError("");
              const fileInput: any = document.getElementById(
                "admin-add-customer-logo"
              );
              resetForm(initialValues);
            //   handleClose();
            }
          }}
    >
        <Form className={classes.formControl}>
        <Grid container spacing={3}
            direction="row"
            justify="space-evenly"
            alignItems="baseline">
            <Grid container spacing={1}>
                <Grid item>  
                    <Field
                    name="name"
                    placeholder={"Customer Name"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Customer Name"
                    />
                </Grid>
                <Grid item>  
                    <Field
                    name="contact"
                    placeholder={"Customer Contact"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Customer Contact"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item> 
                    <Field
                    name="phone"
                    placeholder={"Customer Phone"}
                    isParent={true}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Customer Phone"
                    render={({ field }: any) => (
                      <Field
                        className={classes.autocomplete}
                        component={TextField}
                        name="phone"
                        placeholder={"Customer Phone"}
                        value={field.value ? field.value.replace(phoneRegex, "($1) $2-$3") : ''}
                        margin="normal"
                        variant="outlined"
                        label="Customer Phone"
                        onKeyDown={keyDownPhone}
                        onKeyUp={keyUpPhone}
                      />
                    )}
                  />
                </Grid>
                <Grid item> 
                    <Field
                    name="email"
                    placeholder={"Customer Email"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Customer Email"
                    type="email"
                    />
                </Grid>
                {/* <Grid item>  
                  <Field
                  name="active"
                  margin="normal"
                  variant="outlined"
                  type="checkbox"
                  className={classes.checkbox}
                  disabled={parseInt(userInfo.userType) > 1}
                  />
                  <label htmlFor='managed'>Active</label>
              </Grid> */}
            </Grid>
            <Grid container spacing={1}>
                {/* <Grid item> 
                  <DropDown data={projectManagers} label={"Project Manager"} name={"projMgr"} classes={classes} userInfo={userInfo}/>
                </Grid>
                <Grid item> 
                    <DropDown data={salesPeople} label={"Sales Person"} name={"salesPerson"} classes={classes} userInfo={userInfo}/>
                </Grid> */}
            </Grid>
            <Grid container spacing={1}>
              <Grid item sm={6} lg={6}>  
                      <Field
                      name="notes"
                      placeholder={"Notes"}
                      isParent={false}
                      className={classes.autocomplete}
                      component={TextField}
                      margin="normal"
                      variant="outlined"
                      label="Notes"
                      multiline
                      rowsMax="4"
                      rows={4}
                      style={{width: "91%"}}
                      />
              </Grid>
            </Grid>
        </Grid>
        
        <Grid container sm>
            <Grid item style={{marginTop: "20px"}}>
                <Button type="submit" variant="contained" className={importedClasses.clockTrackButton} style={{float: "left", width: "92px"}}>Save</Button>
            </Grid>
        </Grid>
        </Form>

    </Formik>
  
    </>
};

export default CompanyForm;