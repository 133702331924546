import Axios from "axios";
import { UserResponseType } from "../model/authentication/UserResponseType";
import { UserType } from "../model/enums/UserType";

export const callLoginApi = async ({
  username,
  password
}: {
  username: string;
  password: string;
}) => {
  try {
    const { data }: { data: UserResponseType } = await Axios.post(
      `/authenticate`,
      {
        username,
        password,
        crossDomain: true
      },
      {
        headers: {
          Accept: "application/json"
        },
        //withCredentials: true,
        //baseURL: window.location.origin
      }
    );
    // console.log("login api data", data);
    return { ...data, success: true };
  } catch (error) {
    console.error(error);
    return {
      firstName: "",
      lastName: "",
      success: false,
      token: "",
      sessionTimeout: undefined,
      expires: undefined,
      ownerId: "",
      error: '',
      userType: undefined,
      role: undefined,
      userId: undefined,
      id: undefined,
      admin: undefined,
      pm: undefined,
      sp: undefined,
    };
  }
};
