import * as React from "react";
import {
  Button,
  Theme,
  Grid,
  OutlinedInput,
  Link,Card, CardContent
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";
import { useState } from "react";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { forgot } from "../services/utility/callChangePassword";


interface Props {
  importedClasses?: any;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
  
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '300px',
      },
      '& .MuiButtonBase-root': {
        margin: theme.spacing(2),
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        margin: theme.spacing(1),
        minWidth: 300,
      },
      dense: {
        marginTop: theme.spacing(2),
      },
      menu: {
        width: 200,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      cardStyle: {
        border: 'gray solid thin',
        marginTop: theme.spacing(10),
        width: 374
      },
      clockTrackButton: {
          margin: theme.spacing(1),
          color: "#333333",
          '&:hover': {
              textDecoration: "none",
              color: "#25ca94"
          },
          fontWeight: "bold"
      },
      linkStatic: {
          margin: theme.spacing(1),
          color: "#333333",
          '&:hover': {
              textDecoration: "none",
              color: "#25ca94"
          },
          fontWeight: "bold"
      }
  }),
);

/**
 * Login component.
 */
const ForgotPassword: React.FC<Props> = (props) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const classes = useStyles();

  const handleChangeEmail = (event: any) => {
    let value = event.target.value.trim() || '';
    setEmail(value);

    if (value.length > 5) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }

  const forgotPassword = async () => {
    const response = await forgot(email);

    if (response && response.success) {
      setMessage("Please check your email.");
      setIsDisabled(true);
    } else {
      setMessage(response.result);
    }
  };
  
  return <>
  <Grid
  container
  direction="row"
  justify="center"
  alignItems="center"
  spacing={10}
  >
  <Card className={classes.cardStyle}>
        <CardContent>
          <img src="/images/png/fhg-logo-90.png" alt="FHG logo"/>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start">
        <div style={{color: 'green', margin: "15px"}}>{message}</div>
           </Grid>
          <Grid item>
          <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                value={email}
                onChange={handleChangeEmail}
                label="Email"
                className={classes.textField}
              />
            </FormControl>
            </Grid>
            
            <Grid item></Grid>
            <Grid item>
              
          <Button disabled={isDisabled} onClick={forgotPassword} variant="contained" className={classes.clockTrackButton} >Reset Password</Button>
          </Grid>
          <Link href={"/"} className={classes.linkStatic}>Return to Login</Link>
        </CardContent>
      </Card>
    </Grid>
  </>;
};

export default ForgotPassword;

