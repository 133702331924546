import { useEffect } from "react";
import { TextField, Grid, makeStyles, Theme, MenuItem, Button} from "@material-ui/core";
import { useState } from "react";
import { allOpenTimerProjects, stopTimer, startStopWatch } from "../../services/entries/callTimeEntry";
import { getAllProjects } from "../../services/entries/callProjectApi"; 
import dayjs from "dayjs";
import { callSprints } from "../../services/utility/callSprints";

interface Props {
    userInfo: any;
    importedClasses: any;
    setTimerId: any;
    timerProjectId: string;
}

const useStyle = makeStyles((theme: Theme) => {
    return {
      autocomplete: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 300
      }, 
      button: {
        marginLeft: theme.spacing()
      }
    };
  });



const TimeEntryTimer: React.FC<Props> = props => {
    const { userInfo, importedClasses, setTimerId, timerProjectId } = props;
    const classes = useStyle();
    const [selectedDate, setSelectedDate] = useState(new Date);
    const [counting, setCounting] = useState(false);
    const [sprint, setSprint] = useState<any>(undefined);
    const [projectId, setProjectId] = useState('0');
    const [projects, setProjects] = useState<any[]>([]);
    const [timeEntryId, setTimeEntryId] = useState(0);
    const [comments, setComments] = useState('');
    const [error, setError] = useState('');
    const [timerEntry, setTimerEntry] = useState<any>(undefined);
    const [countingId, setCountingId] = useState<any>(undefined);

    const startTimer = async () => {
      if (sprint) {
        const initialValues =  {
          user: parseInt(userInfo.userId),
          sprint: sprint.sprintNumber,
          day: dayjs(selectedDate).date(),
          year: dayjs(selectedDate).year(),
          month: dayjs(selectedDate).month() + 1,
          managed: true,
          comments: comments,
          projectId: parseInt(projectId),
          workDate: dayjs(selectedDate).set('hour', 12).set('minute', 0).set('second', 0),
          active: true,
          startDateTime: dayjs(selectedDate),
          endDateTime: null,
          updated: dayjs(selectedDate),
          created: dayjs(selectedDate),
          updatedBy: parseInt(userInfo.userId),
          createdBy: parseInt(userInfo.userId),
          ownerId: parseInt(userInfo.ownerId)
        }

        const response: any = await startStopWatch(initialValues);

        if (response && response.success) {
          setTimeEntryId(response.result.id);
          setTimerEntry(initialValues);
          setCounting(true);
          setCountingId(projectId)
        }
      }
    }

    const stop = async () => {
      if (timeEntryId && timeEntryId !== 0) {
        timerEntry.comments = comments;
        const response = await stopTimer(timeEntryId, timerEntry);
        if (response && response.success) {
          setCounting(false);
          setComments('');
          if (!timerProjectId) {
            setProjectId('0');
          } else if (timerProjectId !== countingId) {
            setProjectId(timerProjectId);
          }
          setTimeEntryId(0);
          setTimerId(timeEntryId);
        } else {
          setError('Failed to stop!');
        }
      }
    }

    const getSprints = async () => {
      const response: any = await callSprints(dayjs(selectedDate).format("YYYYMMDD"));
      if (response && response.success) {
        let sprint = response.result;
        if (sprint && projectId) {
          setSprint(sprint);
        }
      }  
    }

    const getProjects = async () => {
      if (userInfo && sprint) {
        const response: any = await getAllProjects(undefined, userInfo.userType, userInfo.userId, sprint.year, sprint.sprintNumber);
        if (response && response.success) {
          setProjects(response.results);
        }
      }
    }

    const getRunningProjects = async () => {
      if (userInfo) {
        const response: any = await allOpenTimerProjects(userInfo.userId);
        if (response && response.success && response.result ) {
          if (response.result.length < 1) {
            return;
          }
          setTimeEntryId(response.result.id);
          setProjectId(response.result.projectId);
          setComments(response.result.comments);
          setCounting(true);
          setTimerEntry(response.result);
        } else {

        }
      }
    }

    useEffect(() => {
      getSprints();
    }, []);

    useEffect(() => {
      if (sprint) {
        getProjects();
      }
    }, [userInfo, sprint]);

    useEffect(() => {
      if (!counting) {
        setProjectId(timerProjectId); 
      }
    }, [timerProjectId])

    useEffect(() => {
      getRunningProjects();
    }, []);

    const handleChangeProject= (event: any)  => {
      setProjectId(event.target.value);
    }

    const handleCommentsChange = (event: any) => {
      setComments(event.target.value);
    }

    return <>
    <Grid item>
        <TextField 
            id="projectId"
            value={projectId}
            label="Project"
            onChange={handleChangeProject}
            fullWidth={true}
            select
            variant="outlined"
            className={classes.button}
            disabled={counting}
        >
        <MenuItem key={`0`} value={`0`}>      
        Select a Project                  
            </MenuItem>
        {projects.map((option: any) => (
            <MenuItem key={`${option.name}`} value={option.id}>
            {`${option.name}`}
            </MenuItem>
        ))}
        </TextField >  
        </Grid>
        <span style={{paddingRight: "10px"}}></span>
        {counting &&
          <Grid item>
          <TextField 
              id="comments"
              value={comments}
              label="Comments"
              onChange={handleCommentsChange}
              fullWidth={true}
              variant="outlined"
              className={classes.button}
          ></TextField>
          </Grid>
        }
        <span style={{paddingRight: "10px"}}></span>
        <Grid item>
        {sprint && !counting && projectId && projectId !== '0' && 
        <Button onClick={startTimer} variant="contained" className={importedClasses.clockTrackButton && classes.button} style={{float: "left", width: "92px"}}>Start</Button>
        }

        {sprint && counting && projectId && projectId !== '0' && 
        <>
        <img src="/images/gif/Stopwatch.gif" alt="FHG stopwatch" style={{maxWidth: "50px"}}/>
        <Button onClick={stop} variant="contained" className={importedClasses.clockTrackButton && classes.button} style={{float: "left", width: "92px"}}>End Timer</Button>
        </>
        }
        <div style={{color: 'red'}}>{error}</div>
        </Grid>
    </>
};

export default TimeEntryTimer;