import * as React from "react";
import { navigate } from "@reach/router";
import { useState } from "react";
import { callLogout } from "./services/callLogout";
import { UserResponseType } from "./model/authentication/UserResponseType";
import { useTokenContextState, useTokenContextDispatch } from "./TokenContext";

const initialAppStateContext = {
    isAuthenticated: false,
    userType: undefined,
    firstName: "",
    lastName: "",
    token: "",
    initialPath: "",
    handleAuthenticatedUser: (_: UserResponseType) => {},
    ownerId: ""
  };

  export const AppStateContext = React.createContext(initialAppStateContext);

  export type SessionUser = {
    firstName: string;
    lastName: string;
  };

  export const AppStateProvider: React.FC = ({ children }) => {
    const [isAuthenticated, setAuthenticated] = React.useState(false);
    const [userType, setUserType] = React.useState();
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [ownerId, setOwnerId] = React.useState("");
    const [initialPath, setInitialPath] = useState("insurer/overview");
    const [currentView, setCurrentView] = useState(""); 
    const [token, setToken] = useState(""); 
    const { sessionValid, sessionData } = useTokenContextState();
    const tokenDispatch = useTokenContextDispatch();
  
    /**
     * handle when a user is successfully logged in.
     */
    const handleAuthenticatedUser = React.useCallback(
      ({
        firstName,
        lastName,
        token,
        ownerId
      }: UserResponseType) => {       
        setAuthenticated(true);
        setFirstName(firstName);
        setLastName(lastName);
        setOwnerId(ownerId);
      },
      []
    );

    /**
     * handle when a user is logged out.
     */
    const handleLogoutUser = React.useCallback(async () => {
      await callLogout();

      setAuthenticated(false);
      navigate("/");
    }, []);
      
    /**
     * set initial path when first logged in.
     * @param path - the initial path.
     */
    const handleInitialPath = (path: string) => {
      setInitialPath(path);
    };
  
    /**
     * set the current view on the application (faq vs overview etc)
     * @param currentView - the current view to set
     */
    const handleCurrentView = (currentView: string) => {
      setCurrentView(currentView);
    };

    React.useEffect(() => {
        if (sessionData) {
            handleAuthenticatedUser(sessionData);
        }
      }, [handleAuthenticatedUser, sessionData]);
  
  
    return (
      <AppStateContext.Provider
        value={{
          isAuthenticated,
          userType,
          firstName,
          lastName,
          token,
          initialPath,
          handleAuthenticatedUser,
          ownerId
        }}
      >
        {children}
      </AppStateContext.Provider>
    );
  };
  