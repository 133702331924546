import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Table, TableRow, Grid, makeStyles, Theme, createStyles, Paper, Button, 
    TableHead, TableCell, TableBody, Link, Tabs, Tab, Typography, Box } from "@material-ui/core";
import { useState } from "react";
import { callCompanyById, getAllCompanies, getActiveCompanies, deactivateCompany } from "../../services/entries/callCompany";
import CompanyForm from "./CompanyForm";
import { displayLoader, hideLoader } from '../../services/utility/loader';
import { phoneRegex } from "../utility/Utils";
import MaterialTable from "material-table";

interface Props {
    userInfo: any;
    importedClasses: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      dense: {
        marginTop: theme.spacing(2),
      },
      menu: {
        width: 200,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      inactive: {
        color: 'red',
        '&:hover': {
            textDecoration: "none",
            color: "#25ca94",
            cursor: "pointer"
        },
      }
  }),
);


export const CompanyIndex: React.FC<Props & RouteComponentProps> = (props) => {

    const { userInfo, importedClasses } = props;
    const [showTable, setShowTable] =  useState(false);
    const classes = useStyles();
    const [companies, setCompanies] = useState<any[]>([]);
    const [company, setCompany] = useState<any>(undefined);
    const [selectedCompany, setSelectCompany] = useState<any>(undefined);
    const [selectedTab, setSelectedTab] = useState<any>(0);
    const [companyUpdate, setCompanyUpdate] = useState(false);
    const [showNew, setShowNew] = useState(false);

    const setSelectedCompanyTask = (id: number) => {
        setSelectCompany(id);
        setShowTable(true);
    }

    const findActiveCompanies  = async () => {
        const response: any = await getActiveCompanies();
        if (response.success) {
            setCompanies(response.results);
            hideLoader();
        }        
    };

    const findAllCompanies  = async () => {
        const response: any = await getAllCompanies();
        if (response.success) {
            setCompanies(response.results);
            hideLoader();
        }        
    };

    const goBackToTable = () => {
        setShowTable(true);
        setCompany(undefined);
        setSelectCompany(undefined);
        setShowNew(false);
        hideLoader();
        setSelectedTab(0);
        refresh();
    }

    const findById  = async () => {
        const response: any = await callCompanyById(
            selectedCompany
        );
        if (response.success && response.result) {
            setCompanyUpdate(true);
            setCompany(response.result);
            setShowTable(false);
            window.scrollTo(0, 0);
            hideLoader();
        }        
    };

    React.useEffect(() => {
        if (selectedCompany) {
            displayLoader();
            findById();
        }        
    }, [selectedCompany]);

    React.useEffect(() => {
        refresh();
    }, []);

    React.useEffect(() => {
        hideLoader();
    }, []);

    const handleTabsChange = (event: React.ChangeEvent<{}>, newValue: number) =>{
        setSelectedTab(newValue);
    }

    const openNew = () => {
        setShowNew(true);
    }

    const refresh = () => {
        displayLoader();
        findAllCompanies();
        // if (all) {
        //     findAllCompanies();
        // } else {
        //     findActiveCompanies();
        // }
        setShowTable(true);
        hideLoader();
    }

    const a11yProps = (index: any) =>{
        return {
          id: `full-width-tab-${index}`,
          'aria-controls': `full-width-tabpanel-${index}`,
        };
      }

      interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index: any;
        value: any;
      }
      
      const TabPanel = (props: TabPanelProps) =>{
        const { children, value, index, ...other } = props;
      
        return (
          <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
          >
            <Box p={3}>{children}</Box>
          </Typography>
        );
      }

      const toggleStatus = async (id: number) => {
        await deactivateCompany(id);
        refresh();
      }

  return (
      <>
      
            {showNew && 
                <Paper>
                    <Link onClick={goBackToTable} className={importedClasses.link} >
                        &lt;&nbsp;Back
                    </Link>
                    <Grid container sm>
                        <CompanyForm isUpdate={false} handleClose={goBackToTable} company={company} updateCompany={setSelectCompany} userInfo={userInfo} importedClasses={importedClasses}/>
                    </Grid>
                </Paper>
            }
            {!showNew && 
            <>
            <Grid container>
                <Button onClick={openNew} variant="contained" className={importedClasses.clockTrackButton} style={{float: "left"}}>New Customer</Button>
            </Grid>
            <Grid
            direction="row"
            justify="center"
            alignItems="center"
            >
                <Grid item lg={12}>
                    <Paper className={classes.paper}>
                    {showTable && company === undefined &&
                    <>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        >
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} alignItems="stretch">
                        <MaterialTable
                                components={{}}
                                columns={[
                                    {
                                        title: 'Customer Name',
                                        field: 'name',
                                        render: (row: any) => {
                                            return (
                                                <>
                                                <Link onClick={() => {setSelectedCompanyTask(row.id)}} className={importedClasses.link} >
                                                {row.name}
                                                </Link>
                                            </>
                                        );
                                        },
                                        customFilterAndSearch: (value, rowData) => {
                                            return  rowData.name.toLowerCase().search(value) > -1;
                                        }
                                    },
                                    {
                                    title: 'Contact',
                                    field: 'contact'
                                    },
                                    {
                                    title: 'Customer Phone',
                                    field: 'phone', 
                                    render: (rowData: any) => {
                                        if (rowData.phone) {
                                            return (<>{rowData.phone ? rowData.phone.replace(phoneRegex, "($1) $2-$3") : ''}</>);
                                        } 
                                    }
                                    },
                                    {
                                        title: 'Status',
                                        field: 'name',
                                        render: (row: any) => {
                                            return (
                                                <>
                                                {parseInt(userInfo.userType) === 1 ?
                                                <Link onClick={() => {toggleStatus(row.id)}} className={importedClasses.link} >
                                                    {row.active ? 'Deactivate' : <span className={classes.inactive}>Activate</span>}
                                                </Link>
                                                :  <>{row.active ? 'Active' : <span className={classes.inactive}>Inactive</span> }</>
                                                }
                                            </>
                                        );
                                        },
                                        customFilterAndSearch: (value, rowData) => {
                                            return  rowData.name.toLowerCase().search(value) > -1;
                                        }
                                    },
                                ]}
                                options={{
                                    exportButton: true,
                                    search: true,
                                    showTitle: false,
                                    exportFileName: "companies",
                                    rowStyle: {
                                        verticalAlign: "baseline"
                                    },
                                    pageSize: 20,
                                    exportAllData: true,
                                }}
                                data={companies}
                            />
                    
                    </Grid>
                    </Grid>
                    </>
                    }

                    {company && 
                    <>
                    <div style={{float: "left"}}>
                        <Link onClick={goBackToTable} className={importedClasses.link} >
                            &lt;&nbsp;Back
                        </Link>
                    </div>
                    <br/>
                    <br/>
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabsChange}
                        indicatorColor="primary"
                        // textColor="primary"
                        style={{paddingLeft: "70px"}}
                    >
                        <Tab label="Customer" {...a11yProps(0)} className={importedClasses.link}/>
                    </Tabs>
                    <TabPanel value={selectedTab} index={0}>
                        <CompanyForm isUpdate={companyUpdate} handleClose={goBackToTable} company={company} updateCompany={setSelectCompany} userInfo={userInfo} importedClasses={importedClasses}/>
                    </TabPanel>
                    
                    </>
                    
                    }
                    </Paper>
                </Grid>
                
            </Grid>
            </>
                }
        
    </>
  );
};

export default CompanyIndex;
