import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Grid, makeStyles, Theme, createStyles, Paper } from "@material-ui/core";
import { useState, useEffect } from "react";
import { findAllGroupByYear, findAllGroupByYearByCompanyId, findAllGroupByYearByProjectId } from "../../services/report/callTimeReportByYear";
import { displayLoader, hideLoader } from '../../services/utility/loader';
import MaterialTable from "material-table";

interface Props {
    setShowEnc?: any;
    showEnc?: boolean;
    userInfo: any;
}

var currency = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD' });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      dense: {
        marginTop: theme.spacing(2),
      },
      menu: {
        width: 200,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      fakelink: {
        cursor: "pointer",
        color: "blue",
        textDecoration: "underline"
      },
        hidden: {
        display: "none",
        visibility: "hidden"
        }
  }),
);


export const TimeReportByYear: React.FC<Props & RouteComponentProps> = (props) => {

    const {userInfo} = props;
    const [showTable, setShowTable] =  useState(false);
    const classes = useStyles();
    const [timeReports, setTimeReports] = useState<any[]>([]);
    const [user, setUser] = useState<any>(undefined);
    const [projectId, setProjectId] = useState<any>(0);
    const [companyId, setCompanyId] = useState<any>(0);

    const handleChangeProject = (event: any)  => {
        setCompanyId(0);
        setProjectId(parseInt(event.target.value));
    }

    const handleChangeCompany = (event: any)  => {
        setProjectId(0);
        setCompanyId(parseInt(event.target.value));
    }

    const userSearch = () => {
        findTimeReports();
    }

    const findTimeReports  = async () => {
        const response: any = await findAllGroupByYear();
        if (response && response.success) {
            setTimeReports(response.results);
            hideLoader();
        }        
    };

    useEffect(() => {
        displayLoader();
        // userSearch(physician);   
        userSearch();
        setShowTable(true);
        hideLoader();
        // findCompanies();
        // findProjects();
    }, [userInfo]);

    const findTimeReportsByProjectId  = async () => {
        const response: any = await findAllGroupByYearByProjectId(projectId);
        if (response && response.success) {
            setTimeReports(response.results);
            hideLoader();
        }        
    };

    useEffect(() => {
        if (projectId !== 0) {
            findTimeReportsByProjectId();
        }
        hideLoader();
    }, [projectId]);

    const findTimeReportsByCompanyId  = async () => {
        const response: any = await findAllGroupByYearByCompanyId(companyId);
        if (response && response.success) {
            setTimeReports(response.results);
            hideLoader();
        }        
    };

    useEffect(() => {
        if (companyId !== 0) {
            findTimeReportsByCompanyId();
        }
        hideLoader();
    }, [companyId]);


  return (
      <>
        <Paper style={{margin: "3%"}}>
            <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            >
                    {/* <Paper className={classes.paper}> */}
                    {showTable && user === undefined &&
                    <>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        >
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} alignItems="stretch">
                            <MaterialTable
                                components={{}}
                                columns={[  
                                    {
                                    title: 'Customer',
                                    field: 'company'
                                    },
                                    {
                                    title: 'Project Name',
                                    field: 'projectName'
                                    },
                                    {
                                    title: 'Dev Hours',
                                    field: 'devHours',
                                    align: 'right'
                                    },
                                    {
                                    title: 'PM Hours',
                                    field: 'pmHours',
                                    align: 'right'
                                    },
                                    {
                                    title: 'Total',
                                    field: 'total',
                                    align: 'right'
                                    },
                                    {
                                    title: 'Cost Total',
                                    field: 'totalAmount',
                                    align: 'right',
                                    render: (rowData: any) => {
                                      return (
                                      <>{currency.format(rowData.totalAmount)}</>
                                      )
                                    },
                                    },
                                    {
                                    title: 'Year',
                                    field: 'year',
                                    align: 'right'
                                    }
                                ]}
                                  options={{
                                  exportButton: true,
                                  search: true,
                                  showTitle: false,
                                  exportFileName: "time_entries",
                                  rowStyle: {
                                      verticalAlign: "baseline",
                                      height: "80px",
                                  },
                                  columnsButton: true,
                                  pageSize: 20,
                                  exportAllData: true,
                                }}
                                data={timeReports}
                            />
                        </Grid>
                    </Grid>
                    </>
                    }

                </Grid>
                </Paper>
            </>
  );
};

export default TimeReportByYear;