import * as React from "react";
import MaterialTable from "material-table";
import { Link, makeStyles, Theme } from "@material-ui/core";
import { withStyles, createStyles, WithStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { callUserByProjectId, inactivateUserProject } from "../../services/project/callUserProject";
import ProjectUserForm from "./ProjectUserForm";

interface Props extends WithStyles<typeof styles> {
    handleClick: any;
    userInfo: any;
    projectId: number;
    user: number;
    importedClasses: any;
}

const useStyle = makeStyles((theme: Theme) => {
    return {
      formControl: {
        minWidth: 120,
        display: "flex",
        flexDirection: "column",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        width: "100%"
      },
      autocomplete: {
        // marginBottom: 25
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      approved: {
        color: 'green'
      } 
    };
  });

  const styles = createStyles({
    fakelink: {
        cursor: "pointer",
        color: "blue",
        textDecoration: "underline"
      },
    hidden: {
      display: "none",
      visibility: "hidden"
    }

    }
  );

  const ProjectUserTable: React.FC<Props> = props => {

    
    const { classes, userInfo, projectId, importedClasses} = props;
    const classNames = useStyle();
    const [userProjects, setUserProjects] = useState<any[]>([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [userProject, setUserProject] = useState(undefined);

    const toggleStatus = async (id: number) => {
      await inactivateUserProject(id);
      getUserProjects();
    }

    const getUserProjects = async () => {
      const response: any = await callUserByProjectId(projectId);
      if (response.success) {
          let list = response.results;
          setUserProjects(list);
      }  
    }
    
    useEffect(() => {
      getUserProjects();
    }, []);

    return <div className={classNames.formControl}>
        <ProjectUserForm  isUpdate={isUpdate} userProject={userProject} projectId={projectId} user={userInfo.userId} refresh={getUserProjects}
              setIsUpdate={setIsUpdate} userInfo={userInfo} importedClasses={importedClasses}/>
        <MaterialTable
            components={{}}
            columns={[
                {
                    title: '',
                    field: '',
                    render: (rowData: any) => {
                        return (
                          <>
                          <>
                            <Link onClick={() => {toggleStatus(rowData.id)}} className={importedClasses.link} >
                              {rowData.active ? 'Remove' : 'Add'}
                            </Link>
                            </>
                        </>
                    );
                    }
                },
                {
                  title: 'Developer',
                  field: 'userName'
                },
                {
                  title: 'Customer Name',
                  field: 'companyName'
                },
                {
                  title: 'Project Name',
                  field: 'projectName'
                }
            ]}
            options={{
              exportButton: true,
              search: true,
              sorting: true,
              showTitle: false,
              exportFileName: "user_projects",
              paging: true,
              rowStyle: {
                verticalAlign: "baseline"
              },
              pageSize: 20,
              exportAllData: true,
            }}
            data={userProjects}
          />
      </div>

  };

  export default withStyles(styles)(ProjectUserTable);
