import * as React from "react";
// import { AppStateContext, AppType } from "../AppStateContext";
import Login from "./Login";
import { RouteComponentProps } from "@reach/router";
import { UserResponseType } from "../model/authentication/UserResponseType";
import { useEffect } from "react";

/**
 * Component utilized to handle access to private routes.
 * If a user doesn't have access to a route/url, they will be redirect to an Unauthorized page.
 */
export const PrivateRoute: React.FC<
  {
    component: React.FunctionComponent | React.ComponentClass;
  } & RouteComponentProps<{ currentApp: string }>
> = ({ component: C}) => {

  const [isAuthenticated, setAuthenticated] = React.useState(false);

  const handleLogin = (response: UserResponseType) => {
    if (response.token !== "") {
      setAuthenticated(true);
      localStorage.setItem("token", response.token);
      localStorage.setItem("sessionTimeout", response.sessionTimeout as unknown as string);
      localStorage.setItem("expires", response.expires as unknown as string);
      localStorage.setItem("ownerId", response.ownerId as unknown as string);
      localStorage.setItem("userFirstName", response.firstName as unknown as string);
      localStorage.setItem("userLastName", response.lastName as unknown as string);
      if (response.userType === 1) {
        localStorage.setItem("role", "ADMIN" as unknown as string);
      } else if (response.userType === 2) {
        localStorage.setItem("role", "PM" as unknown as string);
      } else if (response.userType === 3) {
        localStorage.setItem("role", "SP" as unknown as string);
      } else {
        localStorage.setItem("role", "USER" as unknown as string);
      }
      localStorage.setItem("userType", response.userType as unknown as string);
      localStorage.setItem("userId", response.userId as unknown as string);
      localStorage.setItem("pm", response.pm as unknown as string);
      localStorage.setItem("admin", response.admin as unknown as string);
      localStorage.setItem("sp", response.sp as unknown as string);
    }
  }

  const checkSession = () => {
    const token = localStorage.getItem("token");
    if (token && token !== "") {
      setAuthenticated(true);
    }
  }

  const checkSessionTimeout = () => {
    const timeout = localStorage.getItem("expires") as unknown as number;
    const now = Date.now();

    if (now > timeout) {
      localStorage.clear();
    }
  }

  useEffect(() => {
    checkSessionTimeout();
    checkSession();
  }, [isAuthenticated, localStorage]);
  

  return isAuthenticated ? (
    <C />
    ) : (
    <Login authenticated={isAuthenticated} handleLogin={handleLogin}/>
  );
};