import Axios from "axios";
import { CompanysModelType } from "../../model/company/CompaniesModelType";
import { CompanyFullModelType } from "../../model/company/CompanyFullModelType";

export const callCompanyByDob = async (
  dob : string,
  ownerId: number
) => {
  try {
    
    
    const { data }: { data: CompanysModelType[] } = await Axios.post(`/api/company/${ownerId}/dob/${dob}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        id: response.id,
        name: response.name,
        contact: response.contact,
        phone: response.phone,
        email: response.email,
        active: response.active
      };
    } else {
      return {
        success: false,
        id: null,
        name: null,
        contact: null,
        phone: null,
        email: null,
        active: null
      };
    }
  }
};

export const callCompanyById = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: CompanysModelType[] } = await Axios.post(`/api/company/${id}/full`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        id: response.id,
        name: response.name,
        contact: response.contact,
        phone: response.phone,
        email: response.email,
        active: response.active
      };
    } else {
      return {
        success: false,
        id: null,
        name: null,
        contact: null,
        phone: null,
        email: null,
        active: null,
        error: "Call 'callCompanyById' Failed"
      };
    }
  }
};

export const updateOrCreateCompany = async (
  company : CompanyFullModelType,
  isUpdate: boolean
) => {
  try {

    const url = isUpdate ? `/api/company/${company.id}/full/update` : `/api/company/new/full`;
    
    const { data }: { data: CompanyFullModelType[] } = await Axios.post(
      url,
      {
        company: company
      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        id: response.id,
        name: response.name,
        contact: response.contact,
        phone: response.phone,
        email: response.email,
        active: response.active
      };
    } else {
      return {
        success: false,
        id: null,
        name: null,
        contact: null,
        phone: null,
        email: null,
        active: null,
        error: "Call 'updateOrCreateCompany' Failed"
      };
    }
  }
};

export const getAllCompanies = async () => {
  try {
    
    const { data }: { data: CompanysModelType[] } = await Axios.post(
      `/api/company/all`,
      {

      },
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        results: response.results
      };
    } else {
      return {
        success: false,
        results: []
      };
    }
  }
};

export const getActiveCompanies = async () => {
  try {
    
    const { data }: { data: CompanysModelType[] } = await Axios.post(
      `/api/company/active`,
      {

      },
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        results: response.results
      };
    } else {
      return {
        success: false,
        results: []
      };
    }
  }
};

export const deactivateCompany = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: CompanysModelType[] } = await Axios.post(
      `/api/company/${id}/toggleStatus`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response
      };
    } else {
      return {
        success: false,
        error: response
      };
    }
  }
};