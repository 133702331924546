import * as React from "react";
import { Field, Form, Formik } from "formik";
import { Grid, makeStyles, Theme, Button} from "@material-ui/core";
import { TimeEntryModelType } from "../../model/entries/TimeEntryModelType";
import { useState } from "react";
import { updateOrCreateTimeEntry, deleteTimeEntry } from "../../services/entries/callTimeEntry";
import { TextField } from 'formik-material-ui';
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import utc from 'dayjs/plugin/utc';

interface Props {
    timeEntry?: any;
    isUpdate: boolean;
    sprint: any;
    projectId: number;
    user: number;
    refresh: any;
    setIsUpdate: any;
    importedClasses: any;
    showSave: boolean;
    startDate: string;
    endDate: string;
    maxDate: string;
}

const useStyle = makeStyles((theme: Theme) => {
    return {
      formControl: {
        minWidth: 120,
        display: "flex",
        flexDirection: "column",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        // width: "85%"
      },
      autocomplete: {
        // marginBottom: 25
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 300
      },
      checkbox: {
        margin: theme.spacing(2)
      }
    };
  });



const TimeEntryForm: React.FC<Props> = props => {
    dayjs.extend(isBetween);
    dayjs.extend(utc);
    dayjs.utc();
    const { timeEntry, isUpdate, sprint, projectId, user, refresh
      , setIsUpdate, importedClasses, showSave, startDate, endDate, maxDate} = props;
    const [error, setError] = useState("");
    // const [isSubmitting, setSubmitting] = useState(false);
    const classes = useStyle();
    const [selectedDate, setSelectedDate] = useState(timeEntry?.workDate || undefined);

    const initialValues = isUpdate ? timeEntry : {
        id: '',
        hours: '',
        user: user,
        sprint: sprint?.sprintNumber,
        week: '',
        day: dayjs(selectedDate).date(),
        year: sprint.year,
        month: dayjs(selectedDate).month() + 1,
        managed: true,
        comments: '',
        projectId: projectId,
        workDate: dayjs(selectedDate).set('hour', 12).set('minute', 0).set('second', 0),
        active: true
      };

    const [initVals, setInitVals] = useState(initialValues);
      
    const updateTimeEntryResult = async (updatedTimeEntry : TimeEntryModelType) => {
        // updateTimeEntry(updatedTimeEntry.id);
    }

    const handleDateChange = (date: any | null) => {
      if (date) {
        setSelectedDate(date);
        setIsUpdate(false);

        const initialValues =  {
          id: '',
          hours: '',
          user: user,
          sprint: sprint?.sprintNumber,
          week: '',
          day: dayjs(date).date(),
          year: dayjs(date).year(),
          month: dayjs(date).month() + 1,
          managed: true,
          comments: '',
          projectId: projectId,
          workDate: dayjs(date).set('hour', 12).set('minute', 0).set('second', 0),
          active: true
        }

        setInitVals(initialValues);
      }
    };

    return <>
    
    <Formik
        isInitialValid={isUpdate ? true : false}
        enableReinitialize
        initialValues={initVals}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if ((values.hours > 0) === false) {
              setError("Time must be greater than zero!");
              return;
            }

            const today = dayjs(maxDate).set('hour', 12).set('minute', 0).set('second', 0);
            const sprintStart = dayjs(startDate).set('hour', 0).set('minute', 0).set('second', 0);
            const sprintEnd = dayjs(endDate).set('hour', 23).set('minute', 59).set('second', 59);
            let workDate = dayjs(values.workDate).set('hour', 12).set('minute', 0).set('second', 0);
            const workDateString = dayjs(values.workDate).format("MM/DD/YYYY");
            const failsFuture = workDate.isAfter(today, 'day');
            const passesFutureSprint = workDate.isBetween(sprintStart, sprintEnd, 'day', '[]');

            if (failsFuture && maxDate !== workDateString) {
              setError(`Work Date must not be greater than ${dayjs(today).format("MM/DD/YYYY")}!`);
              return;
            }
            
            if (!passesFutureSprint) {
              setError(`Work Date must be between ${dayjs(sprintStart).format("MM/DD/YYYY")} and ${dayjs(sprintEnd).format("MM/DD/YYYY")}`);
              return;
            }

            values.workDate = dayjs(workDate).utc().set('hour', 12).set('minute', 0).set('second', 0);
            const result: any = await updateOrCreateTimeEntry(values, isUpdate);
            if (result.success) {
              if (!isUpdate) {
                  updateTimeEntryResult(result.result);
              }
              setError(result.error);
              setSubmitting(false);
              resetForm(initVals);
              refresh();
              setIsUpdate(false);
            } else {
              setSubmitting(false);
              setError(result.result);
              resetForm(initVals);
            }
          }}
    >
        <Form className={classes.formControl}>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">
            <div style={{color: 'red'}}>{error}</div>
           </Grid>
        <Grid container spacing={1}
            direction="row"
            justify="space-evenly"
            alignItems="baseline">
            <Grid container spacing={1}>
              <Grid item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                      variant="inline"
                      autoOk={true}
                      margin="normal"
                      id="date-picker-dialog"
                      label="Work Day"
                      format="MM/dd/yyyy"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      minDate={startDate}
                      maxDate={maxDate}
                    />
                </MuiPickersUtilsProvider>
              </Grid>
                <Grid item>  
                    <Field
                    name="hours"
                    placeholder={"Hours"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Hours"
                    />
                </Grid>
                <Grid item>  
                    <Field
                    name="comments"
                    placeholder={"Comments"}
                    isParent={false}
                    className={classes.autocomplete}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Comments"
                    multiline
                    rowsMax="4"
                    rows={4}
                    style={{width: "91%"}}
                    />
                </Grid>
                <Field name="sprint" type={"hidden"}/>
                <Field name="user" type={"hidden"}/>
                <Field name="day" type={"hidden"}/>
                <Field name="year" type={"hidden"}/>
                <Field name="month" type={"hidden"}/>
                <Field name="projectId" type={"hidden"}/>
                <Field name="workDate" type={"hidden"}/>
                <Field name="active" type={"hidden"}/>
                
                <Grid item>  
                
                </Grid>
            </Grid>
            
        </Grid>
        {showSave && 
          <Button type="submit" variant="contained" className={importedClasses.clockTrackButton} style={{float: "left", width: "92px"}}>Save</Button>
        }
            </Form>

    </Formik>
  
    </>
};

export default TimeEntryForm;