import * as React from "react";
import { Router } from "@reach/router";
import PatientIndex from "../dashboard/project/ProjectIndex";
// import { Navigation } from "../nav/Navigation";
import MainIndex from "../dashboard/MainIndex";

/**
 * Dashboard component.
 */
const Dashboard: React.FC = () => (
    // <Navigation>
        <Router>
            <MainIndex path={"/"}/>
            {/* <PatientIndex path={"/patient"}/>
            <EncounterIndex path={"/encounter"}/> */}
            {/* <MainIndex path={"/"}/> */}
        </Router>
    // </Navigation>

);

export default Dashboard;
