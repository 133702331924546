import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Paper } from "@material-ui/core";
import { useState } from "react";
import { callUserById } from "../../services/users/callUsers";
import UserForm from "./UserForm";
import { displayLoader, hideLoader } from '../../services/utility/loader';
import ChangePassword from "../../auth/ChangePassword";

interface Props {
    userInfo: any;
    importedClasses: any;
}

export const UserEditIndex: React.FC<Props & RouteComponentProps> = (props) => {

    const {userInfo, importedClasses} = props;
    const [user, setUser] = useState<any>(undefined);
    const [update, setUpdate] = useState(false);

    const findById  = async () => {
        const response: any = await callUserById(
            userInfo.userId
        );
        if (response.success && response.result) {
            setUpdate(true);
            setUser(response.result);
            window.scrollTo(0, 0);
            hideLoader();
        }        
    };

    React.useEffect(() => {
        if (userInfo.userId) {
            displayLoader();
            findById();
        }        
    }, [userInfo]);
    
  return (
      <>
      {update && user && 
        <Paper>
            <>
                <UserForm isUpdate={update} handleClose={() => {}} user={user} updateUser={userInfo.userId} refresh={() => {}} 
                importedClasses={importedClasses} userInfo={userInfo} setUserAfterUpdate={setUser} selfService={true}/>

                {/* <div style={{marginLeft: "8px"}}>
                    <ChangePassword userInfo={userInfo} importedClasses={importedClasses}/>
                </div> */}
            </>
            
        </Paper>
      }
    </>
  );
};

export default UserEditIndex;