import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Grid, Paper } from "@material-ui/core";
import { useState, useEffect } from "react";
import { displayLoader, hideLoader } from '../../services/utility/loader';
import MaterialTable from "material-table";
import { callAllSubmittedTime } from "../../services/entries/callSubmittedTime";
import dayjs from "dayjs";

interface Props {
    setShowEnc?: any;
    showEnc?: boolean;
    userInfo: any;
}

export const SubmittedTimeReport: React.FC<Props & RouteComponentProps> = (props) => {

    const {userInfo} = props;
    const [showTable, setShowTable] =  useState(false);
    const [timeReports, setTimeReports] = useState<any[]>([]);

    const findTimeReports  = async () => {
        const response: any = await callAllSubmittedTime();
        if (response && response.success) {
            setTimeReports(response.results);
            hideLoader();
        }        
    };

    useEffect(() => {
        displayLoader();
        findTimeReports();
        setShowTable(true);
        hideLoader();
    }, [userInfo]);


  return (
      <>
        <Paper style={{margin: "3%"}}>
            <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            >
              {showTable &&
              <>
              <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} alignItems="stretch">
                      <MaterialTable
                          components={{}}
                          columns={[  
                              {
                              title: 'Project',
                              field: 'projectName'
                              },
                              {
                              title: 'Customer',
                              field: 'customerName'
                              },
                              {
                              title: 'Project Manager Name',
                              field: 'projMgrName'
                              },
                              {
                              title: 'Developer Name',
                              field: 'userName'
                              },
                              {
                              title: 'Status',
                              field: 'timeStatus'
                              },
                              {
                              title: 'Submitted Status',
                              field: 'submittedTime'
                              },
                              {
                                title: 'Approval Status',
                                field: 'approvedTime'
                              },
                              {
                              title: 'Sprint End Date',
                              field: 'endDate',
                              render: (rowData: any) => {
                                  return (
                                    <span>
                                    {rowData.endDate ? dayjs(rowData.endDate).format("MM/DD/YYYY") : ''}
                                    </span>
                              );
                              }
                              }
                          ]}
                            options={{
                            exportButton: true,
                            search: true,
                            showTitle: false,
                            exportFileName: "submitted_time",
                            rowStyle: {
                                verticalAlign: "baseline",
                                height: "80px",
                            },
                            columnsButton: true,
                            pageSize: 20,
                            exportAllData: true,
                          }}
                          data={timeReports}
                      />
                  </Grid>
              </Grid>
              </>
              }

          </Grid>
          </Paper>
      </>
  );
};

export default SubmittedTimeReport;