import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Box, Grid, Paper, TableCell, TableFooter, TableHead, TableRow, Typography } from "@material-ui/core";
import { useState, useEffect } from "react";
import { callUserTimeReportBySprint } from "../../services/report/callUserTimeReport";
import { displayLoader, hideLoader } from '../../services/utility/loader';
import MaterialTable, { MTableBody } from "material-table";
import dayjs from "dayjs";
import { callSprints } from "../../services/utility/callSprints";
import { UserTimeEntryModelType } from "../../model/entries/UserTimeEntryModelType";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

interface Props {
    userInfo: any;
}

export const UserTimeReport: React.FC<Props & RouteComponentProps> = (props) => {

    const {userInfo} = props;
    const [showTable, setShowTable] =  useState(false);
    const [timeReports, setTimeReports] = useState<any[]>([]);
    // const [year, setYear] = useState(dayjs().year());
    const [sprint, setSprint] = useState<any>(undefined);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const [hours, setHours] = useState('');
    const [pmHours, setPmHours] = useState('');

    const handleDateChange = (date: Date | null) => {
        if (date) {
            setSelectedDate(date);
            // setYear(dayjs(date).year());
        }
    };

    useEffect(() => {
        getSprints();
    }, [selectedDate]);

  const getSprints = async () => {
      const response: any = await callSprints(dayjs(selectedDate).format("YYYYMMDD"));
      if (response.success) {
        let list = response.result;
        if (list) {
          setSprint(list);
          hideLoader();
        }
      }  
    }

    useEffect(() => {
        if (userInfo && sprint) {
            displayLoader();
            findTimes();
        }
        setShowTable(true);
        hideLoader();
    }, [userInfo, sprint]);

    useEffect(() => {
        getSprints();
    }, []);

    const findTimes  = async () => {
        const response: any = await callUserTimeReportBySprint(userInfo.userId, sprint.sprintNumber, sprint.year, (userInfo.isPm === true || userInfo.isAdmin === true));
        if (response && response.success) {
            setTimeReports(response.results);
            hideLoader();

            let hours = 0.00;
            let pmHours = 0.00;
            for (let idx = 0; idx < response.results.length; idx++) {
                let timeEntry: UserTimeEntryModelType = response.results[idx];
                hours = timeEntry.devHours ? hours + timeEntry.devHours : hours;
                pmHours = timeEntry.pmHours ? pmHours + timeEntry.pmHours : pmHours;
            };
            setHours(hours.toFixed(2));
            setPmHours(pmHours.toFixed(2));
        }        
    };

  return (
      <>
            <Paper style={{margin: "3%"}}>
                
            <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            >
                    {showTable && userInfo !== undefined &&
                    <>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        >
                        
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} alignItems="stretch">
                            <Typography>
                                <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start">
                                   <Grid item>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                variant="inline"
                                                autoOk={true}
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="Sprint"
                                                format="MM/dd/yyyy"
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid> 
                                </Grid>
                                <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start">
                                    <Grid item>
                                        <Box component="div" style={{ display: 'inline', fontWeight: 'bold', margin: '5px', marginRight: '25px'}}>Total Hours this Sprint</Box>
                                    </Grid>
                                    <Grid item>
                                        <Box component="div" style={{ display: 'inline' }}>{hours}</Box>
                                    </Grid>
                                    <Grid item>
                                        <Box component="div" style={{ display: 'inline', fontWeight: 'bold', margin: '5px', marginRight: '25px'}}>Total PM Hours this Sprint</Box>
                                    </Grid>
                                    <Grid item>
                                        <Box component="div" style={{ display: 'inline' }}>{pmHours}</Box>
                                    </Grid>
                                </Grid>
                            </Typography>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} alignItems="stretch">
                            <MaterialTable
                                components={{
                                    Body: (props) => {
                                        let totalObj = {
                                          actualSum: 0
                                        }
                                        props.renderData.forEach((rowData: any) => {
                                          totalObj.actualSum += rowData.devHours;
                                        });
                                        return (
                                        <>
                                        <TableHead>
                                            <TableRow>
                                              <TableCell colSpan={2} />
                                              <TableCell colSpan={1} style={{fontWeight: "bold", fontSize: "1em"}} align="right">Total: {totalObj.actualSum}</TableCell>
                                              <TableCell colSpan={4} />
                                            </TableRow>
                                          </TableHead>
                                          <TableFooter>
                                            <TableRow>
                                              <TableCell colSpan={2} />
                                              <TableCell colSpan={1} style={{fontWeight: "bold", fontSize: "1em"}} align="right">Total: {totalObj.actualSum}</TableCell>
                                              <TableCell colSpan={4} />
                                            </TableRow>
                                          </TableFooter>
                                          <MTableBody {...props}/>
                                          
                                          
                                        </>
                                      )
                                    }
                                }}
                                columns={[  
                                    {
                                    title: 'Project',
                                    field: 'projectName'
                                    },
                                    {
                                    title: 'Customer',
                                    field: 'companyName'
                                    },
                                    {
                                    title: 'Dev Hours',
                                    field: 'devHours',
                                    align: 'right'
                                    },
                                    {
                                    title: 'PM Hours',
                                    field: 'pmHours',
                                    align: 'right'
                                    },
                                    {
                                    title: 'Total',
                                    field: 'total',
                                    align: 'right'
                                    },
                                    {
                                    title: 'Day',
                                    field: 'dayOfTheWeek'
                                    },
                                    {
                                    title: 'Work Date',
                                    field: 'workDate',
                                        render: (rowData: any) => {
                                            return (
                                            <>{dayjs(rowData.workDate).add(6, 'hour').format("MM/DD/YYYY")}</>
                                        );
                                        }
                                    },
                                    {
                                    title: 'Comments',
                                    field: 'comments'
                                    }
                                ]}
                                options={{
                                    exportButton: true,
                                    search: true,
                                    showTitle: false,
                                    exportFileName: "time_entries",
                                    rowStyle: {
                                        verticalAlign: "baseline"
                                    },
                                    pageSize: 20,
                                    exportAllData: true,
                                    columnsButton: true,
                                }}
                                data={timeReports}
                            />
                        </Grid>
                    </Grid>
                    </>
                    }

                </Grid>
                </Paper>
            </>
  );
};

export default UserTimeReport;