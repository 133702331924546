import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Grid, makeStyles, Theme, createStyles, Button, Typography, Toolbar, AppBar  } from "@material-ui/core";
import { useState } from "react";
import TimeReportByDeveloper from "./TimeReportByDeveloper";
import TimeReportByYear from "./TimeReportByYear";
import SubmittedTimeReport from "./SubmittedTimeReport";
import TimeReportsAll from "./TimeReportsAll";

interface Props {
    classesImported: any;
    userInfo: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      dense: {
        marginTop: theme.spacing(2),
      },
      menu: {
        width: 200,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      fakelink: {
        cursor: "pointer",
        color: "blue",
        textDecoration: "underline"
      },
        hidden: {
        display: "none",
        visibility: "hidden"
      },
      fixedMenu: {
          backgroundColor: "#fff",
          color: "#333333",
      }
  }),
);


export const ReportIndex: React.FC<Props & RouteComponentProps> = (props) => {

    const {userInfo, classesImported} = props;
    const [showMainReport, setShowMainReport] = useState(true);    
    const [showTimeReportByYear, setShowTimeReportByYear] = useState(false);
    const [showSubmittedView, setShowSubmittedView] = useState(false);
    const [showAllEntriesView, setShowAllEntriesView] = useState(false);
    const classes = useStyles();
    
    const showMain = () => {
        setShowMainReport(true);
        setShowTimeReportByYear(false);
        setShowSubmittedView(false);
        setShowAllEntriesView(false);
    }

    const showTimeReportByYearPanel = () => {
        setShowMainReport(false);
        setShowTimeReportByYear(true);
        setShowSubmittedView(false);
        setShowAllEntriesView(false);
    }

    const showSubmittedTimePanel = () => {
      setShowMainReport(false);
      setShowTimeReportByYear(false);
      setShowSubmittedView(true);
      setShowAllEntriesView(false);
    }

    const showAllTimePanel = () => {
      setShowMainReport(false);
      setShowTimeReportByYear(false);
      setShowSubmittedView(false);
      setShowAllEntriesView(true);
    }

  return (
    <>
        
            <AppBar position="static">
            <Toolbar className={classes.fixedMenu} style={{width: "100%"}}>
                <Grid container direction="row">
                    {/* <Grid item className={classesImported.barLeft}> */}
                    <Typography style={{width: "100%"}}>
                      <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start">
                            <Grid item lg={2} sm={12} xs={12}>
                        <Button onClick={showMain} className={showMainReport ? classesImported.linkSelected : classesImported.link}>
                            Summary By Dev
                        </Button>
                        </Grid>
                        <Grid item lg={2} sm={12} xs={12}>
                        <Button onClick={showTimeReportByYearPanel} className={showTimeReportByYear ? classesImported.linkSelected : classesImported.link}>
                            Summary By Year
                        </Button>
                        </Grid>
                        <Grid item lg={2} sm={12} xs={12}>
                        <Button onClick={showSubmittedTimePanel} className={showSubmittedView ? classesImported.linkSelected : classesImported.link}>
                            Submitted Time
                        </Button>
                        </Grid>
                        <Grid item lg={2} sm={12} xs={12}>
                        <Button onClick={showAllTimePanel} className={showAllEntriesView ? classesImported.linkSelected : classesImported.link}>
                            All Times
                        </Button>
                        </Grid>
                        </Grid>    
                    </Typography>
                    </Grid>
                {/* </Grid> */}
            </Toolbar>
            </AppBar>
            {showMainReport && 
                <>
                    <TimeReportByDeveloper userInfo={userInfo} classesImported={classesImported}/>
                </>
            }
            {showTimeReportByYear && 
                <>
                    <TimeReportByYear userInfo={userInfo}/>
                </>
            }
            {showSubmittedView && 
                <>
                    <SubmittedTimeReport userInfo={userInfo}/>
                </>
            }
            {showAllEntriesView && 
                <>
                    <TimeReportsAll userInfo={userInfo}/>
                </>
            }
    </>
  );
};

export default ReportIndex;