import * as React from 'react';
import { Box, CircularProgress } from "@material-ui/core";

interface Props {}

const Spinner: React.FC<Props> = props => {
  return (
    <Box className={"hidden"} id={"lds-spinner"}>
      <CircularProgress />
    </Box>
  );

}
export default Spinner;