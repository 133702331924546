import Axios from "axios";
import { UserModelType } from "../../model/user/UserModelType";
import { UserFullModelType } from "../../model/user/UserFullModelType";

export const callUserById = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: UserModelType[] } = await Axios.post(`/api/users/${id}`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        id: response.id,
        firstName: response.firstName,
        lastName: response.lastName,
        phone: response.phone,
        cellphone: response.cellphone,
        email: response.email,
        title: response.title,
        suffix: response.suffix,
        password: response.password,
        middle: response.middle,
        projectManger: response.projectManager,
        salesPerson: response.salesPerson,
        admin: response.admin,
        error: response
      };
    } else {
      return {
        success: false,
        id: null,
        userType: null,
        firstName: '',
        lastName: '',
        phone: '',
        cellphone: '',
        email: '',
        title: '',
        suffix: '',
        password: '',
        middle: '',
        projectManger: false,
        salesPerson: false,
        admin: false,
        username: false,
        error: "Call 'callUserById' Failed"
      };
    }
  }
};

export const updateOrCreateUser = async (
  user : UserFullModelType,
  isUpdate: boolean
) => {
  try {

    const url = isUpdate ? `/api/users/${user.id}/full/update` : `/api/users/new/full`;
    
    const { data }: { data: UserFullModelType[] } = await Axios.post(
      url,
      {
        user: user
      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        firstName: response.firstName,
        lastName: response.lastName,
        phone: response.phone,
        cellphone: response.cellphone,
        email: response.email,
        title: response.title,
        suffix: response.suffix,
        password: response.password,
        middle: response.middle,
        projectManger: response.projectManager,
        salesPerson: response.salesPerson,
        admin: response.admin,
        username: response.username,
        error: response
      };
    } else {
      return {
        success: false,
        id: null,
        userType: null,
        firstName: '',
        lastName: '',
        phone: '',
        cellphone: '',
        email: '',
        title: '',
        suffix: '',
        password: '',
        middle: '',
        projectManger: false,
        salesPerson: false,
        admin: false,
        username: false,
        error: "Call 'updateOrCreateUser' Failed"
      };
    }
  }
};

export const getAllUsers = async (
) => {
    try {
    
      const { data }: { data: UserModelType[] } = await Axios.post(
        `/api/users/all`,
        {
  
        },
      );
  
      return { ...data, success: true };
    } catch (error) {
      const response: any = error;
      if (response) {
        return {
          success: false,
          results: response.results
        };
      } else {
        return {
          success: false,
          results: []
        };
      }
    }
};

export const getAvailableUsers = async (
    projectId: number
  ) => {
      try {
      
        const { data }: { data: UserModelType[] } = await Axios.post(
          `/api/utility/availableUsers/${projectId}`,
          {
    
          },
        );
    
        return { ...data, success: true };
      } catch (error) {
        const response: any = error;
        if (response) {
          return {
            success: false,
            results: response.results
          };
        } else {
          return {
            success: false,
            results: []
          };
        }
      }
  };

export const deactivateUser = async (
  id : number
) => {
  try {
    
    
    const { data }: { data: UserModelType[] } = await Axios.post(
      `/api/users/${id}/toggleStatus`,
      {

      },
      
    );

    return { ...data, success: true };
  } catch (error) {
    const response: any = error;
    if (response) {
      return {
        success: false,
        error: response
      };
    } else {
      return {
        success: false,
        error: response
      };
    }
  }
};
